import React, { useRef } from 'react'
import { Button, ButtonColor, ButtonProps } from '../Button/Button'
interface FilesUploadButtonProps extends Omit<ButtonProps, 'color'> {
  children: React.ReactNode
  uploadFiles: (files: File[]) => void
  multiple?: boolean
  color?: ButtonColor
}

export const FilesUploadButton: React.FC<FilesUploadButtonProps> = ({
  children,
  uploadFiles,
  multiple = true,
  color = 'secondary',
  ...rest
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const handleFilesSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    const selectedFiles = Array.from(e.target.files)
    uploadFiles(selectedFiles)
  }

  const handleOpenFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <div>
      <Button
        {...rest}
        color={color}
        onClick={handleOpenFileInput}
        style={{ whiteSpace: 'nowrap' }}
      >
        {children}
      </Button>
      <input
        type="file"
        multiple={multiple}
        style={{ display: 'none' }}
        onChange={handleFilesSelect}
        ref={fileInputRef}
      />
    </div>
  )
}

export default FilesUploadButton

import React from 'react'

import { useDocuments } from 'common/documentsHook'
import { BasicDocument } from 'models/Document'
import { DateWithTooltip } from '../DateWithTooltip/DateWithTooltip'
import { DocumentThumbnail } from '../DocumentThumbnail/DocumentThumbnail'
import { ColumnConfiguration, EntrTable } from '../EntrTable/EntrTable'
import FilesUploadButton from '../FilesUploadButton/FilesUploadButton'
import { LoadingAnimation } from '../LoadingAnimation/LoadingAmination'
import { MenuButton } from '../MenuButton/MenuButton'
import { UploadDropZone } from '../UploadDropZone/UploadDropZone'
import { BoxRow, ViewerBottomHeaderFooter } from '../common'
import {
  DocumentsMainViewBoxStyled,
  FileNameTypographyStyled,
  uploadDateStyle,
  uploadDropZoneActiveStyles,
  uploadDropZoneStyles
} from './DocumentsPanelStyles'

interface TableBasicDocument extends BasicDocument {
  id: string
}
interface DocumentsPanelProps {
  documents?: BasicDocument[]
  onUploadDocument: (
    file: File,
    progressListener: (progress: number | undefined) => void
  ) => Promise<void>
  onPreviewDocument: (document: BasicDocument) => Promise<string>
  onDownloadDocument: (document: BasicDocument) => Promise<void>
  onDeleteDocument: (document: BasicDocument) => Promise<void>
  refresh: () => void
  readOnly?: boolean
}

export const DocumentsPanel: React.FC<DocumentsPanelProps> = ({
  documents,
  onUploadDocument,
  onPreviewDocument,
  onDownloadDocument,
  onDeleteDocument,
  refresh,
  readOnly = false
}) => {
  const {
    uploadDocumentsCallback,
    previewDocumentCallback,
    downloadDocumentCallback,
    deleteDocumentCallback
  } = useDocuments({
    onUploadDocument,
    onPreviewDocument,
    onDownloadDocument,
    onDeleteDocument,
    refresh
  })

  const [activeFile, setActiveFile] = React.useState<BasicDocument | null>(null)
  const [tableDocuments, setTableDocuments] = React.useState<
    TableBasicDocument[]
  >([])

  const handleUploadDocuments = (files: File[]) => {
    uploadDocumentsCallback(files)
  }

  const handlePreviewDocument = (file: BasicDocument) => {
    previewDocumentCallback(file)
  }

  const handleDownloadDocument = (file: BasicDocument) => {
    downloadDocumentCallback(file)
  }

  const handleDeleteDocument = (file: BasicDocument | null) => {
    deleteDocumentCallback(file)
  }

  const handleRowMouseEnter = (file: BasicDocument) => {
    setActiveFile(file)
  }

  const handleRowMouseLeave = () => {
    setActiveFile(null)
  }

  React.useEffect(() => {
    if (documents) {
      const t = documents.map((d) => {
        return {
          ...d,
          id: d.path
        }
      })
      setTableDocuments(t)
    }
  }, [documents])

  const columnConfigs: ColumnConfiguration<TableBasicDocument>[] = [
    {
      title: 'Name',
      fieldStyles: { width: '60%' },
      field: () => '',
      customRender: (document) => (
        <BoxRow>
          <DocumentThumbnail type={document.type} />{' '}
          <FileNameTypographyStyled noWrap sx={{ marginLeft: '10px' }}>
            {document.name}
          </FileNameTypographyStyled>
        </BoxRow>
      )
    },
    {
      title: 'Upload Date',
      fieldStyles: { width: '30%' },
      field: () => '',
      customRender: (document) => (
        <DateWithTooltip date={document.lastModified} style={uploadDateStyle} />
      )
    },
    {
      title: '',
      fieldStyles: { width: '10%' },
      field: () => '',
      customRender: (document) => (
        /* path for link? */
        <MenuButton
          menuItems={[
            {
              itemTitle: 'Download',
              itemCallback: () => handleDownloadDocument(document)
            },
            ...(readOnly
              ? []
              : [
                  {
                    itemTitle: 'Delete',
                    itemCallback: () => handleDeleteDocument(document),
                    itemStyle: {
                      color: 'var(--error, #D03838)'
                    }
                  }
                ])
          ]}
          showButton={activeFile?.name === document.name}
        />
      )
    }
  ]

  return (
    <>
      {documents === undefined ? (
        <LoadingAnimation text="Loading documents" />
      ) : (
        <DocumentsMainViewBoxStyled>
          {/* Documents Upload Drop Zone */}
          {!readOnly && (
            <UploadDropZone
              onDrop={handleUploadDocuments}
              zoneStyle={uploadDropZoneStyles}
              activeZoneAppendedStyle={uploadDropZoneActiveStyles}
            />
          )}
          <EntrTable
            data={tableDocuments}
            onRowClick={handlePreviewDocument}
            columnConfigs={columnConfigs}
            headerSx={{
              borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
              '& .MuiBox-root': {
                marginBottom: '0px',
                padding: '15px 0px'
              },
              '& .MuiTypography-root': {
                fontFamily: 'Inter, sans-serif',
                fontSize: '13px',
                fontWeight: 400,
                color: 'rgba(35,60,88,0.43)'
              },
              '& th:first-of-type': {
                paddingLeft: '15px'
              },
              '& th:last-child': {
                paddingRight: '15px'
              }
            }}
            bodySx={{
              '& td:first-of-type': {
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
                paddingLeft: '15px'
              },
              '& td:last-child': {
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                paddingRight: '15px'
              }
            }}
            onRowEnter={handleRowMouseEnter}
            onRowExit={handleRowMouseLeave}
          />
        </DocumentsMainViewBoxStyled>
      )}

      <ViewerBottomHeaderFooter data-footer>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {!readOnly && (
            <FilesUploadButton uploadFiles={handleUploadDocuments}>
              Upload Files
            </FilesUploadButton>
          )}
        </div>
      </ViewerBottomHeaderFooter>
    </>
  )
}

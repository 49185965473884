import {
  FlexContainerItemRow,
  POINT_TO_PIXEL_RATIO
} from 'components/NutritionLabel/components/NutritionLabelStyles'
import { NutritionLabelText } from 'components/NutritionLabel/components/NutritionLabelText'

export interface ItemRowSettings {
  level?: number
  boldName?: boolean
  boldPercentage?: boolean
  topLine?: boolean
}

export interface ItemRowValues {
  name: string | React.ReactNode
  amount?: string | number
  unit?: string
  dvPercentage?: string
  override?: React.ReactNode
}

export interface ItemRowProps extends ItemRowSettings {
  name: string
  amount?: string | number
  unit?: string
  dvPercentage?: string
  override?: React.ReactNode
}

export const ItemRow: React.FC<ItemRowProps> = ({
  name,
  amount,
  unit,
  dvPercentage,
  level = 0,
  boldName = false,
  boldPercentage = false,
  override
}) => (
  <>
    <FlexContainerItemRow
      style={{
        alignItems: 'flex-start'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          width: '80%'
        }}
      >
        {!override && (
          <>
            <NutritionLabelText
              bold={boldName}
              points={8}
              wrapText={true}
              extraStyle={{
                width: '80%',
                whiteSpace: 'pre-wrap',
                paddingLeft: `${level * 10 * POINT_TO_PIXEL_RATIO}px`
              }}
            >
              {name}
            </NutritionLabelText>
            <NutritionLabelText
              points={8}
              extraStyle={{ width: '20%', textAlign: 'right' }}
            >
              {'\u00A0'}
              {amount}
              {unit && '\u00A0'}
              {unit}
            </NutritionLabelText>
          </>
        )}
        {override}
      </div>
      <NutritionLabelText
        bold={boldPercentage}
        points={8}
        extraStyle={{ textAlign: 'right', width: '20%' }}
      >
        {dvPercentage && `${dvPercentage}`}
      </NutritionLabelText>
    </FlexContainerItemRow>
  </>
)

import {
  BoxColumnBasic,
  Option,
  OptionGroup,
  SwitchStyled
} from 'components/common'
import React from 'react'
import { SelectTargetSwitchControlLabel } from '../../../TargetsStyles'
import { Box, Typography } from '@mui/material'
import _ from 'lodash'
import { SearchField } from 'components/SearchField/SearchField'

export interface TargetSelectionProps {
  targetOptionGroups: OptionGroup<boolean>[]
  onChange: (selected: Option<boolean>) => void
}

const arePropsEqual = (
  prevProps: TargetSelectionProps,
  nextProps: TargetSelectionProps
): boolean => {
  return (
    _.isEqual(prevProps.targetOptionGroups, nextProps.targetOptionGroups) &&
    prevProps.onChange === nextProps.onChange
  )
}

export const TargetSelection: React.FC<TargetSelectionProps> = React.memo(
  ({ targetOptionGroups, onChange }) => {
    const [searchQuery, setSearchQuery] = React.useState<string>('')
    const [filteredTargetOptionGroups, setFilteredTargetOptionGroups] =
      React.useState<OptionGroup<boolean>[]>([])

    const onSearchChange = (search: string) => {
      setSearchQuery(search)
    }

    React.useEffect(() => {
      if (searchQuery) {
        const filteredCategories = targetOptionGroups
          .map((category) => {
            const filteredTargets = category.options.filter((target) => {
              return (target.label as string)
                .toLowerCase()
                .includes(searchQuery.toLowerCase())
            })

            return {
              ...category,
              options: filteredTargets
            }
          })
          .filter((category) => category.options.length > 0)

        setFilteredTargetOptionGroups(filteredCategories)
      } else {
        setFilteredTargetOptionGroups(targetOptionGroups)
      }
    }, [searchQuery, targetOptionGroups])

    return (
      <BoxColumnBasic sx={{ gap: '12px' }}>
        <Box>
          <SearchField
            placeholder="Search targets"
            onChange={onSearchChange}
            sx={{ width: '100%' }}
          />
          {searchQuery && filteredTargetOptionGroups.length === 0 && (
            <Typography sx={{ margin: '10px 0px', textAlign: 'center' }}>
              No targets matched your search
            </Typography>
          )}
        </Box>
        {filteredTargetOptionGroups.map((group, index) => {
          return (
            <BoxColumnBasic key={`tg-${index}`} sx={{ gap: '12px' }}>
              <Typography variant="h5">{group.label}</Typography>
              {group.options.map((option, index) => {
                return (
                  <SelectTargetSwitchControlLabel
                    key={`ts-${index}`}
                    label={<>{option.label}</>}
                    onClick={() =>
                      onChange({ ...option, value: !option.value })
                    }
                    control={<SwitchStyled checked={option.value} />}
                  />
                )
              })}
            </BoxColumnBasic>
          )
        })}
      </BoxColumnBasic>
    )
  },
  arePropsEqual
)

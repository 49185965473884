import React from 'react'
import { Panel } from './Panel'
import { ReviewContainer } from './tabs/Review/ReviewContainer'
import { LabelContainer } from './tabs/Label/LabelContainer'
import { ActivityContainer } from './tabs/Activities/components/Activity/ActivitiyContainer'
import { ActivitiesContainer } from './tabs/Activities/ActivitiesContainer'

export const PanelContainer: React.FC = () => {
  return (
    <Panel
      reviewTabContent={<ReviewContainer />}
      activityTabContent={<ActivitiesContainer />}
      labelTabContent={<LabelContainer />}
    />
  )
}

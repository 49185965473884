import React from 'react'
import { LabelProofer } from './LabelProofer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { useParams } from 'react-router'
import {
  getProof,
  resetProoferState
} from 'state/labelproof/proofer/ProoferSlice'
import { resetProoferActivitiesState } from 'state/labelproof/proofer/ProoferActivitiesSlice'

export const LabelProoferContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const companyId = useAppSelector((state) => state.companies.currentCompany.id)

  const { id } = useParams()

  // Gets the proof.
  React.useEffect(() => {
    if (id) {
      void dispatch(getProof({ companyId: companyId, proofId: id }))
    }
    return () => {
      dispatch(resetProoferState())
      dispatch(resetProoferActivitiesState())
    }
  }, [id, companyId])

  return <LabelProofer />
}

import React from 'react'
import { TableSortLabel } from '@mui/material'
import { SortingOrder } from 'components/common'

interface SortableTableHeaderCellProps {
  id: string
  sortingSettings: {
    sortingFieldId?: string
    sortingOrder: SortingOrder | undefined
    updateSortingFieldPath: (path: string[]) => void
  }
  propertyToSortByPath: string[]
  label: string
  containerComponent: React.ElementType
  containerProps?: Record<string, any>
}

export const SortableTableHeaderCell: React.FC<SortableTableHeaderCellProps> = ({
  id,
  sortingSettings,
  propertyToSortByPath,
  label,
  containerComponent: ContainerComponent,
  containerProps
}) => {
  const sortingActive = React.useMemo(() => {
    return (
      id === sortingSettings?.sortingFieldId &&
      sortingSettings?.sortingOrder !== undefined
    )
  }, [id, sortingSettings?.sortingFieldId, sortingSettings?.sortingOrder])

  const sortingDirection = React.useMemo(() => {
    return sortingActive ? sortingSettings.sortingOrder : SortingOrder.DESC
  }, [sortingActive, sortingSettings.sortingOrder])

  const handleClick = () => {
    sortingSettings.updateSortingFieldPath(propertyToSortByPath)
  }

  return (
    <ContainerComponent {...containerProps}>
      <TableSortLabel
        active={sortingActive}
        direction={sortingDirection}
        onClick={handleClick}
      >
        {label}
      </TableSortLabel>
    </ContainerComponent>
  )
}

import { Box } from '@mui/material'
import { Assigner } from 'components/Assigner/Assigner'
import { BoxRowBasic } from 'components/common'
import React from 'react'
import { Reviewer, ReviewerProps } from './Reviewer'

export type Reviewer = Omit<ReviewerProps, 'onRemove'>
export type ReviewerOption = Omit<
  ReviewerProps,
  'onRemove' | 'action' | 'removable'
>

export interface ReviewersProps {
  reviewers: Reviewer[]
  reviewerOptions: ReviewerOption[]
  onRemove: (id: string) => void
  onAdd: (id: string) => void
}

export const Reviewers: React.FC<ReviewersProps> = ({
  reviewers,
  reviewerOptions,
  onRemove,
  onAdd
}) => {
  const options = reviewerOptions.filter((option) => {
    return !reviewers.some((reviewer) => reviewer.id === option.id)
  })

  return (
    <BoxRowBasic sx={{ gap: '-5px', position: 'relative' }}>
      {reviewers.map((reviewer, index) => (
        <Box
          key={`reviewer-${reviewer.id}`}
          sx={{
            marginLeft: '-5px',
            position: 'relative', // Enable stacking context.
            zIndex: reviewers.length - index // Ensure the first one has the highest z-index.
          }}
        >
          <Reviewer {...reviewer} onRemove={onRemove} />
        </Box>
      ))}
      <Box
        key={`assigner`}
        sx={{
          marginLeft: '-5px',
          position: 'relative' // Enable stacking context.
        }}
      >
        <Assigner
          options={options}
          onSelect={onAdd}
          buttonSx={{ width: '32px', height: '32px' }}
        />
      </Box>
    </BoxRowBasic>
  )
}

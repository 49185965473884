import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LabelProofApiResponse } from 'services/apis/label_proofs/LabelProofApiResponse'
import { LabelProofsApi } from 'services/apis/label_proofs/LabelProofsApi'
import { GetProofDesignFileUrlRequest, GetProofRequest, UpdateProofRequest, UploadProofDesignFileRequest } from './ProoferRequest'


export interface ProoferState {
  loadingDesignFileUrl: boolean
  loadingProof: boolean
  savingProof: boolean
  proof?: LabelProofApiResponse
  designFileUrl?: string
  highlightedPinId?: string
  deletingProof: boolean
}

const initialState: ProoferState = {
  loadingDesignFileUrl: false,
  loadingProof: false,
  savingProof: false,
  deletingProof: false
}

export const getProof = createAsyncThunk(
  'labelproof/proofer/get',
  async ({ companyId, proofId: proofId }: GetProofRequest) => {
    return await LabelProofsApi.getLabelProof(companyId, proofId)
  }
)

export const updateProof = createAsyncThunk(
  'labelproof/proofer/update',
  async ({ companyId, proofId: proofId, name }: UpdateProofRequest) => {
    return await LabelProofsApi.updateLabelProof(companyId, proofId, {
      name: name
    })
  }
)

export const deleteProof = createAsyncThunk(
  'labelproof/proofer/delete',
  async ({ companyId, proofId: proofId }: GetProofRequest) => {
    return await LabelProofsApi.deleteLabelProof(companyId, proofId)
  }
)

export const uploadProofDesignFile = createAsyncThunk(
  'labelproof/proofer/uploadDesignFile',
  async ({
    companyId,
    proofId,
    file,
    uploadProgressListener
  }: UploadProofDesignFileRequest) => {
    return await LabelProofsApi.uploadLabelProofDesignFile(companyId, proofId, file, uploadProgressListener)
  }
)

export const getProofDesignFileUrl = createAsyncThunk(
  'labelproof/proofer/getDesignFileUrl',
  async ({ companyId, proofId }: GetProofDesignFileUrlRequest) => {
    return await LabelProofsApi.getLabelProofDesignFile(companyId, proofId)
  }
)

const prooferSlice = createSlice({
  name: 'prooferSlice',
  initialState,
  reducers: {
    resetProoferState: () => initialState,
    setHighlightedPinId: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload === state.highlightedPinId) {
        state.highlightedPinId = undefined
        return
      }
      state.highlightedPinId = action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase(getProof.pending, (state) => {
      state.loadingProof = true
    })
    builder.addCase(
      getProof.fulfilled,
      (state, action: PayloadAction<LabelProofApiResponse>) => {
        state.loadingProof = false
        state.proof = action.payload
      }
    )
    builder.addCase(getProof.rejected, (state) => {
      state.loadingProof = false
    })

    builder.addCase(updateProof.pending, (state) => {
      state.savingProof = true
    })
    builder.addCase(
      updateProof.fulfilled,
      (state, action: PayloadAction<LabelProofApiResponse>) => {
        state.savingProof = false
        state.proof = action.payload
      }
    )
    builder.addCase(updateProof.rejected, (state) => {
      state.savingProof = false
    })

    builder.addCase(deleteProof.pending, (state) => {
      state.deletingProof = true
    })
    builder.addCase(deleteProof.fulfilled, (state) => {
      state.deletingProof = false
      state.proof = undefined
    })
    builder.addCase(deleteProof.rejected, (state) => {
      state.deletingProof = false
    })

    builder.addCase(getProofDesignFileUrl.pending, (state) => {
      state.loadingDesignFileUrl = true
    })
    builder.addCase(
      getProofDesignFileUrl.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.loadingDesignFileUrl = false
        state.designFileUrl = action.payload
      }
    )
    builder.addCase(getProofDesignFileUrl.rejected, (state) => {
      state.designFileUrl = undefined
      state.loadingDesignFileUrl = false
    })
  }
})

export const {
  resetProoferState,
  setHighlightedPinId,
} = prooferSlice.actions
export default prooferSlice.reducer

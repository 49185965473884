import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LabelProofCommentApiResponse, LabelProofCommentHavingPinApiResponse, PaginatedLabelProofCommentApiResponse, PinApiResponse } from 'services/apis/label_proofs/LabelProofApiResponse'
import { LabelProofsApi } from 'services/apis/label_proofs/LabelProofsApi'
import { GetProofActivitiesRequest, GetProofActivitiesHavingPinRequest, CreateProofCommentRequest, DeleteProofCommentRequest, UpdateProofCommentRequest } from './ProoferRequest'
import { buildPinId } from './helpers'


export interface StagedCommentReplyTo {
  id: string
  text: string
  mentionsMap: Record<string, string>
  createdByName?: string
  pinMap: Record<string, string>
  pinId?: string
}

export interface StagedComment {
  id?: string
  text: string
  replyTo?: StagedCommentReplyTo
  pin?: PinApiResponse
  mentionedUserIds?: string[]
}

export interface ActivitiesState {
  activities: PaginatedLabelProofCommentApiResponse
  stagedComment: StagedComment
  pinMode: boolean
  editingComment: boolean
  addingComment: boolean
  loadingActivities: boolean
  commentsHavingPin?: LabelProofCommentHavingPinApiResponse[]
}

const initialState: ActivitiesState = {
  activities: {
    items: [],
    page: 0,
    total: 0,
    size: 0,
    pages: 0
  },
  stagedComment: {
    text: '',
    replyTo: undefined,
    pin: undefined,
    mentionedUserIds: undefined
  },
  pinMode: false,
  editingComment: false,
  addingComment: false,
  loadingActivities: false,
}

export const getProofCommentsHavingPin = createAsyncThunk(
  'labelproof/proofer/getCommentsHavingPin',
  async ({ companyId, proofId }: GetProofActivitiesHavingPinRequest) => {
    return await LabelProofsApi.getLabelProofCommentsHavingPin(companyId, proofId)
  }
)

export const getProofActivities = createAsyncThunk(
  'labelproof/proofer/getActivities',
  async ({
    companyId,
    proofId,
    page = 1,
    size = 50
  }: GetProofActivitiesRequest) => {
    return await LabelProofsApi.getLabelProofComments(companyId, proofId, page, size)
  }
)

export const createProofComment = createAsyncThunk(
  'labelproof/proofer/createComment',
  async ({ companyId, proofId, data }: CreateProofCommentRequest) => {
    return await LabelProofsApi.createLabelProofComment(companyId, proofId, data)
  }
)

export const updateProofComment = createAsyncThunk(
  'labelproof/proofer/updateComment',
  async ({ companyId, proofId, commentId, data }: UpdateProofCommentRequest) => {
    return await LabelProofsApi.updateLabelProofComment(companyId, proofId, commentId, data)
  }
)

export const deleteProofComment = createAsyncThunk(
  'labelproof/proofer/deleteComment',
  async ({ companyId, proofId, commentId }: DeleteProofCommentRequest) => {
    return await LabelProofsApi.deleteLabelProofComment(companyId, proofId, commentId)
  }
)

const prooferSlice = createSlice({
  name: 'prooferSlice',
  initialState,
  reducers: {
    togglePinMode: (state) => {
      state.pinMode = !state.pinMode
    },
    setPinMode: (state, action: PayloadAction<boolean>) => {
      state.pinMode = action.payload
    },
    setStagedComment: (state, action: PayloadAction<Partial<StagedComment>>) => {
      state.stagedComment = {
        ...state.stagedComment,
        ...action.payload
      }
    },
    resetStagedComment: (state) => {
      state.stagedComment = initialState.stagedComment
      state.pinMode = false
    },
    setEditingComment: (state, action: PayloadAction<boolean>) => {
      state.editingComment = action.payload
    },
    resetProoferActivitiesState: () => initialState
  },
  extraReducers(builder) {

    builder.addCase(getProofCommentsHavingPin.pending, (state) => {
      state.loadingActivities = true
    })
    builder.addCase(
      getProofCommentsHavingPin.fulfilled,
      (state, action: PayloadAction<LabelProofCommentHavingPinApiResponse[]>) => {
        state.loadingActivities = false
        state.commentsHavingPin = action.payload.map((comment) => {
          comment.pin.id = buildPinId(comment.pin.widthRatio, comment.pin.heightRatio)
          return comment
        })
      }
    )
    builder.addCase(getProofCommentsHavingPin.rejected, (state) => {
      state.commentsHavingPin = undefined
      state.loadingActivities = false
    })

    builder.addCase(getProofActivities.pending, (state) => {
      state.loadingActivities = true
    })
    builder.addCase(
      getProofActivities.fulfilled,
      (state, action: PayloadAction<PaginatedLabelProofCommentApiResponse>) => {
        state.loadingActivities = false
        // add id to the pin
        action.payload.items = action.payload.items.map((item) => {
          if (item.pin) {
            item.pin.id = buildPinId(item.pin.widthRatio, item.pin.heightRatio)
          }
          if (item.replyTo && item.replyTo.pin) {
            item.replyTo.pin.id = buildPinId(item.replyTo.pin.widthRatio, item.replyTo.pin.heightRatio)
          }
          return item
        })
        state.activities = {
          ...action.payload,
          items: [
            ...action.payload.items,
            ...state.activities.items,
          ]
        }
      }
    )
    builder.addCase(getProofActivities.rejected, (state) => {
      state.activities.items = []
      state.loadingActivities = false
    })

    builder.addCase(createProofComment.pending, (state) => {
      state.addingComment = true
    })
    builder.addCase(createProofComment.fulfilled, (state, action: PayloadAction<LabelProofCommentApiResponse>) => {
      state.addingComment = false
      if (state.activities) {
        state.activities.items = [
          ...state.activities.items,
          action.payload
        ]
        if (action.payload.pin && state.commentsHavingPin) {
          action.payload.pin.id = buildPinId(action.payload.pin.widthRatio, action.payload.pin.heightRatio)
          state.commentsHavingPin = [
            ...state.commentsHavingPin,
            action.payload as LabelProofCommentHavingPinApiResponse
          ]
        }
      }
    })
    builder.addCase(createProofComment.rejected, (state) => {
      state.addingComment = false
    })

    builder.addCase(updateProofComment.pending, (state) => { })
    builder.addCase(updateProofComment.fulfilled, (state, action: PayloadAction<LabelProofCommentApiResponse>) => {
      if (state.activities) {
        state.activities.items = state.activities.items.map((comment) => {
          if (comment.id === action.payload.id) {
            return action.payload
          }
          return comment
        })
      }
      if (state.commentsHavingPin) {
        if (action.payload.pin) {
          action.payload.pin.id = buildPinId(action.payload.pin.widthRatio, action.payload.pin.heightRatio)
          const existingHavingPin = state.commentsHavingPin.find((comment) => comment.id === action.payload.id)
          if (existingHavingPin) {
            state.commentsHavingPin = state.commentsHavingPin.map((comment) => {
              if (comment.id === action.payload.id) {
                return action.payload as LabelProofCommentHavingPinApiResponse
              }
              return comment
            })
          } else {
            state.commentsHavingPin = [
              ...state.commentsHavingPin,
              action.payload as LabelProofCommentHavingPinApiResponse
            ]
          }
        } else {
          state.commentsHavingPin = state.commentsHavingPin.filter((comment) => comment.id !== action.payload.id)
        }
      }
    })
    builder.addCase(updateProofComment.rejected, (state) => { })

    builder.addCase(deleteProofComment.pending, (state) => { })
    builder.addCase(deleteProofComment.fulfilled, (state, action: PayloadAction<LabelProofCommentApiResponse>) => {
      state.activities.items = state.activities.items.map((comment) => {
        if (comment.id === action.payload.id) {
          return action.payload
        }
        return comment
      })
      state.activities.items = state.activities.items.map((comment) => {
        if (comment.replyTo && comment.replyTo.id === action.payload.id) {
          comment.replyTo = action.payload
        }
        return comment
      })
      if (state.commentsHavingPin) {
        state.commentsHavingPin = state.commentsHavingPin.filter((comment) => comment.id !== action.payload.id)
      }
    })
    builder.addCase(deleteProofComment.rejected, (state) => { })
  }
})

export const {
  togglePinMode,
  setPinMode,
  setStagedComment,
  resetStagedComment,
  setEditingComment,
  resetProoferActivitiesState
} = prooferSlice.actions
export default prooferSlice.reducer

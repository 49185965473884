import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  AccordionDetails,
  Accordion as AccordionMui,
  AccordionSummary as AccordionSummaryMui,
  AccordionProps as AccordionPropsMui,
  Typography
} from '@mui/material'
import React from 'react'

export interface AccordionProps extends Omit<AccordionPropsMui, 'children'> {
  expandIcon?: React.ReactNode
  title: string
  defaultExpanded?: boolean
  children?: React.ReactNode
}

export const Accordion: React.FC<AccordionProps> = ({
  expandIcon = <ExpandMoreIcon />,
  title,
  defaultExpanded = false,
  children,
  ...rest
}) => {
  return (
    <AccordionMui
      id={`${title.toLowerCase()}-accordion`}
      defaultExpanded={defaultExpanded}
      sx={{
        backgroundColor: '#F6F5F4',
        borderRadius: '8px',
        boxShadow: 'none',
        '&::before': {
          display: 'none'
        },
        '&.Mui-expanded': {
          margin: 0,
          minHeight: 'none'
        }
      }}
      {...rest}
    >
      <AccordionSummaryMui
        expandIcon={expandIcon}
        id={`${title.toLowerCase()}-header`}
      >
        <Typography
          style={{
            fontWeight: '500',
            fontSize: '16px',
            color: '#1A2C42BF'
          }}
        >
          {title}
        </Typography>
      </AccordionSummaryMui>
      <AccordionDetails>{children}</AccordionDetails>
    </AccordionMui>
  )
}

import React from 'react'
import { POINT_TO_PIXEL_RATIO as POINT_TO_PIXELS_RATIO } from '../components/NutritionLabelStyles'

export type NutritionLabelTextProps = React.PropsWithChildren<{
  bold?: boolean
  points: number
  wrapText?: boolean
  additionalLineHeight?: number
  alignRight?: boolean
  symbol?: boolean
  font?: 'helvetica' | 'arial'
  extraStyle?: React.CSSProperties
}>

export const NutritionLabelText: React.FC<NutritionLabelTextProps> = ({
  children,
  bold = false,
  points,
  wrapText = false,
  additionalLineHeight = 0,
  alignRight = false,
  symbol = false,
  font = 'helvetica',
  extraStyle
}) => {
  const fontSize = `${Math.ceil(points * POINT_TO_PIXELS_RATIO)}px`
  /*
   Given we are dealing with a leading which is from the bottom of the text to the bottom of the text above it, we'll have to use line height carefully.
   In comparison, line height splits the leading above and below the text.
  */
  const lineHeightPx = `${Math.ceil(
    (points + additionalLineHeight) * POINT_TO_PIXELS_RATIO
  )}px`

  const fontFamily = React.useMemo(() => {
    switch (font) {
      case 'helvetica':
        return bold ? 'Helvetica Black, Arial Black' : 'Helvetica, Arial'
      case 'arial':
        return 'Arial'
      default:
        return bold ? 'Helvetica Black, Arial Black' : 'Helvetica, Arial'
    }
  }, [font, bold])

  const fontWeight = React.useMemo(() => {
    switch (font) {
      case 'arial':
        return bold ? 'bold' : 'normal'
      case 'helvetica':
        return 'normal'
    }
  }, [font, bold])

  return !symbol ? (
    <div
      style={{
        fontFamily: fontFamily,
        fontSize: fontSize,
        fontWeight: fontWeight,
        whiteSpace: wrapText ? 'normal' : 'nowrap',
        lineHeight: lineHeightPx,
        textAlign: alignRight ? 'right' : 'left',
        color: 'black',
        display: 'inline',
        ...extraStyle
      }}
    >
      {children}
    </div>
  ) : (
    <span
      style={{
        fontFamily: fontFamily,
        fontSize: fontSize,
        fontWeight: fontWeight,
        top: '2px',
        position: 'relative',
        color: 'black',
        ...extraStyle
      }}
    >
      {children}
    </span>
  )
}

import { Option } from 'components/common'
import { EntrTableFilterProps } from 'components/EntrTable/Filter/EntrTableFilter'
import React from 'react'
import { SearchOptions, useSearch } from './SearchHook'

export interface FilterSearchResponse {
  options: Option<boolean>[]
  page: number
  pages: number
}

interface FilterOptions extends SearchOptions {
  name: string // Required in filter options.
  onChange: (values: string[]) => void
}

/**
 * Handles filter logic and selections with pagination in lists.
 * @param filterOptions The options for the filter.
 * @returns The filter props.
 */
export const useFilter = (
  filterOptions: FilterOptions
): EntrTableFilterProps => {
  const {
    options,
    filteredOptions,
    setOptions,
    setFilteredOptions,
    allPagesLoaded,
    onLoadNextPage,
    search
  } = useSearch(filterOptions)

  const onSelected = React.useCallback(
    (option: Option<boolean>) => {
      // Update the options list to include the new selected option.
      // This case handles if the option is in the filter but not in the original.
      let optionFound = false
      const newOptions = options.map((o) => {
        if (o.id === option.id) {
          optionFound = true
          return { ...o, value: option.value }
        }
        return o
      })

      if (!optionFound) {
        newOptions.push(option)
      }

      // Reflect the changes in the filteredOptions if needed.
      const newFilteredOptions =
        filteredOptions?.map((o) => {
          if (o.id === option.id) {
            return { ...o, value: option.value }
          }
          return o
        }) || null

      try {
        filterOptions.onChange(
          newOptions.filter((o) => o.value).map((o) => o.id)
        )
        setOptions(newOptions)
        setFilteredOptions(newFilteredOptions)
      } catch (e) {
        console.error(e)
      }
    },
    [options, filteredOptions, filterOptions.onChange]
  )

  return {
    name: filterOptions.name,
    options,
    onSelected,
    allPagesLoaded: allPagesLoaded,
    onLoadNextPage: onLoadNextPage,
    search: {
      value: search.value,
      onChange: search.onChange,
      placeholder: `Filter by ${filterOptions.name}`
    },
    filteredOptions
  }
}

import { NutrientTableRowGroup } from './components/NutrientTable'
import { IngredientNutrient } from 'models/Ingredient'
import { NutrientRowProps } from './components/NutrientRow'

const toTableNutrientsRow = (
  ingn: IngredientNutrient,
  level = 0,
  onChange: (nutrientId: string, amount: number) => void,
  disabled: boolean
): NutrientRowProps => {
  return {
    nutrient: {
      id: ingn.nutrient.type,
      name: ingn.nutrient.name,
      amount: ingn.amount,
      unit: ingn.nutrient.unit
    },
    disabled: disabled || ingn.isCalculated,
    calculated: ingn.isCalculated,
    onChange: onChange,
    level: level
  }
}

const toTableNutrientRows = (
  ingn: IngredientNutrient,
  level = 0,
  onAmountChange: (nutrientId: string, amount: number) => void,
  disabled: boolean
): NutrientRowProps[] => {
  const children = ingn.children.flatMap((c) => {
    return toTableNutrientRows(c, level + 1, onAmountChange, disabled)
  })
  return [
    toTableNutrientsRow(ingn, level, onAmountChange, disabled),
    ...children
  ]
}

export const toTableNutrientsRowGroup = (
  ingredientNutrientGroups: IngredientNutrient[][],
  onAmountChange: (nutrientId: string, amount: number) => void,
  disabled = false
): NutrientTableRowGroup[] => {
  return ingredientNutrientGroups.map((ing) => ({
    nutrientRows: ing.flatMap((ingn) =>
      toTableNutrientRows(ingn, 0, onAmountChange, disabled)
    )
  }))
}

import { ListCell } from 'components/Cells/ListCell'
import { NameCell } from 'components/Cells/NameCell'
import { ColumnConfiguration, EntrTable } from 'components/EntrTable/EntrTable'
import React from 'react'

export interface IngredientsTableRow {
  id: string
  name: string
  friendlyId?: string
  suppliers?: string[]
  tags?: string[]
}

export interface IngredientsTableProps {
  rows: IngredientsTableRow[]
  allPagesLoaded: boolean
  nextPageCallback: () => void
  onRowClick: (ingredientId: string) => void
}

export const IngredientsTable: React.FC<IngredientsTableProps> = ({
  rows,
  allPagesLoaded,
  onRowClick,
  nextPageCallback
}) => {
  const columnConfigs: ColumnConfiguration<IngredientsTableRow>[] = [
    {
      title: 'Name',
      fieldStyles: { width: '35%' },
      field: (ingredient) => ingredient.name,
      customRender: (ingredient) => <NameCell name={ingredient.name} />
    },
    {
      title: 'Friendly ID',
      field: (ingredient) => ingredient.friendlyId ?? ''
    },
    {
      title: 'Suppliers',
      field: (ingredient) => ingredient.suppliers?.join(',') ?? '',
      customRender: (ingredient) => (
        <ListCell list={ingredient.suppliers ?? []} />
      )
    },
    {
      title: 'Tags',
      field: (ingredient) => ingredient.tags?.join(',') ?? '',
      customRender: (ingredient) => <ListCell list={ingredient.tags ?? []} />
    }
  ]

  return (
    <EntrTable
      data={rows}
      allPagesLoaded={allPagesLoaded}
      columnConfigs={columnConfigs}
      onRowClick={(row) => onRowClick(row.id)}
      onLoadNextPage={nextPageCallback}
      noDataMessage="No ingredients found"
    />
  )
}

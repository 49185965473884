import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { FormulaTableHeaderCell } from 'pages/Formulator/FormulatorStyles'
import React from 'react'
import {
  IngredientRow,
  IngredientRowProps
} from './components/row/IngredientRow'
import { BoxColumnBasic, SortingOrder } from 'components/common'
import { visuallyHidden } from '@mui/utils'
import { SortableTableHeaderCell } from 'components/SortableTableHeaderCell/SortableTableHeaderCell'

export interface IngredientTableProps {
  showSupplier?: boolean
  showCost?: boolean
  showActive?: boolean
  showWastePercentage?: boolean
  showQuantityPercentage?: boolean
  loading?: boolean
  ingredientSearchRow: React.ReactNode
  ingredientRows?: IngredientRowProps[]
  sortingSettings: {
    sortingFieldId?: string
    sortingOrder: SortingOrder | undefined
    updateSortingFieldPath: (sortByPath: string[]) => void
    sortingFieldIdGen: (fieldPath: string[]) => string
  }
}

const propertyToSortByPaths = {
  name: ['cells', 'ingredient', 'name'],
  supplier: ['cells', 'supplier', 'currentSupplierName'],
  cost: ['cells', 'cost', 'totalCost'],
  wastePercentage: ['cells', 'wastePercentage', 'percentage'],
  quantity: ['cells', 'quantityEditor', 'amount', 'value'],
  active: ['cells', 'active', 'active']
}

export const IngredientsTable: React.FC<IngredientTableProps> = ({
  showSupplier = false,
  showCost = false,
  showActive = false,
  showWastePercentage = false,
  showQuantityPercentage = false,
  loading = false,
  ingredientSearchRow,
  sortingSettings,
  ingredientRows = []
}) => {
  const tableCellPadding = { padding: '0px 8px' }

  // TODO: Handle sorting.

  const allIngredientsChecked = React.useMemo(() => {
    return ingredientRows.length > 0 && ingredientRows.every((ir) => ir.checked)
  }, [ingredientRows])

  const handleCheckAllIngredientsChange = React.useCallback(() => {
    const checked = allIngredientsChecked
    ingredientRows.forEach((ir) => {
      ir.onRowCheckedChange(!checked)
    })
  }, [ingredientRows, allIngredientsChecked])

  const getPlaceholderRows = (rows: number) => {
    const cellCount =
      3 +
      [showSupplier, showCost, showWastePercentage, showActive].reduce(
        (acc, val) => acc + (val ? 1 : 0),
        0
      )
    return (
      <>
        {Array.from({ length: rows }).map((_, rowIdx) => (
          <TableRow key={rowIdx}>
            {Array.from({ length: cellCount }).map((_, cellIdx) => (
              <TableCell key={cellIdx} sx={{ ...tableCellPadding }}>
                <Skeleton
                  variant="rectangular"
                  height={'32px'}
                  sx={{ marginTop: '3px', marginBottom: '3px' }}
                />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </>
    )
  }

  return (
    <BoxColumnBasic>
      <Table size="small" sx={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <FormulaTableHeaderCell sx={{ width: '40px' }} key="check">
              <Checkbox
                size="small"
                checked={allIngredientsChecked}
                onChange={() => handleCheckAllIngredientsChange()}
              />
            </FormulaTableHeaderCell>
            <SortableTableHeaderCell
              id={sortingSettings.sortingFieldIdGen(propertyToSortByPaths.name)}
              sortingSettings={sortingSettings}
              propertyToSortByPath={propertyToSortByPaths.name}
              label="Ingredient"
              containerComponent={FormulaTableHeaderCell}
              containerProps={{ sx: { width: '65%' } }}
            />
            {showActive && (
              <SortableTableHeaderCell
                id={sortingSettings.sortingFieldIdGen(
                  propertyToSortByPaths.active
                )}
                sortingSettings={sortingSettings}
                propertyToSortByPath={propertyToSortByPaths.active}
                label="Active?"
                containerComponent={FormulaTableHeaderCell}
                containerProps={{ sx: { width: '80px' } }}
              />
            )}
            {showSupplier && (
              <SortableTableHeaderCell
                id={sortingSettings.sortingFieldIdGen(
                  propertyToSortByPaths.supplier
                )}
                sortingSettings={sortingSettings}
                propertyToSortByPath={propertyToSortByPaths.supplier}
                label="Supplier"
                containerComponent={FormulaTableHeaderCell}
                containerProps={{ sx: { width: '35%' } }}
              />
            )}
            {showCost && (
              <SortableTableHeaderCell
                id={sortingSettings.sortingFieldIdGen(
                  propertyToSortByPaths.cost
                )}
                sortingSettings={sortingSettings}
                propertyToSortByPath={propertyToSortByPaths.cost}
                label="Cost ($)"
                containerComponent={FormulaTableHeaderCell}
                containerProps={{ sx: { width: '100px' }, align: 'left' }}
              />
            )}
            {showWastePercentage && (
              <SortableTableHeaderCell
                id={sortingSettings.sortingFieldIdGen(
                  propertyToSortByPaths.wastePercentage
                )}
                sortingSettings={sortingSettings}
                propertyToSortByPath={propertyToSortByPaths.wastePercentage}
                label="Waste %"
                containerComponent={FormulaTableHeaderCell}
                containerProps={{ sx: { width: '105px' }, align: 'left' }}
              />
            )}
            <SortableTableHeaderCell
              id={sortingSettings.sortingFieldIdGen(
                propertyToSortByPaths.quantity
              )}
              sortingSettings={sortingSettings}
              propertyToSortByPath={propertyToSortByPaths.quantity}
              label="Quantity"
              containerComponent={FormulaTableHeaderCell}
              containerProps={{
                sx: {
                  width: showQuantityPercentage ? '275px' : '175px'
                },
                align: 'right'
              }}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? getPlaceholderRows(1) : ingredientSearchRow}
        </TableBody>
      </Table>
      <Table sx={{ tableLayout: 'fixed' }}>
        <TableHead sx={{ height: 0 }}>
          <TableRow>
            <TableCell sx={{ width: '40px', padding: 0 }} key="check" />
            <TableCell key="ingredient" sx={{ width: '65%', padding: 0 }} />
            {showActive && (
              <TableCell key="active" sx={{ width: '80px', padding: 0 }} />
            )}
            {showSupplier && (
              <TableCell key="supplier" sx={{ width: '35%', padding: 0 }} />
            )}
            {showCost && (
              <TableCell key="cost" sx={{ width: '100px', padding: 0 }} />
            )}
            {showWastePercentage && (
              <TableCell
                key="wastePercentage"
                sx={{ width: '100px', padding: 0 }}
              />
            )}
            <TableCell
              key="quantity"
              sx={{
                width: showQuantityPercentage ? '280px' : '180px',
                padding: 0
              }}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {loading
            ? getPlaceholderRows(5)
            : ingredientRows.map((ir) => (
                <IngredientRow key={`ir-${ir.formulaIngredientId}`} {...ir} />
              ))}
        </TableBody>
      </Table>
    </BoxColumnBasic>
  )
}

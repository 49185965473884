import { ReactComponent as CloseCircle } from 'assets/CloseCircle.svg'
import { BoxRow } from 'components/common'
import React from 'react'

interface DeleteHoverableWrapperProps {
  children: React.ReactNode
  onDelete: () => void
  disabled?: boolean
  position?: 'left' | 'right'
  leaveSpace?: boolean
}

export const DeleteHoverableWrapper: React.FC<DeleteHoverableWrapperProps> = ({
  children,
  onDelete,
  disabled = false,
  position = 'left',
  leaveSpace = true
}) => {
  const [isHovering, setIsHovering] = React.useState<boolean>(false)

  const handleMouseEnter = () => {
    if (disabled) {
      return
    }
    if (!isHovering) {
      setIsHovering(true)
    }
  }
  const handleMouseLeave = () => {
    if (disabled) {
      return
    }
    if (isHovering) {
      setIsHovering(false)
    }
  }

  const DeleteButton: React.FC = () => (
    <CloseCircle
      role="button"
      title="remove"
      aria-label="remove"
      style={{
        cursor: 'pointer',
        visibility: isHovering ? 'visible' : 'hidden',
        width: '20px',
        height: '20px',
        flex: 'none'
      }}
      onClick={onDelete}
    />
  )

  return (
    <BoxRow
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{ gap: '5px' }}
    >
      {position === 'left' && (isHovering || leaveSpace) && <DeleteButton />}
      {children}
      {position === 'right' && (isHovering || leaveSpace) && <DeleteButton />}
    </BoxRow>
  )
}

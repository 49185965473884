import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import thunk from 'redux-thunk'
import AuthSlice from './auth/AuthSlice'
import FormulaSliceReducer from './formulas/FormulasSlice'
import FormulatorSlice from './formulator/FormulatorSlice'

import CompaniesSlice from './companies/CompaniesSlice'
import ingredientSlice from './ingredients/IngredientsSlice'
import InvitationsSlice from './invitations/InvitationsSlice'
import UsersSlice from './users/UsersSlice'

import AllergensSlice from './allergens/AllergensSlice'
import { asyncThunkMiddleware } from './asyncThunkMiddleware'
import CommentsSlice from './comments/CommentsSlice'
import CompanyUsersSlice from './companies/users/CompanyUsersSlice'
import FiltersSlice from './filters/FiltersSlice'
import FormulatorAllergensSlice from './formulator/allergens/FormulaAllergensSlice'
import FormulaNoteDocumentsSlice from './formulator/documents/FormulaNoteDocumentsSlice'
import FormulaTargetsSlice from './formulator/formulaTargets/FormulaTargetsSlice'
import FormulaHistorySlice from './formulator/history/FormulaHistorySlice'
import FormulatorIngredientsSlice from './formulator/ingredients/FormulatorIngredientsSlice'
import IngredientSearchSlice from './ingredients/search/IngredientSearchSlice'
import NutritionFactLabelsSlice from './labels/nutritions/NutritionFactLabelsSlice'
import IngredientStatementsSlice from './labels/statements/IngredientStatementsSlice'
import SimpleIngredientDocumentsSlice from './simple_ingredients/documents/SimpleIngredientDocumentsSlice'
import SimpleIngredientMeasurementsSlice from './simple_ingredients/measurements/SimpleIngredientMeasurementsSlice'
import SimpleIngredientsSlice from './simple_ingredients/SimpleIngredientsSlice'
import SupplierDocumentsSlice from './suppliers/documents/SupplierDocumentsSlice'
import SuppliersSlice from './suppliers/SuppliersSlice'
import TagsSlice from './tags/TagsSlice'
import { TargetsSlice } from './targets'
import YieldAdjustmentsSlice from './yield_adjustments/YieldAdjustmentsSlice'
import FormulaYieldAdjustmentsSlice from './formulator/yieldAdjustments/FormulaYieldAdjustmentsSlice'
import ProofsSlice from './labelproof/proofs/ProofsSice'
import ProoferLabelsSlice from './labelproof/proofer/ProoferLabelSlice'
import ProoferSlice from './labelproof/proofer/ProoferSlice'
import ProoferActivitiesSlice from './labelproof/proofer/ProoferActivitiesSlice'
import ReviewSlice from './labelproof/proofer/review/ReviewSlice'

/*
 * The top-level state object
 */

const rootReducer = combineReducers({
  formulas: FormulaSliceReducer,
  auth: AuthSlice,
  formulator: FormulatorSlice,
  formulatorIngredients: FormulatorIngredientsSlice,
  formulatorAllergens: FormulatorAllergensSlice,
  ingredientSearch: IngredientSearchSlice,
  companies: CompaniesSlice,
  invitations: InvitationsSlice,
  users: UsersSlice,
  ingredients: ingredientSlice,
  suppliers: SuppliersSlice,
  tags: TagsSlice,
  comments: CommentsSlice,
  companyUsers: CompanyUsersSlice,
  nutritionFactLabels: NutritionFactLabelsSlice,
  ingredientStatements: IngredientStatementsSlice,
  allergens: AllergensSlice,
  simpleIngredients: SimpleIngredientsSlice,
  formulaHistory: FormulaHistorySlice,
  simpleIngredientDocuments: SimpleIngredientDocumentsSlice,
  supplierDocuments: SupplierDocumentsSlice,
  filters: FiltersSlice,
  targets: TargetsSlice,
  formulaTargets: FormulaTargetsSlice,
  formulaNoteDocuments: FormulaNoteDocumentsSlice,
  simpleIngredientMeasurementSlice: SimpleIngredientMeasurementsSlice,
  yieldAdjustmentsSlice: YieldAdjustmentsSlice,
  formulaYieldAdjustmentsSlice: FormulaYieldAdjustmentsSlice,
  proofs: ProofsSlice,
  prooferLabel: ProoferLabelsSlice,
  proofer: ProoferSlice,
  prooferActivities: ProoferActivitiesSlice,
  review: ReviewSlice
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: [
          'payload.config',
          'payload.request',
          'payload.headers',
          'error',
          'meta.arg'
        ]
      },
      thunk: true
    }).concat(thunk, asyncThunkMiddleware),
  devTools: process.env.NODE_ENV !== 'production'
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

import { useLoadNextPage } from 'common/hooks'
import { QueriesContext } from 'components/EntrTable/Filter/QueriesContext'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { getSimpleIngredients } from 'state/simple_ingredients/SimpleIngredientsSlice'
import { useDebouncedCallback } from 'use-debounce'
import { PageViewerContext } from '../PageViewerContext'
import { IngredientsTable, IngredientsTableRow } from './IngredientsTable'

export const IngredientsTableContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const { sendShowRequest } = React.useContext(PageViewerContext)

  const { items, page, pages } = useAppSelector(
    (state) => state.simpleIngredients.simpleIngredients
  )
  const companyId = useAppSelector((state) => state.companies.currentCompany.id)
  const { filters, search } = React.useContext(QueriesContext)
  const { suppliers, tags, allergens } = filters

  const setCurrentPageCallback = React.useCallback(
    (page: number) => {
      void dispatch(
        getSimpleIngredients({
          companyId: companyId,
          page: page,
          fuzzyName: search,
          suppliers: suppliers,
          tags: tags,
          allergens: allergens
        })
      )
    },
    [companyId, search, suppliers, tags, allergens]
  )

  React.useEffect(() => {
    void dispatch(
      getSimpleIngredients({
        companyId: companyId,
        page: 1,
        fuzzyName: search,
        suppliers: suppliers,
        tags: tags,
        allergens: allergens
      })
    )
  }, [companyId, search, suppliers, tags, allergens])

  const { nextPageCallback, allPagesLoaded } = useLoadNextPage({
    page,
    pages,
    setCurrentPageCallback
  })

  const handleRowClick = useDebouncedCallback((ingredientId: string) => {
    // Debouncing is required to prevent user from fast clicking and sending multiple requests.
    sendShowRequest(ingredientId)
  }, 100)

  const rows: IngredientsTableRow[] = React.useMemo(() => {
    return items.map((item) => ({
      id: item.id,
      name: item.name,
      friendlyId: item.friendlyId,
      suppliers: item.suppliers.map((supplier) => supplier.name),
      tags: item.tags.map((tag) => tag.name)
    }))
  }, [items])

  return (
    <IngredientsTable
      allPagesLoaded={allPagesLoaded}
      nextPageCallback={nextPageCallback}
      onRowClick={handleRowClick}
      rows={rows}
    />
  )
}

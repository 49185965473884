import React from 'react'

interface Patterns {
  TAB: RegExp
  NEWLINE: RegExp
  BOLD: RegExp
  ITALIC: RegExp
  LINK: RegExp
  ESCAPE: RegExp
}

interface Templates {
  TAB: string
  NEWLINE: string
  BOLD: string
  ITALIC: string
  LINK: string
  ESCAPE: string
}

interface MarkdownRendererProps {
  markdownText: string
  style?: React.CSSProperties
  customTemplates?: Templates
}

const patterns: Patterns = {
  TAB: /\\t/g,
  NEWLINE: /\\n/g,
  BOLD: /\*\*(.*?)\*\*/g, // **text**
  ITALIC: /\*(.*?)\*/g, // *text*
  LINK: /\[([^\]]+?)\]\(((?:[^)(]+|\((?:[^)(]+|\([^)(]*\))*\))*)\)/g, // [text](link)
  ESCAPE: /<escape>(.*?)<\/escape>/gs // <escape>text</escape>
}

const templates: Templates = {
  TAB: '&nbsp;&nbsp;&nbsp;&nbsp;',
  NEWLINE: '<br/>',
  BOLD: '<b>$1</b>',
  ITALIC: '<i>$1</i>',
  LINK: "<a href='$2' target='_blank' rel='noopener noreferrer'>$1</a>",
  ESCAPE: '$1'
}

export const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({
  markdownText,
  style,
  customTemplates = templates
}) => {
  const escapeContent = (text: string) => {
    const escapedContent: string[] = []
    const processedText = text.replace(patterns.ESCAPE, (match, content1) => {
      escapedContent.push(content1) // Store the content to restore later
      return `__ESCAPED_${escapedContent.length - 1}__` // Replace with a placeholder
    })
    return { processedText, escapedContent }
  }

  const restoreEscapedContent = (text: string, escapedContent: string[]) => {
    return text.replace(/__ESCAPED_(\d+)__/g, (_, index) => {
      return escapedContent[Number(index)] // Restore the original escaped content
    })
  }

  const renderTextWithFormatting = () => {
    const { processedText, escapedContent } = escapeContent(markdownText)
    
    let formattedText = processedText
    const rules = Object.entries(patterns).map(([key, value]) => [
      value,
      customTemplates[key as keyof Templates]
    ])
    rules.forEach(([rule, template]) => {
      if (rule !== patterns.ESCAPE) {
        formattedText = formattedText.replace(rule, template)
      }
    })
    return restoreEscapedContent(formattedText, escapedContent)
  }

  return (
    <div
      dangerouslySetInnerHTML={{ __html: renderTextWithFormatting() }}
      onClick={(e) => e.stopPropagation()}
      style={{
        ...style,
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        maxWidth: '100%'
      }}
    />
  )
}

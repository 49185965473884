import { Chip } from '@mui/material'
import { Option } from 'components/common'
import React from 'react'

export type TagOption = Option<boolean>

export interface TagProps extends TagOption {
  onDelete: () => void
}

export const Tag: React.FC<TagProps> = ({ id, label, onDelete, disabled }) => {
  return (
    <Chip
      id={id}
      label={label}
      disabled={disabled}
      size="small"
      onDelete={onDelete}
    />
  )
}

import React from 'react'
import {
  LogicalOperator,
  NormalizedComparisonOperation,
  NormalizedLogicalOperation,
  NormalizedTargetOperation
} from 'models/Target'
import { BoxRow } from 'components/common'
import {
  isComparisonOperation,
  isConditionalOperation,
  isNormalizedLogicalOperation,
  isTargetOperation
} from 'services/apis/target/TargetApiMapper'
import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { deleteActiveOperation, updateActiveOperation } from 'state/targets'
import { DeleteHoverableWrapper } from '../../../../components/Wrappers/DeleteHoverableWrapper'
import { TargetOperation } from './leafOperations/TargetOperation'
import { ComparisonOperation } from './leafOperations/ComparisonOperation'
import { grayedOutInputStyle } from '../../TargetsStyles'
import { ConditionalOperation } from './leafOperations/ConditionalOperation'
import { SimpleAutoComplete } from './SimpleAutoComplete'

interface LeafOperationProps {
  operationId: string
  logicalOperator?: LogicalOperator
  parentOperationId?: string
  operation_index?: number
}

export const LeafOperation: React.FC<LeafOperationProps> = React.memo(
  ({ operationId, logicalOperator, parentOperationId, operation_index }) => {
    const dispatch = useAppDispatch()

    const operation = useAppSelector(
      (state) => state.targets.activeOperations[operationId]
    )

    const parentOperation = useAppSelector(
      (state) => state.targets.activeOperations[parentOperationId || '']
    )

    const isActiveTargetEditable = useAppSelector(
      (state) => state.targets.activeTarget.isEditable
    )

    const [selectedOperator, setSelectedOperator] = React.useState(
      parentOperation && isNormalizedLogicalOperation(parentOperation)
        ? parentOperation.operator
        : LogicalOperator.AND
    )

    const [newOperation, setNewOperation] = React.useState<
      NormalizedComparisonOperation | NormalizedTargetOperation | undefined
    >()

    const onParentLogicalOperatorChange = (
      newLogicalOperator: LogicalOperator
    ) => {
      if (
        isNormalizedLogicalOperation(parentOperation) &&
        parentOperation.operator !== newLogicalOperator
      ) {
        dispatch(
          updateActiveOperation({
            ...parentOperation,
            operator: newLogicalOperator
          } as NormalizedLogicalOperation)
        )
      }
    }

    React.useEffect(() => {
      if (!newOperation) return
      dispatch(updateActiveOperation(newOperation))
      setNewOperation(undefined)
    }, [newOperation])

    const getCurrentOperationElement = (): JSX.Element => {
      if (isComparisonOperation(operation)) {
        return (
          <ComparisonOperation
            operationId={operationId}
            setNewOperation={setNewOperation}
            disableEdit={!isActiveTargetEditable}
          />
        )
      }

      if (isTargetOperation(operation)) {
        return (
          <TargetOperation
            operationId={operationId}
            setNewOperation={setNewOperation}
            disableEdit={!isActiveTargetEditable}
          />
        )
      }

      if (isConditionalOperation(operation)) {
        return (
          <ConditionalOperation
            operationId={operationId}
            setNewOperation={setNewOperation}
            disableEdit={!isActiveTargetEditable}
          />
        )
      }

      return <></>
    }

    const handleOperatorChange = (
      event: SelectChangeEvent<LogicalOperator>
    ) => {
      const newLogicalOperator = event.target.value as LogicalOperator
      setSelectedOperator(newLogicalOperator)
      onParentLogicalOperatorChange(newLogicalOperator)
    }

    if (!operation || isNormalizedLogicalOperation(operation)) {
      return <></>
    }

    return (
      <DeleteHoverableWrapper
        disabled={!isActiveTargetEditable}
        onDelete={() => {
          if (!isActiveTargetEditable) return
          dispatch(
            deleteActiveOperation({
              operationId: operationId,
              parentOperationId: operation.parentOperationId
            })
          )
        }}
      >
        <BoxRow style={{ gap: '4px' }}>
          <BoxRow style={{ gap: '4px' }}>
            {operation_index === 0 ? (
              <Box style={{ ...grayedOutInputStyle, width: '72px' }}>
                {'Where'}
              </Box>
            ) : operation_index === 1 ? (
              <Select
                value={selectedOperator}
                onChange={handleOperatorChange}
                disabled={!isActiveTargetEditable}
                style={{ width: '72px', height: '32px' }}
              >
                <MenuItem value={LogicalOperator.AND}>
                  {LogicalOperator.AND}
                </MenuItem>
                <MenuItem value={LogicalOperator.OR}>
                  {LogicalOperator.OR}
                </MenuItem>
              </Select>
            ) : (
              <Box style={{ ...grayedOutInputStyle, width: '72px' }}>
                {logicalOperator}
              </Box>
            )}
          </BoxRow>

          {getCurrentOperationElement()}
        </BoxRow>
      </DeleteHoverableWrapper>
    )
  }
)

import React from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { MentionsInput, Mention, MentionItem } from 'react-mentions'
import { getCompanyUsers } from 'state/companies/users/CompanyUsersSlice'
import { setStagedComment } from 'state/labelproof/proofer/ProoferActivitiesSlice'

const highlightStyle = {
  backgroundColor: '#057deb1a',
  position: 'relative',
  zIndex: 1
}

export interface ProofCommentInputProps {
  disabled?: boolean
}

export const ProofCommentInput: React.FC<ProofCommentInputProps> = ({
  disabled
}) => {
  const dispatch = useAppDispatch()
  const stagedComment = useAppSelector((state) => state.prooferActivities.stagedComment)
  const currentUser = useAppSelector((state) => state.users.currentUser)
  const companyUsers = useAppSelector((state) => state.companyUsers.userRoles)
  const companyUsersLoading = useAppSelector(
    (state) => state.companyUsers.loading
  )

  React.useEffect(() => {
    void dispatch(getCompanyUsers({ companyId: currentUser.companyId }))
  }, [currentUser.companyId])

  const onChange = (
    event: { target: { value: string } },
    newValue: string,
    newPlainTextValue: string,
    mentions: MentionItem[]
  ) => {
    dispatch(
      setStagedComment({
        ...stagedComment,
        text: newValue,
        mentionedUserIds: mentions.map((mention) => mention.id)
      })
    )
  }

  const mentionDisplayTransform = React.useCallback(
    (id: string) => {
      if (companyUsersLoading) {
        return '@Loading...'
      }

      const user = companyUsers.find((user) => user.user.id === id)
      return user
        ? `@${user.user.firstName + ' ' + user.user.lastName}`
        : '@Unknown User'
    },
    [companyUsers, companyUsersLoading]
  )

  return (
    <MentionsInput
      value={stagedComment.text}
      onChange={onChange}
      forceSuggestionsAboveCursor={true}
      placeholder="Add a comment..."
      style={{
        control: {
          backgroundColor: '#fff',
          fontSize: 14,
          fontWeight: 'normal'
        },

        '&multiLine': {
          control: {
            minHeight: 63
          },
          highlighter: {
            padding: '15px',
            border: 'none'
          },
          input: {
            padding: '15px',
            border: 'none',
            lineHeight: 1.4
          }
        },

        suggestions: {
          list: {
            backgroundColor: 'white',
            border: '1px solid rgba(0,0,0,0.15)',
            fontSize: 14
          },
          item: {
            padding: '5px 15px',
            '&focused': {
              backgroundColor: '#057deb1a'
            }
          }
        }
      }}
    >
      <Mention
        trigger="@"
        markup="@{__id__}"
        data={companyUsers.map((user) => ({
          id: user.user.id,
          display: user.user.firstName + ' ' + user.user.lastName
        }))}
        displayTransform={mentionDisplayTransform}
        style={highlightStyle}
      />
    </MentionsInput>
  )
}

import { convertToFixedFloat } from 'common/utils'
import React from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { InputField, InputFieldProps } from './InputField'
import {
  runValidations,
  validateCharacters,
  validateDecimal,
  validateMinusSign,
  validateZeros
} from './validation'

export interface NumberFieldProps
  extends Omit<InputFieldProps, 'value' | 'onChange'> {
  onChange: (value: number) => void
  value?: number
  debounceTime?: number
  maxDecimals?: number
  validation?: '>0' | '>=0'
}

export const NumberField: React.FC<NumberFieldProps> = ({
  onChange,
  value = '',
  debounceTime = 0,
  maxDecimals = 0,
  validation,
  ...rest
}) => {
  const positive = ['>0', '>=0'].includes(validation ?? '')
  const nonZero = validation === '>0'
  const [pendingSubmission, setPendingSubmission] =
    React.useState<boolean>(false)
  const [incompleteInput, setIncompleteInput] = React.useState<boolean>(false)
  const [invalidInput, setInvalidInput] = React.useState<boolean>(false)

  // Start initially with a clean number.
  const [internalValue, setInternalValue] = React.useState<string>(
    convertToFixedFloat(value, maxDecimals, true)
  )
  // Save the last valid input as a fallback value.
  const [lastValidInput, setLastValidInput] = React.useState<string>(
    convertToFixedFloat(value, maxDecimals, true)
  )

  /**
   * Checks if user is still typing an incomplete number.
   * @param v The number to check.
   * @returns True if the user is still typing, false otherwise.
   */
  const isUserStillTyping = (v: string): boolean => {
    return v === '' || incompleteInput
  }

  /**
   * Marks that there is a pending change to be sent and starts the debounce timer.
   * @param newValue The value to be sent.
   */
  const submitChanges = (newValue: string) => {
    if (!areValuesEqual(newValue, value)) {
      setLastValidInput(newValue)
      setPendingSubmission(true)
      handleInputChangeDebounced(newValue)
    }
  }

  const cancelSubmit = () => {
    setPendingSubmission(false)
    handleInputChangeDebounced.cancel()
  }

  /**
   * Checks if 2 values are equal based on the conversion to a fixed float.
   * @param valueA The first value.
   * @param valueB The second value.
   * @returns True if the values are equal, false otherwise.
   */
  const areValuesEqual = (
    valueA?: string | number,
    valueB?: string | number
  ): boolean => {
    const a = convertToFixedFloat(valueA ?? '', maxDecimals, true)
    const b = convertToFixedFloat(valueB ?? '', maxDecimals, true)
    return a === b
  }

  React.useEffect(() => {
    // Check if the user is still typing and if there are no pending submissions.
    if (!isUserStillTyping(internalValue) && !pendingSubmission) {
      // If the value is different from the internal value, update the internal value with a clean input.
      if (!areValuesEqual(value, internalValue)) {
        const newValue = convertToFixedFloat(value, maxDecimals, true)
        setInternalValue(newValue)
      }
    }
  }, [value])

  const handleInputChangeDebounced = useDebouncedCallback(
    (inputValue: string) => {
      // Send the input as a number to the parent component.
      const numberInputValue = Number(inputValue)
      if (onChange) {
        onChange(numberInputValue)
        setPendingSubmission(false)
      }
    },
    debounceTime
  )

  const handleInputChange = (inputValue: string) => {
    // If it's an empty string, don't do anything until it's blurred.
    if (inputValue === '') {
      setInternalValue(inputValue)
      cancelSubmit()
      return
    }

    // Run all the validations.
    const result = runValidations(inputValue, [
      (value) => validateCharacters(value),
      (value) => validateDecimal(value, maxDecimals),
      (value) => validateMinusSign(value, positive),
      (value) => validateZeros(value, nonZero)
    ])

    setIncompleteInput(result.status === 'incomplete')
    setInvalidInput(result.invalid ?? false)

    // If the validation result has an input.
    if (result.newInput) {
      setInternalValue(result.newInput)
      // If the validation passed, submit the changes.
      if (result.status === 'pass') {
        submitChanges(result.newInput)
      }
    }
  }

  const handleBlur = () => {
    // If the user was still typing an invalid number and does not have pending submissions.
    let newInternalValue = internalValue
    if ((internalValue === '' || invalidInput) && !pendingSubmission) {
      // Default to 0 if possible or to the last valid input.
      newInternalValue = nonZero ? lastValidInput : '0'
    } else {
      // Otherwise, format the internal value properly regardless of submission.
      newInternalValue = convertToFixedFloat(internalValue, maxDecimals, true)
    }
    // Reset all the flags and submit the changes.
    setIncompleteInput(false)
    setInvalidInput(false)
    setInternalValue(newInternalValue)
    submitChanges(newInternalValue)
  }

  return (
    <InputField
      value={internalValue}
      onChange={handleInputChange}
      onBlur={handleBlur}
      {...rest}
    />
  )
}

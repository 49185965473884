import { BoxRowBasic } from 'components/common'
import { LoadingAnimation } from 'components/LoadingAnimation/LoadingAmination'
import { ZoomButtons } from 'components/ZoomButtons/ZoomButtons'
import React, { useCallback, useRef, useState } from 'react'
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper
} from 'react-zoom-pan-pinch'

interface ComponentPreviewProps {
  loading: boolean
  component: React.ReactNode
  loadingText?: string
  onTransformChange?: (ref: ReactZoomPanPinchRef) => void
}

export const ComponentPreview: React.FC<ComponentPreviewProps> = ({
  loading,
  component,
  onTransformChange,
  loadingText = 'Loading'
}) => {
  const MAX_SCALE = 5
  const MIN_SCALE = 0.5
  const transformWrapperRef = useRef<ReactZoomPanPinchRef>(null)
  const [currentScale, setCurrentScale] = useState<number>(1)

  const handleOnTransformed = useCallback(
    (ref: ReactZoomPanPinchRef, s: { scale: number }) => {
      setCurrentScale(s.scale)
      if (onTransformChange) {
        onTransformChange(ref)
      }
    },
    [setCurrentScale, onTransformChange]
  )

  React.useEffect(() => {
    if (transformWrapperRef.current) {
      transformWrapperRef.current.resetTransform()
    }
  }, [transformWrapperRef.current])

  return (
    <BoxRowBasic
      sx={{
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        height: '100%'
      }}
    >
      {loading && <LoadingAnimation text={loadingText} />}
      <TransformWrapper
        ref={transformWrapperRef}
        centerOnInit
        minScale={MIN_SCALE}
        maxScale={MAX_SCALE}
        centerZoomedOut
        onTransformed={handleOnTransformed}
      >
        {({ zoomIn, zoomOut, resetTransform }) => (
          <>
            <TransformComponent
              wrapperStyle={{
                width: '100%',
                height: '100%',
                backgroundColor: 'white'
              }}
            >
              {component}
            </TransformComponent>
            <ZoomButtons
              sx={{ bottom: '12px', left: '12px' }}
              onZoomInClicked={() => zoomIn()}
              onZoomOutClicked={() => zoomOut()}
              onZoomResetClicked={() => {
                resetTransform()
              }}
              zoomInDisabled={currentScale >= MAX_SCALE || loading}
              zoomOutDisabled={currentScale <= MIN_SCALE || loading}
            />
          </>
        )}
      </TransformWrapper>
    </BoxRowBasic>
  )
}

import {
  DisplayAmountOption,
  DisplayDvOption,
  FormulaNutritionFactLabelAgeGroup,
  FormulaNutritionFactLabelLangSpecificFields,
  FormulaNutritionFactLabelType,
  FormulaNutritionFactLanguage,
  FormulaNutritionFactNutrient
} from 'models/FormulaLabel'
import { NutrientRequirement, NutrientType } from 'models/Nutrient'
import {
  SupplementFactsIngredient,
  SupplementFactsNutrient
} from 'models/SupplementFacts'
import React from 'react'
import { SupplementFactsLabelVertical } from './SupplementFactsVertical/SupplementFactsLabelVertical'

export interface SupplementFactsLabelProps {
  servingSize: FormulaNutritionFactLabelLangSpecificFields
  servingWeight: string
  servingWeightOverride?: string
  servingsPerContainer: number
  type: FormulaNutritionFactLabelType
  ageGroup: FormulaNutritionFactLabelAgeGroup
  nutrients: SupplementFactsNutrient[]
  activeIngredients?: SupplementFactsIngredient[]
  selectedNutrients: NutrientType[]
  showProteinPercentage: boolean
}

export interface SupplementFactsProps {
  servingSize: FormulaNutritionFactLabelLangSpecificFields
  servingWeight: string
  servingsPerContainer: number
  nutrients: SupplementFactsNutrient[]
  activeIngredients: SupplementFactsIngredient[]
}

export const SupplementFactsLabel: React.FC<SupplementFactsLabelProps> = ({
  servingSize,
  servingWeight,
  servingWeightOverride,
  servingsPerContainer,
  nutrients,
  activeIngredients = [],
  selectedNutrients,
  showProteinPercentage
}) => {
  const mapDisplayNameOptions = (
    displayNameOptions: string[] | FormulaNutritionFactLabelLangSpecificFields
  ): FormulaNutritionFactLabelLangSpecificFields => {
    if (!Array.isArray(displayNameOptions)) {
      return displayNameOptions
    }

    return Object.values(FormulaNutritionFactLanguage).reduce(
      (acc, language, index) => {
        if (displayNameOptions[index]) {
          acc[language] = displayNameOptions[index]
        }
        return acc
      },
      {} as FormulaNutritionFactLabelLangSpecificFields
    )
  }

  const isNutrientNameOverrideAvailable = (
    nutrient: FormulaNutritionFactNutrient
  ): boolean => {
    return nutrient.overrideValues?.nutrientDisplayName
      ? Object.keys(nutrient.overrideValues?.nutrientDisplayName).length > 0
        ? true
        : false
      : false
  }

  const getNutrientDisplayNameOptions = (
    nutrient: FormulaNutritionFactNutrient
  ): FormulaNutritionFactLabelLangSpecificFields => {
    const originalNames = mapDisplayNameOptions(nutrient.displayNameOptions)

    if (isNutrientNameOverrideAvailable(nutrient)) {
      const overrides = nutrient.overrideValues
        ?.nutrientDisplayName as FormulaNutritionFactLabelLangSpecificFields

      // Combine overrides with original names, preferring overrides
      return Object.keys(originalNames).reduce((result, language) => {
        const lang = language as FormulaNutritionFactLanguage
        result[lang] = overrides[lang] || originalNames[lang]
        return result
      }, {} as FormulaNutritionFactLabelLangSpecificFields)
    }

    // Return original names if no overrides are available
    return originalNames
  }

  const getDisplayDvOptions = (
    nutrient: FormulaNutritionFactNutrient,
    showProteinDv: boolean,
    forContainerColumn?: boolean
  ): DisplayDvOption[] => {
    if (nutrient.nutrient.type === NutrientType.PROTEIN && !showProteinDv) {
      return [] as DisplayDvOption[]
    }
    const dvOverride = nutrient.overrideValues?.dv
    const perContainerDvOverride = nutrient.overrideValues?.perContainerDv
    const originalDv = nutrient.displayDvOptions
    if (forContainerColumn) {
      return perContainerDvOverride
        ? [{ amount: perContainerDvOverride }]
        : originalDv
    }
    return dvOverride ? [{ amount: dvOverride }] : originalDv
  }

  const getDisplayAmountOptions = (
    nutrient: FormulaNutritionFactNutrient
  ): DisplayAmountOption[] => {
    const amountOverride = nutrient.overrideValues?.amount
    const originalAmount = nutrient.displayAmountOptions
    return amountOverride ? [{ amount: amountOverride }] : originalAmount
  }

  const usedSources = React.useMemo(() => {
    // Find the selectedNutrients in nutrients.
    const usedNutrients = nutrients.filter((nutrient) =>
      selectedNutrients.includes(nutrient.nutrient.type)
    )

    return new Set(
      usedNutrients.flatMap((nutrient) =>
        nutrient.source.map((source) => source.id)
      )
    )
  }, [nutrients, selectedNutrients])

  const getActiveIngredients = (
    ingredients: SupplementFactsIngredient[]
  ): SupplementFactsIngredient[] => {
    return ingredients?.filter((i) => i.active && !usedSources.has(i.id)) || []
  }

  const getServingWeight = (
    servingWeight: string,
    servingSizeOverride?: string
  ): string => {
    return servingSizeOverride
      ? servingSizeOverride
      : servingWeight.toString() + 'g'
  }

  const getNutrients = (
    nutrients: SupplementFactsNutrient[],
    showProteinPercentage: boolean
  ): SupplementFactsNutrient[] => {
    return nutrients
      .filter(
        (sn) =>
          (sn.nutrient.requirement === NutrientRequirement.OPTIONAL &&
            selectedNutrients?.includes(sn.nutrient.type)) ||
          sn.nutrient.requirement === NutrientRequirement.MANDATORY
      )
      .map((a) => {
        return {
          nutrient: a.nutrient,
          displayDvOptions: getDisplayDvOptions(a, showProteinPercentage),
          displayAmountOptions: getDisplayAmountOptions(a),
          displayNameOptions: getNutrientDisplayNameOptions(a),
          source: a.source
        }
      })
      .sort((a, b) => a.nutrient.rank - b.nutrient.rank)
  }

  const props: SupplementFactsProps = {
    servingsPerContainer,
    servingSize,
    servingWeight: getServingWeight(servingWeight, servingWeightOverride),
    nutrients: getNutrients(nutrients, showProteinPercentage),
    activeIngredients: getActiveIngredients(activeIngredients)
  }

  return <SupplementFactsLabelVertical {...props} />
}

import React from 'react'
import { Review } from './Review'
import { ReviewListContainer } from './components/ReviewList/ReviewListContainer'
import { useAppDispatch } from 'state/hooks'
import { resetReviewState } from 'state/labelproof/proofer/review/ReviewSlice'

export const ReviewContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  React.useEffect(() => {
    return () => {
      void dispatch(resetReviewState())
    }
  }, [])

  return <Review reviewList={<ReviewListContainer />} />
}

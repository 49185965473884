import { Comment, CommentProps } from 'components/Comments/Comment'
import { mentionParser, pinParser } from 'components/Comments/TextParsers'
import React from 'react'

export interface ActivityCommentProps extends Omit<CommentProps, 'parsers'> {
  mentionedUsersMap: Record<string, string>
  pinMap: Record<string, string>
  onPinClick: (id: string) => void
}

export const ActivityComment: React.FC<ActivityCommentProps> = ({
  mentionedUsersMap,
  pinMap,
  onPinClick,
  ...rest
}) => {
  return (
    <Comment
      {...rest}
      parsers={[
        mentionParser(mentionedUsersMap),
        pinParser(pinMap, onPinClick)
      ]}
    />
  )
}

import {
  FormulaLabelType,
  FormulaNutritionFactLabelAgeGroup,
  FormulaNutritionFactLabelType
} from 'models/FormulaLabel'
import { RegulationName } from 'services/apis/regulation/RegulationApiResponse'

export const getCategories = (
  regulation?: string,
  labelType?: FormulaLabelType
) => {
  if (
    regulation === RegulationName.CANADA ||
    labelType === FormulaLabelType.SUPPLEMENT
  ) {
    return [
      {
        label: FormulaNutritionFactLabelAgeGroup.ADULT,
        value: FormulaNutritionFactLabelAgeGroup.ADULT
      }
    ]
  }

  if (regulation === RegulationName.FDA) {
    return Object.values(FormulaNutritionFactLabelAgeGroup).map((value) => {
      return {
        label: value,
        value: value
      }
    })
  }

  return []
}

export const getTypes = (regulation?: string) => {
  if (regulation === RegulationName.CANADA) {
    return [
      {
        label: FormulaLabelType.FOOD,
        value: FormulaLabelType.FOOD
      }
    ]
  }

  if (regulation === RegulationName.FDA) {
    return Object.values(FormulaLabelType).map((value) => {
      return {
        label: value,
        value: value
      }
    })
  }

  return []
}

export const getFormats = (
  regulation?: string,
  labelType?: FormulaLabelType
) => {
  if (regulation === RegulationName.CANADA) {
    return [
      {
        label: FormulaNutritionFactLabelType.VERTICAL,
        value: FormulaNutritionFactLabelType.VERTICAL
      },
      {
        label: FormulaNutritionFactLabelType.LINEAR,
        value: FormulaNutritionFactLabelType.LINEAR
      }
    ]
  }

  if (labelType === FormulaLabelType.SUPPLEMENT) {
    return [
      {
        label: FormulaNutritionFactLabelType.VERTICAL,
        value: FormulaNutritionFactLabelType.VERTICAL
      }
    ]
  }

  if (regulation === RegulationName.FDA) {
    return Object.values(FormulaNutritionFactLabelType).map((value) => {
      return {
        label: value,
        value: value
      }
    })
  }
  return []
}

import React from 'react'
import { BasicDocument } from 'models/Document'
import { ModalContext } from 'components/Modal/ModalContext'
import { FilePreviewContext } from 'components/FilePreview/FilePreviewContext'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import { LoadingStateContext } from 'components/LoadingUI/LoadingUIContext'
import { pluralize } from './utils'
import { SxProps } from '@mui/system'

interface UseDocumentsArgs {
  documents?: BasicDocument[]
  onUploadDocument?: (
    file: File,
    progressListener: (progress: number | undefined) => void
  ) => Promise<void>
  onPreviewDocument?: (document: BasicDocument) => Promise<string>
  onDownloadDocument?: (document: BasicDocument) => Promise<void>
  onDeleteDocument?: (document: BasicDocument) => Promise<void>
  refresh?: () => void
  validFileTypes?: string[]
  invalidFileTypeMessage?: string
}

export function useDocuments({
  onUploadDocument,
  onPreviewDocument,
  onDownloadDocument,
  onDeleteDocument,
  refresh,
  validFileTypes = [],
  invalidFileTypeMessage = 'Invalid file type'
}: UseDocumentsArgs): {
  uploadDocumentsCallback: (files: File[]) => void
  previewDocumentCallback: (file: BasicDocument) => void
  downloadDocumentCallback: (file: BasicDocument) => void
  deleteDocumentCallback: (file: BasicDocument | null) => void
} {
  const { showConfirmationModal } = React.useContext(ModalContext)
  const { showFilePreview } = React.useContext(FilePreviewContext)
  const { showError, showSuccess, showWarning } = React.useContext(SnackbarContext)
  const { showLoading, hideLoading, updateLoadingMessage } =
    React.useContext(LoadingStateContext)
  const [filesToUploadProgressMap, setFilesToUploadProgressMap] =
    React.useState<Map<string, number>>(new Map())

  const uploadDocumentsCallback = (files: File[]) => {
    if (!onUploadDocument) return
    setFilesToUploadProgressMap(new Map())
    if (files.length > 0) {
      if (validFileTypes.length > 0) {
        const invalidFiles = files.filter(
          (file) => !validFileTypes.includes(file.type)
        )
        if (invalidFiles.length === files.length) {
          hideLoading()
          showWarning(invalidFileTypeMessage)
          return
        }
      }

      showLoading({
        message: 'Uploading - 0%',
        blockUI: true,
        forceDisplay: true
      })
      const uploadPromises = files.map((file) => {
        return onUploadDocument(file, (progress) => {
          setFilesToUploadProgressMap((prevMap) => {
            if (!progress) return prevMap
            const updatedMap = new Map(prevMap)
            updatedMap.set(file.name, progress)
            return updatedMap
          })
        })
      })

      void Promise.allSettled(uploadPromises).then((results) => {
        if (refresh) {
          refresh()
        }
        const hasError = results.some((result) => result.status !== 'fulfilled')

        if (!hasError) {
          setTimeout(() => {
            hideLoading()
            showSuccess(
              `${pluralize(filesToUploadProgressMap.size, 'Document')}
                 uploaded`
            )
          }, 500)
        } else {
          showError(
            `There was a problem uploading the ${pluralize(
              files.length,
              'document'
            )}`
          )
        }
      })
    }
  }

  const previewDocumentCallback = (file: BasicDocument) => {
    if (!onPreviewDocument) return
    showFilePreview({
      fileName: file.name,
      downloadFileCallback: () => downloadDocumentCallback(file),
      downloadPreviewCallback: () => {
        return onPreviewDocument(file)
      },
      fileType: file.type
    })
  }

  const downloadDocumentCallback = (file: BasicDocument) => {
    if (!onDownloadDocument) return
    showLoading({
      message: 'Downloading document',
      blockUI: false
    })
    onDownloadDocument(file)
      .then(() => {
        hideLoading()
      })
      .catch(() => {
        showError(`There was a problem downloading ${file.name}`)
      })
  }

  const deleteDocumentCallback = (file: BasicDocument | null) => {
    if (!onDeleteDocument) return
    const deleteDocument = (file: BasicDocument | null) => {
      if (!file) return
      showLoading({
        message: 'Deleting document',
        blockUI: true
      })
      onDeleteDocument(file)
        .then(() => {
          hideLoading()
          showSuccess(`${file.name} deleted`)
        })
        .catch(() => {
          showError(`There was a problem deleting ${file.name}`)
        })
    }

    showConfirmationModal({
      title: 'Delete document',
      message: (
        <>
          Are you sure you want to delete <b>{file?.name}</b>?
        </>
      ),
      danger: true,
      yesText: 'Delete',
      noText: 'Cancel',
      onYesClicked: () => {
        deleteDocument(file)
      }
    })
  }

  React.useEffect(() => {
    if (filesToUploadProgressMap.size === 0) return

    const uploadTotalProgress = Array.from(
      filesToUploadProgressMap.values()
    ).reduce((total, current) => total + current, 0)

    const uploadAverageProgress = Math.round(
      (uploadTotalProgress / filesToUploadProgressMap.size) * 100
    )

    updateLoadingMessage(`Uploading - ${uploadAverageProgress}%`)
  }, [filesToUploadProgressMap])

  return {
    uploadDocumentsCallback,
    previewDocumentCallback,
    downloadDocumentCallback,
    deleteDocumentCallback
  }
}

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Formula, FormulaNutrient, FormulaNutrientsScale } from 'models/Formula'
import {
  FormulaNutritionFact,
  FormulaNutritionFactEdits,
  FormulaNutritionFactLabelAgeGroup,
  FormulaNutritionFactLabelHeader,
  FormulaNutritionFactLabelType,
  FormulaNutritionFactLanguage
} from 'models/FormulaLabel'
import { Nutrient } from 'models/Nutrient'
import { FormulaApi } from 'services/apis/formula/FormulaApi'
import {
  fromFormulaIngredientsStatementFormatsPreview,
  fromFormulaLabelStatementCaseType,
  fromNutritionFactLabelAgeGroup,
  fromNutritionFactLabelType,
  mapFromLanguageSpecificFields,
  toFormulaLabelStatementCaseType
} from 'services/apis/formula/FormulaApiMapper'
import { FormulaNutrientOverrideApiResponse } from 'services/apis/formula/FormulaApiResponse'
import { NutrientsApi } from 'services/apis/nutrient/NutrientApi'
import { RequirementFilter } from 'services/apis/nutrient/NutrientApiRequest'
import { RegulationApi } from 'services/apis/regulation/RegulationApi'
import { RegulationApiResponse } from 'services/apis/regulation/RegulationApiResponse'
import { toScaleNumber } from 'state/formulator/FormulatorSlice'
import { RootState } from 'state/store'
import { LoadingState, UpdatingState } from '../../CommonState'
import {
  AddFormulaNutritionFactLabelOptionalNutrientsRequest,
  GetFormulaNutrientRequest,
  GetFormulaNutritionFactLabelPreviewRequest,
  GetFormulaNutritionFactLabelRequest,
  GetFormulaReportRequest,
  GetOptionalNutrientsRequest,
  GetSupplementFactsPreviewRequest,
  GetSupplementFactsRequest,
  UpdateFormulaContainerWeightRequest,
  UpdateFormulaNutritionFactLabelAgeGroupRequest,
  UpdateFormulaNutritionFactLabelDescriptionRequest,
  UpdateFormulaNutritionFactLabelIngredientStatementRequest,
  UpdateFormulaNutritionFactLabelRegulationRequest,
  UpdateFormulaNutritionFactLabelServingSizeRequest,
  UpdateFormulaNutritionFactLabelServingsPerContainerRequest,
  UpdateFormulaNutritionFactLabelShowProteinPercentageRequest,
  UpdateFormulaNutritionFactLabelTypeRequest,
  UpdateNutritionFactLabelNutrientsOverrideRequest,
  UpdateNutritionFactsLabelLanguageSpecificFieldsRequest,
  UpdateNutritionFactsLabelRequest
} from './NutritionFactLabelsRequest'
import { SupplementFacts } from 'models/SupplementFacts'
import { fromNutrientType } from 'services/apis/nutrient/NutrientApiMapper'

export interface NutritionFactLabelsState extends LoadingState, UpdatingState {
  nutritionFactLabel?: FormulaNutritionFact | SupplementFacts
  nutritionFactLabelEdits: FormulaNutritionFactEdits
  availableOptionalNutrients: Nutrient[]
  availableRegulations: RegulationApiResponse[]
  formulaNutrients?: FormulaNutrient[][]
  loadingNFPLabel: boolean
}

const initialNutritionFactLabelEditsState: FormulaNutritionFactEdits = {
  allergens: [],
  ingredientsStatement: undefined,
  nutritionFactLabelPreview: {
    servingSize: {
      [FormulaNutritionFactLanguage.ENGLISH]: ''
    },
    servingsPerContainer: 1,
    type: FormulaNutritionFactLabelType.VERTICAL,
    servingWeight: 0,
    servingWeightOverride: '',
    containerWeight: 0,
    nutrients: [],
    optionalNutrientsType: [],
    calories: '0',
    ageGroup: FormulaNutritionFactLabelAgeGroup.ADULT,
    perContainer: undefined,
    description: {
      [FormulaNutritionFactLanguage.ENGLISH]: ''
    },
    ingredientStatement: {
      [FormulaNutritionFactLanguage.ENGLISH]: ''
    },
    allergenStatement: {
      [FormulaNutritionFactLanguage.ENGLISH]: ''
    },
    suggestedAllergenStatement: {
      [FormulaNutritionFactLanguage.ENGLISH]: ''
    },
    allergenStatementPrefix: {
      [FormulaNutritionFactLanguage.ENGLISH]: ''
    },
    showProteinPercentage: false,
    forceShowProteinPercentage: false,
    regulationId: '',
    pdcaas: 1,
    applyPdcaas: false,
    dvBasedOnRounded: false
  },
  isSupplement: false
}

const initialState: NutritionFactLabelsState = {
  loading: false,
  error: false,
  updating: false,
  nutritionFactLabelEdits: {
    ...initialNutritionFactLabelEditsState
  },
  availableOptionalNutrients: [],
  availableRegulations: [],
  nutritionFactLabel: undefined,
  formulaNutrients: undefined,
  loadingNFPLabel: false
}

export const getFormulaNutrients = createAsyncThunk(
  'formulas/nutritionFactLabel/nutrients',
  async (
    {
      companyId,
      formulaId,
      scale = FormulaNutrientsScale.SERVING_SIZE,
      ageGroup,
      regulationId
    }: GetFormulaNutrientRequest,
    { getState }
  ) => {
    const state = getState() as RootState
    const servingWeight = state.formulator.formula.servingWeight

    const scaleNumber = toScaleNumber(scale, servingWeight)

    return await FormulaApi.getFormulaNutrients(companyId, formulaId, {
      scale: scaleNumber,
      ageGroup: fromNutritionFactLabelAgeGroup(ageGroup),
      regulationId: regulationId
    })
  },
  {
    getPendingMeta: (action) => {
      return {
        requestId: action.requestId,
        sequentialThunk: true
      }
    }
  }
)

export const getReport = createAsyncThunk(
  'formulas/nutritionFactLabel/report',
  async ({
    companyId,
    formulaId,
    parameters,
    nfpFile,
    formulaLink
  }: GetFormulaReportRequest): Promise<string> => {
    return await FormulaApi.getFormulaReport(
      companyId,
      formulaId,
      {
        ...parameters
      },
      {
        formulaLink: formulaLink,
        nfpFile: nfpFile
      }
    )
  }
)

export const getAvailableOptionalNutrients = createAsyncThunk(
  'formulas/nutritionFactLabel/optionalNutrients/get',
  async ({
    regulationId,
    supplementNutrient = false
  }: GetOptionalNutrientsRequest): Promise<Nutrient[]> => {
    return await NutrientsApi.getNutrients({
      requirementsFilter: [RequirementFilter.OPTIONAL],
      regulationId: regulationId,
      supplementNutrient: supplementNutrient
    })
  }
)

export const getAvailableRegulations = createAsyncThunk(
  'formulas/nutritionFactLabel/regulations/get',
  async (): Promise<RegulationApiResponse[]> => {
    return await RegulationApi.getRegulations()
  }
)

export const getNutritionFactLabelPreview = createAsyncThunk(
  'formulas/nutritionFactLabel/preview/get',
  async ({
    companyId,
    formulaId,
    ageGroup,
    containerWeight,
    regulationId,
    showProteinPercentage,
    type,
    pdcaas,
    applyPdcaas,
    dvBasedOnRounded,
    statementCase,
    allergenTypes,
    formulaIngredientsStatementFormatPreview,
    servingSize,
    description,
    ingredientStatement,
    allergenStatement,
    optionalNutrientsTypes
  }: GetFormulaNutritionFactLabelPreviewRequest): Promise<FormulaNutritionFact> => {
    const ag = ageGroup ? fromNutritionFactLabelAgeGroup(ageGroup) : undefined
    const nfpType = type ? fromNutritionFactLabelType(type) : undefined
    const statementCaseType = statementCase
      ? fromFormulaLabelStatementCaseType(statementCase)
      : undefined
    const previewAllergenTypes = allergenTypes !== undefined
    // If container weight is specified, populate the per container as well.
    if (containerWeight !== undefined) {
      return await Promise.all([
        FormulaApi.getNutritionFacts(
          companyId,
          formulaId,
          {
            ageGroup: ag,
            type: nfpType,
            showProteinPercentage,
            pdcaas,
            applyPdcaas,
            dvBasedOnRounded,
            statementCase: statementCaseType,
            allergenTypes,
            previewAllergenTypes
          },
          {
            formulaIngredientsStatementFormat:
              formulaIngredientsStatementFormatPreview
                ? fromFormulaIngredientsStatementFormatsPreview(
                    formulaIngredientsStatementFormatPreview
                  )
                : undefined,
            servingSize: servingSize
              ? mapFromLanguageSpecificFields(servingSize)
              : undefined,
            description: description
              ? mapFromLanguageSpecificFields(description)
              : undefined,
            ingredientStatement: ingredientStatement
              ? mapFromLanguageSpecificFields(ingredientStatement)
              : undefined,
            allergenStatement: allergenStatement
              ? mapFromLanguageSpecificFields(allergenStatement)
              : undefined,
            optionalNutrientsTypes:
              optionalNutrientsTypes &&
              optionalNutrientsTypes.map((nutrientType) =>
                fromNutrientType(nutrientType)
              )
          }
        ),
        FormulaApi.getNutritionFacts(
          companyId,
          formulaId,
          {
            ageGroup: ag,
            type: nfpType,
            pdcaas,
            showProteinPercentage,
            applyPdcaas,
            dvBasedOnRounded,
            servingWeight: containerWeight,
            statementCase: statementCaseType,
            allergenTypes,
            previewAllergenTypes
          },
          {
            formulaIngredientsStatementFormat:
              formulaIngredientsStatementFormatPreview
                ? fromFormulaIngredientsStatementFormatsPreview(
                    formulaIngredientsStatementFormatPreview
                  )
                : undefined,
            servingSize: servingSize
              ? mapFromLanguageSpecificFields(servingSize)
              : undefined,
            description: description
              ? mapFromLanguageSpecificFields(description)
              : undefined,
            ingredientStatement: ingredientStatement
              ? mapFromLanguageSpecificFields(ingredientStatement)
              : undefined,
            allergenStatement: allergenStatement
              ? mapFromLanguageSpecificFields(allergenStatement)
              : undefined,
            optionalNutrientsTypes:
              optionalNutrientsTypes &&
              optionalNutrientsTypes.map((nutrientType) =>
                fromNutrientType(nutrientType)
              )
          }
        )
      ]).then((res) => {
        const [perServing, perContainer] = res
        return {
          ...perServing,
          perContainer: {
            calories: perContainer.calories,
            containerWeight: perContainer.servingWeight,
            nutrients: perContainer.nutrients
          }
        }
      })
    } else {
      return await FormulaApi.getNutritionFacts(
        companyId,
        formulaId,
        {
          ageGroup: ag,
          regulationId: regulationId,
          type: nfpType,
          showProteinPercentage,
          pdcaas,
          applyPdcaas,
          dvBasedOnRounded,
          statementCase: statementCaseType,
          allergenTypes,
          previewAllergenTypes
        },
        {
          formulaIngredientsStatementFormat:
            formulaIngredientsStatementFormatPreview
              ? fromFormulaIngredientsStatementFormatsPreview(
                  formulaIngredientsStatementFormatPreview
                )
              : undefined,
          servingSize: servingSize
            ? mapFromLanguageSpecificFields(servingSize)
            : undefined,
          description: description
            ? mapFromLanguageSpecificFields(description)
            : undefined,
          ingredientStatement: ingredientStatement
            ? mapFromLanguageSpecificFields(ingredientStatement)
            : undefined,
          allergenStatement: allergenStatement
            ? mapFromLanguageSpecificFields(allergenStatement)
            : undefined,
          optionalNutrientsTypes:
            optionalNutrientsTypes &&
            optionalNutrientsTypes.map((nutrientType) =>
              fromNutrientType(nutrientType)
            )
        }
      )
    }
  }
)

export const getSupplementFactsPreview = createAsyncThunk(
  'formulas/supplementFacts/preview/get',
  async ({
    companyId,
    formulaId,
    showProteinPercentage,
    pdcaas,
    applyPdcaas,
    dvBasedOnRounded,
    servingSize,
    description,
    ingredientStatement,
    allergenStatement,
    optionalNutrientsTypes,
    allergenTypes,
    statementCase
  }: GetSupplementFactsPreviewRequest): Promise<SupplementFacts> => {
    return await FormulaApi.getSupplementFacts(
      companyId,
      formulaId,
      {
        showProteinPercentage,
        pdcaas,
        applyPdcaas,
        dvBasedOnRounded,
        allergenTypes,
        statementCase: statementCase
          ? fromFormulaLabelStatementCaseType(statementCase)
          : undefined,
        previewAllergenTypes: true
      },
      {
        servingSize: servingSize
          ? mapFromLanguageSpecificFields(servingSize)
          : undefined,
        description: description
          ? mapFromLanguageSpecificFields(description)
          : undefined,
        ingredientStatement: ingredientStatement
          ? mapFromLanguageSpecificFields(ingredientStatement)
          : undefined,
        allergenStatement: allergenStatement
          ? mapFromLanguageSpecificFields(allergenStatement)
          : undefined,
        optionalNutrientsTypes:
          optionalNutrientsTypes &&
          optionalNutrientsTypes.map((nutrientType) =>
            fromNutrientType(nutrientType)
          )
      }
    )
  }
)

export const getSupplementFacts = createAsyncThunk(
  'formulas/supplementFacts/get',
  async ({
    companyId,
    formulaId
  }: GetSupplementFactsRequest): Promise<SupplementFacts> => {
    return await FormulaApi.getSupplementFacts(companyId, formulaId)
  }
)

export const getNutritionFactLabel = createAsyncThunk(
  'formulas/nutritionFactLabel/get',
  async ({
    companyId,
    formulaId
  }: GetFormulaNutritionFactLabelRequest): Promise<FormulaNutritionFact> => {
    const perServingRes = await FormulaApi.getNutritionFacts(
      companyId,
      formulaId,
      {}
    )

    // If it's a dual, get the container weight for the formula and get the container nutrients.
    if (perServingRes.type === FormulaNutritionFactLabelType.DUAL) {
      const containerWeight = perServingRes.containerWeight || 0

      const perContainerRes = await FormulaApi.getNutritionFacts(
        companyId,
        formulaId,
        {
          servingWeight: containerWeight
        }
      )

      return {
        ...perServingRes,
        perContainer: {
          calories: perContainerRes.calories,
          containerWeight: perContainerRes.servingWeight,
          nutrients: perContainerRes.nutrients
        }
      }
    }

    return perServingRes
  }
)

export const updateNutritionFactLabelServingSize = createAsyncThunk(
  'formulas/nutritionFactLabel/servingSize/update',
  async ({
    companyId,
    formulaId,
    servingSize,
    language = FormulaNutritionFactLanguage.ENGLISH
  }: UpdateFormulaNutritionFactLabelServingSizeRequest): Promise<
    FormulaNutritionFactLabelHeader & { language: FormulaNutritionFactLanguage }
  > => {
    const response = await FormulaApi.updateNutritionFacts(
      companyId,
      formulaId,
      {
        servingSize
      },
      {
        language
      }
    )
    return {
      ...response,
      language
    }
  }
)

export const updateNutritionFactLabelServingsPerContainer = createAsyncThunk(
  'formulas/nutritionFactLabel/servingsPerContainer/update',
  async ({
    companyId,
    formulaId,
    servingsPerContainer
  }: UpdateFormulaNutritionFactLabelServingsPerContainerRequest): Promise<FormulaNutritionFactLabelHeader> => {
    return await FormulaApi.updateNutritionFacts(companyId, formulaId, {
      servingsPerContainer
    })
  }
)

export const updateNutritionFactLabelNutrientsOverride = createAsyncThunk(
  'formulas/nutritionFactLabel/nutrients/override',
  async ({
    companyId,
    formulaId,
    regulationId,
    nutrients
  }: UpdateNutritionFactLabelNutrientsOverrideRequest): Promise<
    FormulaNutrientOverrideApiResponse[]
  > => {
    return await FormulaApi.overrideFormulaNutrients(
      companyId,
      formulaId,
      regulationId,
      nutrients
    )
  }
)

export const updateNutritionFactsLabel = createAsyncThunk(
  'formulas/nutritionFactsLabel/update',
  async ({
    companyId,
    formulaId,
    ageGroup,
    regulationId,
    servingsPerContainer,
    showProteinPercentage,
    type,
    pdcaas,
    applyPdcaas,
    servingWeightOverride,
    dvBasedOnRounded
  }: UpdateNutritionFactsLabelRequest): Promise<FormulaNutritionFactLabelHeader> => {
    return await FormulaApi.updateNutritionFacts(companyId, formulaId, {
      ageGroup: ageGroup ? fromNutritionFactLabelAgeGroup(ageGroup) : undefined,
      type: type ? fromNutritionFactLabelType(type) : undefined,
      regulationId,
      servingsPerContainer,
      showProteinPercentage,
      pdcaas,
      applyPdcaas,
      servingWeightOverride,
      dvBasedOnRounded
    })
  }
)

export const updateNutritionFactsLabelLangSpecificFields = createAsyncThunk(
  'formulas/nutritionFactsLabel/updateLangSpecificFields',
  async ({
    companyId,
    formulaId,
    description,
    ingredientStatement,
    allergenStatement,
    servingSize,
    language
  }: UpdateNutritionFactsLabelLanguageSpecificFieldsRequest): Promise<
    FormulaNutritionFactLabelHeader & { language: FormulaNutritionFactLanguage }
  > => {
    const response = await FormulaApi.updateNutritionFacts(
      companyId,
      formulaId,
      {
        description,
        ingredientStatement,
        allergenStatement,
        servingSize
      },
      {
        language
      }
    )
    return {
      ...response,
      language
    }
  }
)

export const updateFormulaContainerWeight = createAsyncThunk(
  'formulas/nutritionFactLabel/containerWeight/update',
  async ({
    companyId,
    formulaId,
    containerWeight
  }: UpdateFormulaContainerWeightRequest) => {
    return await FormulaApi.updateFormula(companyId, formulaId, {
      containerWeight
    })
  }
)

export const updateNutritionFactLabelType = createAsyncThunk(
  'formulas/nutritionFactLabel/type/update',
  async ({
    companyId,
    formulaId,
    type
  }: UpdateFormulaNutritionFactLabelTypeRequest): Promise<FormulaNutritionFactLabelHeader> => {
    const t = fromNutritionFactLabelType(type)
    return await FormulaApi.updateNutritionFacts(companyId, formulaId, {
      type: t
    })
  }
)

export const updateNutritionFactLabelRegulation = createAsyncThunk(
  'formulas/nutritionFactLabel/regulation/update',
  async ({
    companyId,
    formulaId,
    regulationId
  }: UpdateFormulaNutritionFactLabelRegulationRequest): Promise<FormulaNutritionFactLabelHeader> => {
    return await FormulaApi.updateNutritionFacts(companyId, formulaId, {
      regulationId
    })
  }
)

export const updateNutritionFactLabelAgeGroup = createAsyncThunk(
  'formulas/nutritionFactLabel/ageGroup/update',
  async ({
    companyId,
    formulaId,
    ageGroup
  }: UpdateFormulaNutritionFactLabelAgeGroupRequest): Promise<FormulaNutritionFactLabelHeader> => {
    const ag = fromNutritionFactLabelAgeGroup(ageGroup)
    return await FormulaApi.updateNutritionFacts(companyId, formulaId, {
      ageGroup: ag
    })
  }
)

export const updateNutritionFactLabelDescription = createAsyncThunk(
  'formulas/nutritionFactLabel/description/update',
  async ({
    companyId,
    formulaId,
    description,
    language = FormulaNutritionFactLanguage.ENGLISH
  }: UpdateFormulaNutritionFactLabelDescriptionRequest): Promise<
    FormulaNutritionFactLabelHeader & { language: FormulaNutritionFactLanguage }
  > => {
    const response = await FormulaApi.updateNutritionFacts(
      companyId,
      formulaId,
      {
        description
      },
      {
        language
      }
    )
    return {
      ...response,
      language
    }
  }
)

export const updateNutritionFactLabelIngredientStatement = createAsyncThunk(
  'formulas/nutritionFactLabel/ingredientStatement/update',
  async ({
    companyId,
    formulaId,
    ingredientStatement,
    language = FormulaNutritionFactLanguage.ENGLISH
  }: UpdateFormulaNutritionFactLabelIngredientStatementRequest): Promise<
    FormulaNutritionFactLabelHeader & { language: FormulaNutritionFactLanguage }
  > => {
    const response = await FormulaApi.updateNutritionFacts(
      companyId,
      formulaId,
      {
        ingredientStatement
      },
      {
        language
      }
    )
    return {
      ...response,
      language
    }
  }
)

export const updateNutritionFactLabelShowProteinPercentage = createAsyncThunk(
  'formulas/nutritionFactLabel/showProteinPercentage/update',
  async ({
    companyId,
    formulaId,
    showProteinPercentage
  }: UpdateFormulaNutritionFactLabelShowProteinPercentageRequest): Promise<FormulaNutritionFactLabelHeader> => {
    return await FormulaApi.updateNutritionFacts(companyId, formulaId, {
      showProteinPercentage
    })
  }
)

export const addNutritionFactLabelOptionalNutrients = createAsyncThunk(
  'formulas/nutritionFactLabel/optionalNutrients/add',
  async ({
    companyId,
    formulaId,
    nutrientTypes
  }: AddFormulaNutritionFactLabelOptionalNutrientsRequest): Promise<
    Nutrient[]
  > => {
    return await FormulaApi.addNutritionFactsOptionalNutrients(
      companyId,
      formulaId,
      {
        nutrientTypes
      }
    )
  }
)

export const removeNutritionFactLabelOptionalNutrients = createAsyncThunk(
  'formulas/nutritionFactLabel/optionalNutrients/remove',
  async ({
    companyId,
    formulaId,
    nutrientTypes
  }: AddFormulaNutritionFactLabelOptionalNutrientsRequest): Promise<
    string[]
  > => {
    return await FormulaApi.removeNutritionFactsOptionalNutrients(
      companyId,
      formulaId,
      {
        nutrientTypes
      }
    ).then(() => {
      return nutrientTypes
    })
  }
)

const nutritionFactLabelsSlice = createSlice({
  name: 'nutritionFactLabelsSlice',
  initialState,
  reducers: {
    setNutritionFactLabelEdits(
      state,
      action: PayloadAction<FormulaNutritionFactEdits>
    ) {
      state.nutritionFactLabelEdits = action.payload
    },
    clearNutritionFactLabelEdits(state) {
      state.nutritionFactLabelEdits = { ...initialNutritionFactLabelEditsState }
    },
    resetState: (state) => {
      Object.assign(state, initialState)
    }
  },
  extraReducers(builder) {
    builder.addCase(getNutritionFactLabel.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(getNutritionFactLabel.fulfilled, (state, action) => {
      state.nutritionFactLabel = action.payload
      state.loading = false
    })
    builder.addCase(getNutritionFactLabel.rejected, (state) => {
      state.loading = false
      state.error = true
    })
    builder.addCase(getSupplementFacts.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(getSupplementFacts.fulfilled, (state, action) => {
      state.nutritionFactLabel = action.payload
      state.loading = false
    })
    builder.addCase(getSupplementFacts.rejected, (state) => {
      state.loading = false
      state.error = true
    })

    builder.addCase(getNutritionFactLabelPreview.pending, (state) => {
      state.loading = true
      state.loadingNFPLabel = true
      state.error = false
    })
    builder.addCase(
      getNutritionFactLabelPreview.fulfilled,
      (state, action: PayloadAction<FormulaNutritionFact>) => {
        state.nutritionFactLabelEdits.nutritionFactLabelPreview.nutrients = [
          ...action.payload.nutrients
        ]
        state.nutritionFactLabelEdits.nutritionFactLabelPreview.perContainer =
          action.payload.perContainer
            ? {
                ...action.payload.perContainer
              }
            : undefined
        state.nutritionFactLabelEdits.nutritionFactLabelPreview.forceShowProteinPercentage =
          action.payload.forceShowProteinPercentage
        state.nutritionFactLabelEdits.nutritionFactLabelPreview.showProteinPercentage =
          action.payload.showProteinPercentage
        state.nutritionFactLabelEdits.nutritionFactLabelPreview.description = {
          ...action.payload.description
        }
        state.nutritionFactLabelEdits.nutritionFactLabelPreview.servingSize = {
          ...action.payload.servingSize
        }
        state.nutritionFactLabelEdits.nutritionFactLabelPreview.ingredientStatement =
          {
            ...action.payload.ingredientStatement
          }
        state.nutritionFactLabelEdits.nutritionFactLabelPreview.allergenStatement =
          {
            ...action.payload.allergenStatement
          }
        state.nutritionFactLabelEdits.nutritionFactLabelPreview.suggestedAllergenStatement =
          {
            ...action.payload.suggestedAllergenStatement
          }
        state.nutritionFactLabelEdits.nutritionFactLabelPreview.allergenStatementPrefix =
          {
            ...action.payload.allergenStatementPrefix
          }
        state.nutritionFactLabelEdits.nutritionFactLabelPreview.optionalNutrientsType =
          [...action.payload.optionalNutrientsType]
        // Remove the activeIngredients if they are present. This could be the case if switching from Supplement to Food Labels.
        if (
          'activeIngredients' in
          state.nutritionFactLabelEdits.nutritionFactLabelPreview
        ) {
          ;(
            state.nutritionFactLabelEdits
              .nutritionFactLabelPreview as SupplementFacts
          ).activeIngredients = undefined
        }

        state.loading = false
        state.loadingNFPLabel = false
      }
    )
    builder.addCase(getNutritionFactLabelPreview.rejected, (state) => {
      state.loading = false
      state.error = true
      state.loadingNFPLabel = false
    })

    builder.addCase(getSupplementFactsPreview.pending, (state) => {
      state.loading = true
      state.loadingNFPLabel = true
      state.error = false
    })
    builder.addCase(
      getSupplementFactsPreview.fulfilled,
      (state, action: PayloadAction<SupplementFacts>) => {
        state.nutritionFactLabelEdits.nutritionFactLabelPreview.nutrients = [
          ...action.payload.nutrients
        ]
        state.nutritionFactLabelEdits.nutritionFactLabelPreview.perContainer =
          action.payload.perContainer
            ? {
                ...action.payload.perContainer
              }
            : undefined
        state.nutritionFactLabelEdits.nutritionFactLabelPreview.forceShowProteinPercentage =
          action.payload.forceShowProteinPercentage
        state.nutritionFactLabelEdits.nutritionFactLabelPreview.showProteinPercentage =
          action.payload.showProteinPercentage
        state.nutritionFactLabelEdits.nutritionFactLabelPreview.description = {
          ...action.payload.description
        }
        state.nutritionFactLabelEdits.nutritionFactLabelPreview.servingSize = {
          ...action.payload.servingSize
        }
        state.nutritionFactLabelEdits.nutritionFactLabelPreview.ingredientStatement =
          {
            ...action.payload.ingredientStatement
          }
        state.nutritionFactLabelEdits.nutritionFactLabelPreview.allergenStatement =
          {
            ...action.payload.allergenStatement
          }
        state.nutritionFactLabelEdits.nutritionFactLabelPreview.suggestedAllergenStatement =
          {
            ...action.payload.suggestedAllergenStatement
          }
        state.nutritionFactLabelEdits.nutritionFactLabelPreview.allergenStatementPrefix =
          {
            ...action.payload.allergenStatementPrefix
          }
        ;(
          state.nutritionFactLabelEdits
            .nutritionFactLabelPreview as SupplementFacts
        ).activeIngredients = action.payload.activeIngredients
          ? [...action.payload.activeIngredients]
          : []
        state.nutritionFactLabelEdits.nutritionFactLabelPreview.optionalNutrientsType =
          [...action.payload.optionalNutrientsType]
        state.loading = false
        state.loadingNFPLabel = false
      }
    )
    builder.addCase(getSupplementFactsPreview.rejected, (state) => {
      state.loading = false
      state.error = true
      state.loadingNFPLabel = false
    })

    builder.addCase(updateNutritionFactLabelServingSize.pending, (state) => {
      state.updating = true
      state.error = false
    })
    builder.addCase(
      updateNutritionFactLabelServingSize.fulfilled,
      (state, action) => {
        if (state.nutritionFactLabel) {
          state.nutritionFactLabel.servingSize = {
            ...state.nutritionFactLabel.servingSize,
            [action.payload.language]:
              action.payload.servingSize[action.payload.language]
          }
        }
        state.updating = false
      }
    )
    builder.addCase(updateNutritionFactLabelServingSize.rejected, (state) => {
      state.updating = false
      state.error = true
    })

    // updateFormulaContainerWeight
    builder.addCase(updateFormulaContainerWeight.pending, (state) => {
      state.updating = true
    })
    builder.addCase(
      updateFormulaContainerWeight.fulfilled,
      (state, action: PayloadAction<Formula>) => {
        if (state.nutritionFactLabel) {
          state.nutritionFactLabel.containerWeight =
            action.payload.containerWeight || 0
        }
        state.updating = false
      }
    )
    builder.addCase(updateFormulaContainerWeight.rejected, (state) => {
      state.updating = false
    })

    builder.addCase(
      updateNutritionFactLabelServingsPerContainer.pending,
      (state) => {
        state.updating = true
        state.error = false
      }
    )
    builder.addCase(
      updateNutritionFactLabelServingsPerContainer.fulfilled,
      (state, action) => {
        if (state.nutritionFactLabel) {
          state.nutritionFactLabel.servingsPerContainer =
            action.payload.servingsPerContainer
        }
        state.updating = false
      }
    )
    builder.addCase(
      updateNutritionFactLabelServingsPerContainer.rejected,
      (state) => {
        state.updating = false
        state.error = true
      }
    )

    builder.addCase(updateNutritionFactLabelType.pending, (state) => {
      state.updating = true
      state.error = false
    })
    builder.addCase(updateNutritionFactLabelType.fulfilled, (state, action) => {
      if (state.nutritionFactLabel) {
        state.nutritionFactLabel.type = action.payload.type
      }
      state.updating = false
    })
    builder.addCase(updateNutritionFactLabelType.rejected, (state) => {
      state.updating = false
      state.error = true
    })

    builder.addCase(updateNutritionFactLabelRegulation.pending, (state) => {
      state.updating = true
      state.error = false
    })
    builder.addCase(
      updateNutritionFactLabelRegulation.fulfilled,
      (state, action) => {
        if (state.nutritionFactLabel) {
          state.nutritionFactLabel.regulationId = action.payload.regulationId
        }
        state.updating = false
      }
    )
    builder.addCase(updateNutritionFactLabelRegulation.rejected, (state) => {
      state.updating = false
      state.error = true
    })

    builder.addCase(updateNutritionFactLabelAgeGroup.pending, (state) => {
      state.updating = true
      state.error = false
    })
    builder.addCase(
      updateNutritionFactLabelAgeGroup.fulfilled,
      (state, action) => {
        if (state.nutritionFactLabel) {
          state.nutritionFactLabel.ageGroup = action.payload.ageGroup
        }
        state.updating = false
      }
    )
    builder.addCase(updateNutritionFactLabelAgeGroup.rejected, (state) => {
      state.updating = false
      state.error = true
    })

    builder.addCase(updateNutritionFactsLabel.pending, (state) => {
      state.updating = true
      state.error = false
    })
    builder.addCase(updateNutritionFactsLabel.fulfilled, (state, action) => {
      if (state.nutritionFactLabel) {
        if (state.nutritionFactLabel) {
          state.nutritionFactLabel = {
            ...state.nutritionFactLabel,
            ...action.payload,
            servingSize: {
              ...state.nutritionFactLabel.servingSize
            },
            description: {
              ...state.nutritionFactLabel.description
            },
            ingredientStatement: {
              ...state.nutritionFactLabel.ingredientStatement
            }
          }
        }
      }
      state.updating = false
    })
    builder.addCase(updateNutritionFactsLabel.rejected, (state) => {
      state.updating = false
      state.error = true
    })

    builder.addCase(
      updateNutritionFactsLabelLangSpecificFields.pending,
      (state) => {
        state.updating = true
        state.error = false
      }
    )
    builder.addCase(
      updateNutritionFactsLabelLangSpecificFields.fulfilled,
      (state, action) => {
        if (state.nutritionFactLabel) {
          state.nutritionFactLabel = {
            ...state.nutritionFactLabel,
            ...action.payload,
            servingSize: {
              ...state.nutritionFactLabel.servingSize,
              [action.payload.language]:
                action.payload.servingSize[action.payload.language]
            },
            description: {
              ...state.nutritionFactLabel.description,
              [action.payload.language]:
                action.payload.description[action.payload.language]
            },
            ingredientStatement: {
              ...state.nutritionFactLabel.ingredientStatement,
              [action.payload.language]:
                action.payload.ingredientStatement[action.payload.language]
            },
            allergenStatement: {
              ...state.nutritionFactLabel.allergenStatement,
              [action.payload.language]:
                action.payload.allergenStatement[action.payload.language]
            }
          }
        }
        state.updating = false
      }
    )
    builder.addCase(
      updateNutritionFactsLabelLangSpecificFields.rejected,
      (state) => {
        state.updating = false
        state.error = true
      }
    )

    builder.addCase(updateNutritionFactLabelDescription.pending, (state) => {
      state.updating = true
      state.error = false
    })
    builder.addCase(
      updateNutritionFactLabelDescription.fulfilled,
      (state, action) => {
        if (state.nutritionFactLabel) {
          state.nutritionFactLabel.description = {
            ...state.nutritionFactLabel.description,
            [action.payload.language]:
              action.payload.description[action.payload.language]
          }
        }
        state.updating = false
      }
    )
    builder.addCase(updateNutritionFactLabelDescription.rejected, (state) => {
      state.updating = false
      state.error = true
    })

    builder.addCase(
      updateNutritionFactLabelIngredientStatement.pending,
      (state) => {
        state.updating = true
        state.error = false
      }
    )
    builder.addCase(
      updateNutritionFactLabelIngredientStatement.fulfilled,
      (state, action) => {
        if (state.nutritionFactLabel) {
          state.nutritionFactLabel.ingredientStatement = {
            ...state.nutritionFactLabel.ingredientStatement,
            [action.payload.language]:
              action.payload.ingredientStatement[action.payload.language]
          }
        }
        state.updating = false
      }
    )
    builder.addCase(
      updateNutritionFactLabelIngredientStatement.rejected,
      (state) => {
        state.updating = false
        state.error = true
      }
    )

    builder.addCase(
      updateNutritionFactLabelShowProteinPercentage.pending,
      (state) => {
        state.updating = true
        state.error = false
      }
    )
    builder.addCase(
      updateNutritionFactLabelShowProteinPercentage.fulfilled,
      (state, action) => {
        if (state.nutritionFactLabel) {
          state.nutritionFactLabel.showProteinPercentage =
            action.payload.showProteinPercentage
        }
        state.updating = false
      }
    )
    builder.addCase(
      updateNutritionFactLabelShowProteinPercentage.rejected,
      (state) => {
        state.updating = false
        state.error = true
      }
    )

    builder.addCase(addNutritionFactLabelOptionalNutrients.pending, (state) => {
      state.updating = true
      state.error = false
    })
    builder.addCase(
      addNutritionFactLabelOptionalNutrients.fulfilled,
      (state, action) => {
        if (state.nutritionFactLabel) {
          state.nutritionFactLabel.optionalNutrientsType = [
            ...state.nutritionFactLabel.optionalNutrientsType,
            ...action.payload.map((n) => n.type)
          ]
        }
        state.updating = false
      }
    )
    builder.addCase(
      addNutritionFactLabelOptionalNutrients.rejected,
      (state) => {
        state.updating = false
        state.error = true
      }
    )

    builder.addCase(
      removeNutritionFactLabelOptionalNutrients.pending,
      (state) => {
        state.updating = true
        state.error = false
      }
    )
    builder.addCase(
      removeNutritionFactLabelOptionalNutrients.fulfilled,
      (state, action) => {
        if (state.nutritionFactLabel) {
          const removedNutrientTypes = new Set(action.payload)

          state.nutritionFactLabel.optionalNutrientsType =
            state.nutritionFactLabel.optionalNutrientsType.filter(
              (n) => !removedNutrientTypes.has(n)
            )
        }
        state.updating = false
      }
    )
    builder.addCase(
      removeNutritionFactLabelOptionalNutrients.rejected,
      (state) => {
        state.updating = false
        state.error = true
      }
    )

    builder.addCase(getAvailableOptionalNutrients.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(
      getAvailableOptionalNutrients.fulfilled,
      (state, action) => {
        state.availableOptionalNutrients = action.payload
        state.loading = false
      }
    )
    builder.addCase(getAvailableOptionalNutrients.rejected, (state) => {
      state.loading = false
      state.error = true
    })

    builder.addCase(getAvailableRegulations.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(getAvailableRegulations.fulfilled, (state, action) => {
      state.availableRegulations = action.payload
      state.loading = false
    })
    builder.addCase(getAvailableRegulations.rejected, (state) => {
      state.loading = false
      state.error = true
    })

    // getFormulaNutrient
    builder.addCase(getFormulaNutrients.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getFormulaNutrients.fulfilled, (state, action) => {
      state.formulaNutrients = action.payload
      state.loading = false
    })
    builder.addCase(getFormulaNutrients.rejected, (state) => {
      state.loading = false
      state.formulaNutrients = []
    })

    builder.addCase(
      updateNutritionFactLabelNutrientsOverride.pending,
      (state) => {
        state.updating = true
      }
    )
    builder.addCase(
      updateNutritionFactLabelNutrientsOverride.fulfilled,
      (state, action) => {
        state.updating = false
      }
    )
    builder.addCase(
      updateNutritionFactLabelNutrientsOverride.rejected,
      (state) => {
        state.updating = false
      }
    )
  }
})

export const {
  setNutritionFactLabelEdits,
  clearNutritionFactLabelEdits,
  resetState
} = nutritionFactLabelsSlice.actions

export default nutritionFactLabelsSlice.reducer

import { RegulationName } from "services/apis/regulation/RegulationApiResponse"

export const PATHS = {
  HOME: '/',
  FORMULAS: '/formulas',
  EDIT_FORMULA: '/formulas/:id',
  INGREDIENTS: '/ingredients',
  SUPPLIERS: '/suppliers',
  TARGETS: '/targets',
  SETTINGS: '/settings',
  PROFILE: '/settings/profile',
  COMPANY: '/settings/company',
  MEMBERS: '/settings/members',
  LOGIN: '/login',
  SIGNUP: '/signup',
  ACTIVATION: '/activation',
  NO_COMPANY: '/nocompany',
  LABEL_PROOFS: '/labelproofs',
  EDIT_PROOFS: '/labelproofs/:id',
}

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/


export const infoMessages = {
  ingredients: {
    INGREDIENT_STATEMENT_NAME: "The name entered here is what will appear in the formula's ingredient statement, which ENTR will automatically generate once a formula is created on the Formula page. This name should reflect the official ingredient name to be factual and truthful in the ingredient statement.",
    FRIENDLY_ID: "The Friendly ID field allows you to enter a custom ID # for this ingredient. You can search for an ingredient using this ID.",
    MANUFACTURER: "With certain ingredients, the supplier that will sell you the ingredient serves as a middleman and is not responsible for the ingredient's production, which is done by the manufacturer. In those cases, it is important to note both the supplier and manufacturer for complete traceability.",
    ADDED_SUGAR: `By checking this box, ENTR will use the Total Sugar value entered for this ingredient to populate the Added Sugar field on the Nutrition Fact Panel for both the US and Health Canada. For Health Canada, any items marked as added sugar will be grouped together in the ingredient statement.

***For the US***: According to [21 CFR 101.9(c)(6)(iii)](https://www.ecfr.gov/current/title-21/part-101#p-101.9(c)(6)(iii)), any ingredient should be considered an added sugar within a product formula if it is introducing a source of added sugar, as defined within the aforementioned section of the regulations. 
    
***For Canada***: Per [Health Canada regulations](https://inspection.canada.ca/en/food-labels/labelling/industry/list-ingredients-and-allergens#s6c2), grouping sugars in the ingredient list is mandated. Examples of sugars-based ingredients that require grouping can be found [here](https://inspection.canada.ca/en/food-labels/labelling/industry/list-ingredients-and-allergens#c7).`,
  },
  formula: {
    INGREDIENT_STATEMENT: "CFR 21.1.B.101.A 101.4 A(2)\n\nThe descending order of predominance requirements of paragraph (a)(1) of this section do not apply to ingredients present in amounts of 2 percent or less by weight when a listing of these ingredients is placed at the end of the ingredient statement following an appropriate quantifying statement, e.g., “Contains __ percent or less of ______” or “Less than __ percent of ______.” The blank percentage within the quantifying statement shall be filled in with a threshold level of 2 percent, or, if desired, 1.5 percent, 1.0 percent, or 0.5 percent, as appropriate. No ingredient to which the quantifying phrase applies may be present in an amount greater than the stated threshold.",
    ALLERGEN_STATEMENT: "You can override the allergen statement to make the statement more specific. For example: Contains: Tree Nuts (Almonds).",
    INGREDIENT_QUANTITY: "Click on the unit field to adjust the unit (if others are available). The percentages on the left\ndenote the % of the formula that a specific ingredient accounts for. As you adjust ingredient\nquantities, the percentages will adjust as well.",
    SERVING_SIZE: "Add your serving size in grams. To add a descriptive serving size such as 1 cookie, 1 tbsp, 2\npieces, ½ cup, etc, navigate to the 'Label' tab on the right side of the page, and click the 'Edit &\nDownload Label' button.",
    INGREDIENT_WASTE_PERCENTAGE: "Waste % can be set for individual ingredients in the event that the full ingredient isn't used\nduring production. Use cases include trimming meat, batter left behind, etc. Particularly useful\nfor more accurate costing.\n\n*Once we add waste % for the complete recipe*\n\nWaste % can be set for individual ingredients in the event that the full ingredient isn't used\nduring production. Use cases can include trimming meat, ingredient spoilage, etc. You can also\nset a waste % for the full product formula. Use cases can include batter left behind in bowls,\nmixture that sticks to the inside of your machinery, etc. Particularly useful for more accurate\ncosting.",
    MOISTURE_LOSS_PERCENTAGE: "Moisture loss % can be set for your product formula to indicate the amount of moisture lost\nduring processing.",
    yieldAdjustments: {
      //
      MOISTURE: "**Moisture —> Loss**\n\n" +

        "When you select 'loss', you can input either the % moisture loss or the amount of water/moisture in grams that was lost.\n\n" +

        "Example: My formula is 100 grams and my water/moisture content pre-processing is 8 grams. I know I’ve lost 4 of the 8 grams of water in my formula during processing. I input 4 grams into the field, and ENTR will calculate that 50% of the moisture was lost, and update nutrient amounts accordingly.\n\n" +

        "**Moisture —> Target**\n\n" +

        "When you select 'target', you can input either the % moisture loss or the post-processing weight in grams.\n\n" +

        "Example: My formula is 100 grams and my water/moisture content pre-processing is 8 grams. I know my post-processing weight is 96 grams. I input 96 grams, and ENTR will calculate that 50% of the moisture was lost, and update nutrient amounts accordingly."
    },
    label: {
      SERVING_AMOUNT: 'The descriptive serving size is the amount of food customarily consumed per sitting by a person\n4 years of age or older. It is usually descriptive, e.g. 1 cookie, 1 tbsp, 2 pieces, 1/2 cup, etc.\n\nTo view the full set of FDA guidance pertaining to serving size, [click here](https://www.fda.gov/media/133699/download?attachment).',
      ALLERGENS: '(insert disclaimer language)\n\n*Users are solely responsible for the accuracy of allergen statements. We do not assume liability\nfor the provided data; users must independently verify and ensure compliance*.',
      OPTIONAL_NUTRIENTS: 'Some nutrients are voluntary and optional, such as showing calories from saturated fat.\nShowing an optional nutrient is required if you intend to make a claim relating to a specific\nnutrient.\n\n(insert disclaimer language)\n\n*Users are solely responsible for confirming the accuracy of FDA-regulated claims. We do not\nassume liability for the provided data; users must independently verify and ensure compliance*',
      PDCAAS: 'The protein digestibility-corrected amino acid score (PDCAAS) has been adopted by FAO/WHO as the preferred method for the measurement of the protein value in human nutrition. By toggling ‘Use PDCAAS’, you will be able to input a value for PDCAAS between 0-1. This score will then be used to calculate the % daily value for protein. Learn more about PDCAAS [here](https://jn.nutrition.org/article/S0022-3166(22)14150-7/fulltext#:~:text=This%20scoring%20method%2C%20known%20as,and%20were%20truncated%20to%20100%25.).',
      DV_BASED_ON_ROUNDED: 'ENTR defaults to calculating the % Daily Value based on unrounded nutrient values. Switch the toggle to utilize rounded nutrient values to calculate the % Daily Value.  Regulations for rounding macronutrients and sodium are found in [21 CFR 101.9(c)](https://www.ecfr.gov/current/title-21/part-101#p-101.9(c)), and guidelines for rounding vitamins and minerals (other than sodium) can be found on pages 26-29 of  FDA’s 2018/2019 Guidance for Industry [here](https://www.fda.gov/media/117402/download).',
      SHOW_PROTEIN_PERCENTAGE: 'Per 21 CFR 101.9(c)(7)(i), it is optional to include the % DV for protein in all formats of the NFP EXCEPT when the product is designed for children and infants under the age of 4 OR if a protein claim (such as Good or Excellent Source) is made for the product.',
    },
    targets: {
      EXECUTED_TARGETS: "Our 'Targets' tools allow you to seamlessly apply predefined, FDA-regulated targets or craft custom targets tailored to your specifications.\n\nTo apply targets to this product formula, press 'Edit Targets'.",
      RACC: {
        [RegulationName.FDA]: `A RACC refers to the Reference Amounts Customarily Consumed per Eating Occasion. RACCs are set by the FDA and can be found [here](https://www.fda.gov/media/102587/download).\n\nFor a more user-friendly way to find the RACC that's relevant to your formula, you can check out 21 CFR 101.12(b) via the National Archive website [here](https://www.ecfr.gov/current/title-21/chapter-I/subchapter-B/part-101/subpart-A/section-101.12) or the FDA website [here](https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfcfr/CFRSearch.cfm?fr=101.12).`,
        [RegulationName.CANADA]: `A RACC refers to the Reference Amounts Customarily Consumed per Eating Occasion. RACCs are set by Health Canada and can be found [here](https://www.canada.ca/en/health-canada/services/technical-documents-labelling-requirements/table-reference-amounts-food.html).`
      },
      REFERENCE_FOOD:
      {
        [RegulationName.FDA]: "[21 CFR 101.13(j)1](https://www.ecfr.gov/current/title-21/chapter-I/subchapter-B/part-101/subpart-A/section-101.13)\\n\\n" +

          "To bear a relative claim about the level of a nutrient, the amount of that nutrient in the food must be compared to an amount of nutrient in an appropriate reference food.\\n\\n" +

          "21 CFR 101.13(j)1(i)A\\n\\n" +

          "For “less” (or “fewer”) and “more” claims, the reference food may be a dissimilar food within a product category that can generally be substituted for one another in the diet (e.g., potato chips as reference for pretzels, orange juice as a reference for vitamin C " +
          "tablets) or a similar food (e.g., potato chips as reference for potato chips, one brand of multivitamin as reference for another brand of multivitamin).\\n\\n" +

          "21 CFR 101.13(j)1(i)B\\n\\n" +

          "For “light,” “reduced,” “added,” “extra,” “plus,” “fortified,” and “enriched” claims, the reference food shall be a similar food (e.g., potato chips as a reference for potato chips, one brand of multivitamin for another brand of multivitamin), and\\n\\n" +

          "21 CFR 101.13(j)1(ii)A\\n\\n" +

          "For “light” claims, the reference food shall be representative of the type of food that includes the product that bears the claim. The nutrient value for the reference food shall be representative of a broad base of foods of that type; e.g., a value in a representative, " +
          "valid data base; an average value determined from the top three national (or regional) brands, a market basket norm; or, where its nutrient value is representative of the food type, a market leader. Firms using such a reference nutrient value as a basis for a claim, " +
          "are required to provide specific information upon which the nutrient value was derived, on request, to consumers and appropriate regulatory officials.\\n\\n" +

          "21 CFR 101.13(j)1(ii)B\\n\\n" +

          "For relative claims other than “light,” including “less” and “more” claims, the reference food may be the same as that provided for “light” in [paragraph (j)(1)(ii)(A)](https://www.ecfr.gov/current/title-21/section-101.13#p-101.13(j)(1)(ii)(A)) of this section, " +
          "or it may be the manufacturer's regular product, or that of another manufacturer, that has " +
          "been offered for sale to the public on a regular basis for a substantial period of time in " +
          "the same geographic area by the same business entity or by one entitled to use its trade " +
          "name. The nutrient values used to determine the claim when comparing a single " +
          "manufacturer's product to the labeled product shall be either the values declared in " +
          "nutrition labeling or the actual nutrient values, provided that the resulting label is " +
          "internally consistent to (i.e., that the values stated in the nutrition information, the nutrient " +
          "values in the accompanying information and the declaration of the percentage of nutrient " +
          "by which the food has been modified are consistent and will not cause consumer " +
          "confusion when compared), and that the actual modification is at least equal to the " +
          "percentage specified in the definition of the claim.  " +

          "[FDA Reference](https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfcfr/CFRSearch.cfm?fr=101.13#::text=(i)(A)%20For%20%22,food%20(e.g.%2C%20potato%20chips%20as))",
        [RegulationName.CANADA]: `To bear a relative claim about the level of a nutrient, the amount of that nutrient in the food must be compared to an amount of nutrient in an appropriate reference food.

"Reference food of the same food group" means a food which can be substituted in the diet for the food to which it is compared and that belongs to [B.01.500, FDR]:

&#8226; the same [food group](https://inspection.canada.ca/en/food-labels/labelling/industry/nutrition-labelling/definitions#c2) as the food to which it is compared, for example, cheese as a reference food for milk, or chicken as a reference food for tofu

&#8226; the category of ["other foods"](https://inspection.canada.ca/en/food-labels/labelling/industry/nutrition-labelling/definitions#c7), if the food to which it is compared also belongs to that category, for example, pretzels as a reference food for potato chips, or

&#8226; the category of ["combination foods"](https://inspection.canada.ca/en/food-labels/labelling/industry/nutrition-labelling/definitions#c1), if the food to which it is compared also belongs to that category, for example, pizza as a reference food for lasagna

These reference foods in the same food group do not have to be similar; they are used to make comparative claims, such as "lower in energy", "lower in fat", or "lower in saturated fatty acids". A comparative claim might state, for example, that "our pretzels contain 90% less fat than our regular potato chips."

"Similar reference food" means a food of the same type as the food to which it is compared and that has not been processed, formulated, reformulated or otherwise modified in a manner that increases or decreases either the energy value or the amount of a nutrient that is the subject of the comparison. For example, whole milk is a similar reference food for partly skimmed milk; regular cola is a similar reference food for calorie-reduced cola; regular chocolate chip cookies are a similar reference food for fat-reduced chocolate chip cookies [B.01.500, FDR].

Similar reference foods are useful for comparing a "regular" product with a product that has had its nutritional content intentionally increased or decreased, for example, "more energy", "more protein", "more fibre", "reduced in energy" and "reduced in sugars". For example, the fat content of skim milk, which has had most of the fat removed, can be compared to the fat content of whole milk.

[Health Canada Reference](https://inspection.canada.ca/en/food-labels/labelling/industry/nutrition-labelling/definitions)`
      },
      MEAL_OR_MAIN_DISH:
        "[21 CFR 101.13(l)](https://www.ecfr.gov/current/title-21/chapter-I/subchapter-B/part-101/subpart-A/section-101.13#p-101.13(l))\\n\\n" +

        "For purposes of making a claim, a “meal product shall be defined as a food that:\\n\\n" +

        "(1) Makes a major contribution to the total diet by:\\n\\n" +

        "(i) Weighing at least 10 ounces (oz) per labeled serving; and\\n\\n" +

        "(ii) Containing not less than three 40-g portions of food, or combinations of foods,\\n" +
        "from two or more of the following four food groups, except as noted in paragraph\\n" +
        "(l)(1)(ii)(E) of this section.\\n\\n" +

        "(A) Bread, cereal, rice, and pasta group;\\n\\n" +

        "(B) Fruits and vegetables group;\\n\\n" +

        "(C) Milk, yogurt, and cheese group;\\n\\n" +

        "(D) Meat, poultry, fish, dry beans, eggs, and nuts group; except that;\\n\\n" +

        "(E) These foods shall not be sauces (except for foods in the above four\\n" +
        "food groups that are in the sauces), gravies, condiments, relishes,\\n" +
        "pickles, olives, jams, jellies, syrups, breadings or garnishes; and\\n\\n" +

        "(2) Is represented as, or is in a form commonly understood to be, a breakfast, lunch,\\n" +
        "dinner, or meal. Such representations may be made either by statements, photographs,\\n" +
        "or vignettes.\\n\\n" +

        "[21 CFR 101.13(m)](https://www.ecfr.gov/current/title-21/chapter-I/subchapter-B/part-101/subpart-A/section-101.13#p-101.13(m))\\n\\n" +

        "For purposes of making a claim, a “main dish product” shall be defined as a food that:\\n\\n" +

        "(1) Makes a major contribution to a meal by\\n\\n" +

        "(i) Weighing at least 6 oz per labeled serving; and\\n\\n" +

        "(ii) Containing not less than 40 g of food, or combinations of foods, from each of\\n" +
        "at least two of the following four food groups, except as noted in\\n" +
        "[paragraph (m)(1)(ii)(E)](https://www.ecfr.gov/current/title-21/section-101.13#p-101.13(m)(1)(ii)(E)) of this section.\\n\\n" +

        "(A) Bread, cereal, rice, and pasta group;\\n\\n" +

        "(B) Fruits and vegetables group;\\n\\n" +

        "(C) Milk, yogurt, and cheese group;\\n\\n" +

        "(D) Meat, poultry, fish, dry beans, eggs, and nuts groups; except that:\\n\\n" +

        "(E) These foods shall not be sauces (except for foods in the above four\\n" +
        "food groups that are in the sauces) gravies, condiments, relishes, pickles,\\n" +
        "olives, jams, jellies, syrups, breadings, or garnishes; and\\n\\n" +

        "(2) Is represented as, or is in a form commonly understood to be, a main dish (e.g.,\\n" +
        "not a beverage or a dessert). Such representations may be made either by\\n" +
        "statements, photographs, or vignettes."
    }
  }
}

import { InputAdornment } from '@mui/material'
import { NumberUnitField } from 'components/InputFields/NumberUnitField'
import { TextField } from 'components/InputFields/TextField'
import { MenuButton } from 'components/MenuButton/MenuButton'
import { ModalContext } from 'components/Modal/ModalContext'
import { BoxRow } from 'components/common'
import React from 'react'

export interface MeasurementUnit {
  id: string
  unit: string
}

export interface UpdateCostArgs {
  cost?: number
  measurementUnit?: MeasurementUnit
  note?: string
}

export interface Cost {
  id: string
  note: string
  value: number
  measurementUnit: MeasurementUnit
  updatedAt: string
}

export interface IngredientCostRowProps {
  cost: Cost
  measurementUnitOptions: MeasurementUnit[]
  onUpdate: (id: string, args: UpdateCostArgs) => void
  onDelete: (id: string) => void
  disabled: boolean
}

export const IngredientCostRow: React.FC<IngredientCostRowProps> = ({
  cost,
  measurementUnitOptions,
  onUpdate,
  onDelete,
  disabled
}) => {
  const { showConfirmationModal } = React.useContext(ModalContext)

  const measurementRecord: Record<string, MeasurementUnit> =
    React.useMemo(() => {
      const record: Record<string, MeasurementUnit> = {}
      measurementUnitOptions.forEach((measurement) => {
        record[measurement.unit] = measurement
      })
      return record
    }, [measurementUnitOptions])

  const handleDelete = () => {
    showConfirmationModal({
      title: 'Delete Cost',
      message: 'Are you sure you want to delete this cost?',
      yesText: 'Delete',
      noText: 'Cancel',
      danger: true,
      onYesClicked: () => onDelete(cost.id)
    })
  }

  const unitOptions = React.useMemo(() => {
    return measurementUnitOptions.map((measurement) => {
      return {
        id: measurement.id,
        name: measurement.unit,
        abreviation: measurement.unit
      }
    })
  }, [measurementUnitOptions])

  return (
    <BoxRow style={{ width: '100%', gap: '8px' }}>
      <NumberUnitField
        value={cost.value}
        unit={{
          id: cost.measurementUnit.id,
          name: cost.measurementUnit.unit,
          abreviation: cost.measurementUnit.unit
        }}
        unitOptions={unitOptions}
        onChange={(value) => onUpdate(cost.id, { cost: value })}
        onUnitChange={(unit) =>
          onUpdate(cost.id, { measurementUnit: measurementRecord[unit.name] })
        }
        disabled={disabled}
        renderValue={(value) => `per ${value}`}
        startAdornment={<InputAdornment position="start">$</InputAdornment>}
        debounceTime={300}
        maxDecimals={2}
        boxSx={{ height: '100%' }}
        sx={{ height: '100%' }}
      />
      <TextField
        placeholder="Cost note"
        value={cost.note}
        onChange={(v) => {
          onUpdate(cost.id, { note: v })
        }}
        disabled={disabled}
        boxSx={{ width: '70%', height: '100%' }}
        sx={{ height: '100%' }}
        debounceTime={300}
      />
      <MenuButton
        buttonStyle={{
          height: '100%'
        }}
        menuItems={[
          {
            itemTitle: 'Delete',
            itemCallback: () => handleDelete(),
            itemStyle: {
              color: 'var(--error, #D03838)'
            }
          }
        ]}
        showButton={!disabled}
      />
    </BoxRow>
  )
}

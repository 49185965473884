import { SxProps, Typography, TypographyProps } from '@mui/material'
import React from 'react'
import { TextParserFunction } from './TextParsers'

type commentTextVariants = 'normal' | 'replied' | 'deleted' | 'deleted-replied' | 'staged-replied'

export interface CommentTextProps {
  text: string
  parsers?: TextParserFunction[]
  variant?: commentTextVariants
  sx?: SxProps
}

const getSxFromVariant = (variant: commentTextVariants): SxProps => {
  switch (variant) {
    case 'replied':
      return {
        color: '#1A2C42BF'
      }
    case 'deleted':
      return {
        fontStyle: 'italic',
        color: 'gray',
        fontSize: '13px'
      }
    case 'deleted-replied':
      return {
        color: '#1A2C42BF',
        fontStyle: 'italic',
        fontSize: '13px'
      }
    case 'staged-replied':
      return {
        fontStyle: 'italic',
        color: 'gray',
        fontSize: '13px',
        marginLeft: '10px'
      }
    default:
      return {}
  }
}

export const CommentText: React.FC<CommentTextProps> = ({
  text,
  parsers,
  variant = 'normal',
  sx = {}
}) => {
  const parts = text.split(' ')
  const parsedParts = parts.map((part, index) => {
    if (parsers) {
      for (const parser of parsers) {
        const parsedPart = parser(part)
        if (parsedPart) {
          return (
            <React.Fragment key={`${index}-${part}`}>
              {parsedPart}{' '}
            </React.Fragment>
          )
        }
      }
    }
    return <React.Fragment key={`${index}-${part}`}>{part} </React.Fragment>
  })

  return (
    <Typography sx={{ ...getSxFromVariant(variant) }}>
      {parsedParts}
    </Typography>
  )
}

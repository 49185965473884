export enum RequirementFilter {
  MANDATORY = 'mandatory',
  OPTIONAL = 'optional',
  OTHER = 'other'
}

export interface GetNutrientsApiParams {
  requirementsFilter?: RequirementFilter[]
  regulationId?: string
  supplementNutrient?: boolean
}

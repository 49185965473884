import React from 'react'
import { resetFormulaRegulationsSettingsEdits } from 'state/formulator/FormulatorSlice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  clearNutritionFactLabelEdits,
  setNutritionFactLabelEdits
} from 'state/labels/nutritions/NutritionFactLabelsSlice'
import { AllergensContainer } from './components/Allergens/AllergensContainer'
import { GeneralContainer } from './components/General/GeneralContainer'
import { OverridesContainer } from './components/Overrides/OverridesContainer'
import { EditorPanel } from './EditorPanel'
import { setFormulaIngredientsStatementFormatPreview } from 'state/labels/statements/IngredientStatementsSlice'
import { mapFormulaIngredientsToStatementFormatsPreview } from 'services/apis/formula/FormulaApiMapper'

export interface EditorPanelContainerProps {
  visible: boolean
}

export const EditorPanelContainer: React.FC<EditorPanelContainerProps> = ({
  visible
}) => {
  const dispatch = useAppDispatch()
  const nutritionFactLabel = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabel
  )
  const nutritionFactLabelEdits = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabelEdits
  )
  const formulaAllergens = useAppSelector(
    (state) => state.formulatorAllergens.formulaAllergens
  )
  const formulaIsSupplement = useAppSelector(
    (state) => state.formulator.formula.isSupplement
  )
  const formulaIsSupplementEdits = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabelEdits.isSupplement
  )
  const formulaIngredients = useAppSelector(
    (state) => state.formulatorIngredients.formulaIngredients
  )

  React.useEffect(() => {
    // This should only happen when the panel is visible to reset the edits.
    if (nutritionFactLabel && visible) {
      dispatch(
        setNutritionFactLabelEdits({
          ...nutritionFactLabelEdits,
          nutritionFactLabelPreview: nutritionFactLabel,
          allergens: formulaAllergens.allergens.map((a) => a.type),
          isSupplement: formulaIsSupplement
        })
      )
    }
  }, [visible])

  React.useEffect(() => {
    if (!visible) {
      dispatch(clearNutritionFactLabelEdits())
      dispatch(resetFormulaRegulationsSettingsEdits())
      dispatch(
        setFormulaIngredientsStatementFormatPreview(
          mapFormulaIngredientsToStatementFormatsPreview(formulaIsSupplement || formulaIsSupplementEdits, formulaIngredients)
        )
      )
    }
  }, [visible, formulaIngredients])

  return (
    <EditorPanel
      general={<GeneralContainer />}
      allergens={<AllergensContainer />}
      overrides={<OverridesContainer />}
    />
  )
}

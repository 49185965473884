import { AxiosProgressEvent } from 'axios'
import { SimpleIngredientAllergen } from 'models/Allergen'
import { IngredientNutrient } from 'models/Ingredient'
import { NutrientType } from 'models/Nutrient'
import {
  DetailedSimpleIngredient,
  DetailedSimpleIngredientsPaginated,
  SimpleIngredientCost
} from 'models/SimpleIngredient'
import { SupplierSimpleIngredient } from 'models/Supplier'
import { SimpleIngredientTag } from 'models/Tags'
import { api } from '../config/AxiosConfig'
import {
  toIngredientNutrient,
  toIngredientNutrients
} from '../ingredient/IngredientApiMapper'
import { IngredientNutrientApiResponse } from '../ingredient/IngredientApiResponse'
import {
  toDetailedSimpleIngredient,
  toDetailedSimpleIngredientFromSimpleSimpleIngredient,
  toDetailedSimpleIngredientPaginated,
  toSimpleIngredientAllergen,
  toSimpleIngredientCost,
  toSimpleIngredientTag,
  toSupplierSimpleIngredient,
  toSupplierSimpleIngredients
} from './SimpleIngredientApiMapper'
import {
  CreateSimpleIngredientApiData,
  CreateSimpleIngredientCostApiData,
  DuplicateSimpleIngredientApiParams,
  GetSimpleIngredientApiParams,
  GetSimpleIngredientSuppliersFilterApiParams,
  GetSimpleIngredientTagsFilterApiParams,
  UpdateSimpleIngredientApiData,
  UpdateSimpleIngredientCostApiData
} from './SimpleIngredientApiRequest'
import {
  DetailedSimpleIngredientApiPaginatedResponse,
  DetailedSimpleIngredientApiResponse,
  SimpleIngredientAllergenApiResponse,
  SimpleIngredientCostApiResponse,
  SimpleIngredientSuppliersFilterApiResponse,
  SimpleIngredientTagApiResponse,
  SimpleIngredientTagsFilterApiResponse,
  SimpleSimpleIngredientApiResponse,
  SupplierSimpleIngredientApiPaginatedResponse,
  SupplierSimpleIngredientApiResponse
} from './SimpleIngredientApiResponse'
import { FileApi } from '../document/DocumentApi'
import { BasicDocument } from 'models/Document'
import { DocumentApiResponse } from '../document/DocumentApiResponse'
import { toBasicDocuments } from '../document/DocumentApiMapper'

export const SimpleIngredientApi = {
  getSimpleIngredients: (
    companyId: string,
    params: GetSimpleIngredientApiParams
  ): Promise<DetailedSimpleIngredientsPaginated> => {
    return api
      .get<DetailedSimpleIngredientApiPaginatedResponse>(
        `/companies/${companyId}/simple_ingredients`,
        {
          params,
          paramsSerializer: {
            indexes: null
          }
        }
      )
      .then((res) => {
        return toDetailedSimpleIngredientPaginated(res.data)
      })
  },

  getSimpleIngredientSuppliers: (
    companyId: string,
    simpleIngredientId: string
  ): Promise<SupplierSimpleIngredient[]> => {
    return api
      .get<SupplierSimpleIngredientApiPaginatedResponse>(
        `/companies/${companyId}/simple_ingredients/${simpleIngredientId}/suppliers`
      )
      .then((res) => {
        return toSupplierSimpleIngredients(res.data)
      })
  },

  createSimpleIngredient: (
    companyId: string,
    data: CreateSimpleIngredientApiData
  ): Promise<DetailedSimpleIngredient> => {
    return api
      .post<SimpleSimpleIngredientApiResponse>(
        `/companies/${companyId}/simple_ingredients/`,
        data
      )
      .then((res) => {
        return toDetailedSimpleIngredientFromSimpleSimpleIngredient(res.data)
      })
  },

  duplicateSimpleIngredient: (
    companyId: string,
    simpleIngredientId: string,
    params: DuplicateSimpleIngredientApiParams
  ): Promise<DetailedSimpleIngredient> => {
    return api
      .post<DetailedSimpleIngredientApiResponse>(
        `/companies/${companyId}/simple_ingredients/${simpleIngredientId}/duplicate`,
        {
          params
        }
      )
      .then((res) => {
        return toDetailedSimpleIngredient(res.data)
      })
  },

  getSimpleIngredient: (
    companyId: string,
    simpleIngredientId: string
  ): Promise<DetailedSimpleIngredient> => {
    return api
      .get<DetailedSimpleIngredientApiResponse>(
        `/companies/${companyId}/simple_ingredients/${simpleIngredientId}`
      )
      .then((res) => {
        return toDetailedSimpleIngredient(res.data)
      })
  },

  updateSimpleIngredient: (
    companyId: string,
    simpleIngredientId: string,
    data: UpdateSimpleIngredientApiData
  ): Promise<DetailedSimpleIngredient> => {
    return api
      .put<SimpleSimpleIngredientApiResponse>(
        `/companies/${companyId}/simple_ingredients/${simpleIngredientId}`,
        data
      )
      .then((res) => {
        return toDetailedSimpleIngredientFromSimpleSimpleIngredient(res.data)
      })
  },

  deleteSimpleIngredient: (
    companyId: string,
    ingredientId: string
  ): Promise<string> => {
    return api
      .delete(`/companies/${companyId}/simple_ingredients/${ingredientId}`)
      .then(() => ingredientId)
  },

  deleteSimpleIngredientDensityOverride: (
    companyId: string,
    ingredientId: string
  ): Promise<string> => {
    return api
      .delete(
        `/companies/${companyId}/simple_ingredients/${ingredientId}/density_override`
      )
      .then(() => ingredientId)
  },

  addSimpleIngredientToSupplier: (
    companyId: string,
    supplierId: string,
    simpleIngredientId: string
  ): Promise<SupplierSimpleIngredient> => {
    return api
      .post<SupplierSimpleIngredientApiResponse>(
        `/companies/${companyId}/suppliers/${supplierId}/simple_ingredients/${simpleIngredientId}`
      )
      .then((res) => {
        return toSupplierSimpleIngredient(res.data)
      })
  },

  deleteSimpleIngredientFromSupplier: (
    companyId: string,
    supplierId: string,
    simpleIngredientId: string
  ): Promise<void> => {
    return api.delete(
      `/companies/${companyId}/suppliers/${supplierId}/simple_ingredients/${simpleIngredientId}`
    )
  },

  addSimpleIngredientTag: (
    companyId: string,
    simpleIngredientId: string,
    name: string
  ): Promise<SimpleIngredientTag> => {
    return api
      .post<SimpleIngredientTagApiResponse>(
        `/companies/${companyId}/simple_ingredients/${simpleIngredientId}/tags`,
        { name }
      )
      .then((res) => {
        return toSimpleIngredientTag(res.data)
      })
  },

  deleteSimpleIngredientTag: (
    companyId: string,
    simpleIngredientId: string,
    tagId: string
  ): Promise<void> => {
    return api.delete(
      `/companies/${companyId}/simple_ingredients/${simpleIngredientId}/tags/${tagId}`
    )
  },

  getSimpleIngredientAllergens: (
    companyId: string,
    simpleIngredientId: string
  ): Promise<SimpleIngredientAllergen[]> => {
    return api
      .get<SimpleIngredientAllergenApiResponse[]>(
        `/companies/${companyId}/simple_ingredients/${simpleIngredientId}/allergens`
      )
      .then((res) => {
        return res.data.map((d) => toSimpleIngredientAllergen(d))
      })
  },

  addSimpleIngredientAllergen: (
    companyId: string,
    simpleIngredientId: string,
    allergenType: string
  ): Promise<SimpleIngredientAllergen> => {
    return api
      .post<SimpleIngredientAllergenApiResponse>(
        `/companies/${companyId}/simple_ingredients/${simpleIngredientId}/allergens`,
        { allergenType }
      )
      .then((res) => {
        return toSimpleIngredientAllergen(res.data)
      })
  },

  deleteSimpleIngredientAllergen: (
    companyId: string,
    simpleIngredientId: string,
    allergenType: string
  ): Promise<void> => {
    return api.delete(
      `/companies/${companyId}/simple_ingredients/${simpleIngredientId}/allergens/${allergenType}`
    )
  },

  getSimpleIngredientNutrients: (
    companyId: string,
    simpleIngredientId: string
  ): Promise<IngredientNutrient[][]> => {
    return api
      .get<IngredientNutrientApiResponse[][]>(
        `/companies/${companyId}/simple_ingredients/${simpleIngredientId}/nutrients/display`
      )
      .then((res) => {
        return toIngredientNutrients(res.data)
      })
  },

  updateSimpleIngredientNutrient: (
    companyId: string,
    simpleIngredientId: string,
    nutrientType: string,
    amount: number
  ): Promise<IngredientNutrient[]> => {
    return api
      .put<IngredientNutrientApiResponse[]>(
        `/companies/${companyId}/simple_ingredients/${simpleIngredientId}/nutrients/${nutrientType}`,
        { amount }
      )
      .then((res) => {
        return res.data.map((d) => toIngredientNutrient(d))
      })
  },

  uploadSimpleIngredientDocument: async (
    companyId: string,
    simpleIngredientId: string,
    file: File,
    uploadProgressListener?: (
      progressEvent: AxiosProgressEvent
    ) => ProgressEvent | void
  ): Promise<string> => {
    return api
      .post<string>(
        `/companies/${companyId}/simple_ingredients/${simpleIngredientId}/documents/`,
        { fileName: file.name, contentType: file.type }
      )
      .then((res) => {
        return FileApi.uploadFile(file, res.data, uploadProgressListener)
      })
  },

  getSimpleIngredientDocument: async (
    companyId: string,
    simpleIngredientId: string,
    fileName: string,
    download = true
  ): Promise<string> => {
    const encodedFileName = encodeURIComponent(fileName)

    return api
      .get<string>(
        `/companies/${companyId}/simple_ingredients/${simpleIngredientId}/documents/${encodedFileName}`
      )
      .then((res) => {
        return FileApi.downloadFile(res.data, fileName, download)
      })
  },

  deleteSimpleIngredientDocument: (
    companyId: string,
    simpleIngredientId: string,
    fileName: string
  ): Promise<string> => {
    return api
      .delete(
        `/companies/${companyId}/simple_ingredients/${simpleIngredientId}/documents`,
        { params: { fileName } }
      )
      .then(() => fileName)
  },

  getSimpleIngredientDocuments: (
    companyId: string,
    simpleIngredientId: string
  ): Promise<BasicDocument[]> => {
    return api
      .get<DocumentApiResponse[]>(
        `/companies/${companyId}/simple_ingredients/${simpleIngredientId}/documents`
      )
      .then((res) => {
        return toBasicDocuments(res.data)
      })
  },

  createSimpleIngredientCost: (
    companyId: string,
    simpleIngredientId: string,
    data: CreateSimpleIngredientCostApiData
  ): Promise<SimpleIngredientCost> => {
    return api
      .post<SimpleIngredientCostApiResponse>(
        `/companies/${companyId}/simple_ingredients/${simpleIngredientId}/costs`,
        data
      )
      .then((res) => {
        return toSimpleIngredientCost(res.data)
      })
  },
  updateSimpleIngredientCost: (
    companyId: string,
    simpleIngredientId: string,
    simpleIngredientCostId: string,
    data: UpdateSimpleIngredientCostApiData
  ): Promise<SimpleIngredientCost> => {
    return api
      .put<SimpleIngredientCostApiResponse>(
        `/companies/${companyId}/simple_ingredients/${simpleIngredientId}/costs/${simpleIngredientCostId}`,
        data
      )
      .then((res) => {
        return toSimpleIngredientCost(res.data)
      })
  },
  deleteSimpleIngredientCost: (
    companyId: string,
    simpleIngredientId: string,
    simpleIngredientCostId: string
  ): Promise<string> => {
    return api
      .delete(
        `/companies/${companyId}/simple_ingredients/${simpleIngredientId}/costs/${simpleIngredientCostId}`
      )
      .then(() => simpleIngredientCostId)
  },
  getSimpleIngredientSuppliersFilter: (
    companyId: string,
    params: GetSimpleIngredientSuppliersFilterApiParams
  ): Promise<SimpleIngredientSuppliersFilterApiResponse> => {
    return api
      .get<SimpleIngredientSuppliersFilterApiResponse>(
        `/companies/${companyId}/filters/simple_ingredients/suppliers`,
        {
          params: params,
          paramsSerializer: {
            indexes: null
          }
        }
      )
      .then((res) => {
        return res.data
      })
  },
  getSimpleIngredientTagsFilter: (
    companyId: string,
    params: GetSimpleIngredientTagsFilterApiParams
  ): Promise<SimpleIngredientTagsFilterApiResponse> => {
    return api
      .get<SimpleIngredientTagsFilterApiResponse>(
        `/companies/${companyId}/filters/simple_ingredients/tags`,
        {
          params: params,
          paramsSerializer: {
            indexes: null
          }
        }
      )
      .then((res) => {
        return res.data
      })
  }
}

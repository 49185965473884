import { Card, CardActionArea } from '@mui/material'
import React from 'react'

export interface EntrCardProps {
  // The id of the card.
  id: string
  // The content of the card as children.
  children: React.ReactNode
  // The function to call when the card is clicked.
  onClick: (id: string) => void
}

/**
 * A card component for the EntrGrid component.
 */
export const EntrCard: React.FC<EntrCardProps> = ({
  id,
  children,
  onClick
}) => {
  return (
    <Card sx={{ width: '300px', height: '250px' }}>
      <CardActionArea onClick={() => onClick(id)}>{children}</CardActionArea>
    </Card>
  )
}

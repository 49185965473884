import React from 'react'
import { ActivityComment } from './ActivityComment'
import { BoxColumnBasic, BoxRowBasic } from 'components/common'
import { Skeleton } from '@mui/material'

export const ActivityItemSkeleton = () => {
  return (
    <BoxRowBasic gap="1rem">
      <BoxColumnBasic>
        <Skeleton variant="circular" height={40} width={40} />
      </BoxColumnBasic>
      <BoxColumnBasic gap={1} sx={{ flexGrow: 1 }}>
        <BoxRowBasic gap={1}>
          <Skeleton variant="rectangular" height={10} sx={{ flexGrow: 0.25 }} />
        </BoxRowBasic>
        <Skeleton variant="rectangular" height={30} sx={{ flexGrow: 1 }} />
      </BoxColumnBasic>
    </BoxRowBasic>
  )
}

export interface ActivityProps {
  id: string
  text: string
  createdBy?: {
    name: string
    isOwner: boolean
    avatarSrc?: string
  }
  repliedComment?: {
    id: string
    text: string
    createdByName?: string
    deleted: boolean
  }
  timestamp: string
  edited: boolean
  deleted: boolean
  mentionedUsersMap: Record<string, string>
  pinMap: Record<string, string>
  onDeleteClick: (id: string) => void
  onEditClick: (id: string) => void
  onReplyClick: (id: string) => void
  onPinClick: (id: string) => void
}

export const Activity: React.FC<ActivityProps> = ({
  id,
  text,
  createdBy,
  repliedComment,
  timestamp,
  edited,
  deleted,
  mentionedUsersMap,
  pinMap,
  onDeleteClick,
  onEditClick,
  onReplyClick,
  onPinClick
}) => {
  return (
    <ActivityComment
      id={id}
      text={text}
      createdBy={createdBy}
      timestamp={timestamp}
      edited={edited}
      deleted={deleted}
      repliedComment={repliedComment}
      onDeleteClick={onDeleteClick}
      onEditClick={onEditClick}
      onReplyClick={onReplyClick}
      mentionedUsersMap={mentionedUsersMap}
      pinMap={pinMap}
      onPinClick={onPinClick}
    />
  )
}

import CloudCircleOutlinedIcon from '@mui/icons-material/CloudCircleOutlined'
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined'
import { Box, Typography, useTheme } from '@mui/material'
import { ReactComponent as CircleCheckIcon } from 'assets/CheckCircle.svg'
import React from 'react'
import { BoxRow } from '../common'

export enum SavingStatus {
  SAVED,
  UNSAVED,
  SAVING
}

interface SavingStatusIndicatorProps {
  status: SavingStatus
}

const SyncIcon: React.FC = () => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '16px',
        height: '16px',
        animation: 'spin 2s linear infinite',
        '@keyframes spin': {
          '0%': {
            transform: 'rotate(360deg)'
          },
          '100%': {
            transform: 'rotate(0deg)'
          }
        }
      }}
    >
      <SyncOutlinedIcon sx={{ height: '12px', color: 'white' }} />
      <CircleCheckIcon stroke={theme.palette.tertiary.main} />
    </Box>
  )
}

export const SavingStatusIndicator: React.FC<SavingStatusIndicatorProps> = ({
  status
}) => {
  const theme = useTheme()
  const [label, setLabel] = React.useState<string>('All changes saved')
  const [icon, setIcon] = React.useState<React.ReactElement>(
    <CircleCheckIcon stroke={theme.palette.tertiary.main} />
  )

  React.useEffect(() => {
    switch (status) {
      case SavingStatus.SAVED:
        setLabel('All changes saved')
        setIcon(<CircleCheckIcon stroke={theme.palette.tertiary.main} />)
        break
      case SavingStatus.SAVING:
        setLabel('Saving changes')
        setIcon(<SyncIcon />)
        break
      case SavingStatus.UNSAVED:
        setLabel('Unsaved changes')
        setIcon(
          <CloudCircleOutlinedIcon
            sx={{ color: theme.palette.tertiary.main }}
          />
        )
        break
      default:
        break
    }
  }, [status])

  return (
    <BoxRow style={{ marginRight: '20px' }}>
      {icon}
      <Typography variant="subtitle2" style={{ marginLeft: '5px' }}>
        {label}
      </Typography>
    </BoxRow>
  )
}

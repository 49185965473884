import { BoxColumnBasic, BoxRowBasic } from 'components/common'
import React from 'react'
import { ActivityItemSkeleton } from './components/Activity/Activity'
import { TabContent } from 'pages/Formulator/components/FormulatorPanel/TabContent'
import {
  ActivityContainer,
  ActivityContainerProps
} from './components/Activity/ActivitiyContainer'
import {
  ProofCommentForm,
  ProofCommentFormProps
} from './components/ProofCommentForm/ProofCommentForm'
import { InView } from 'react-intersection-observer'
import { CircularProgress } from '@mui/material'

export const ActivitiesSkeleton: React.FC = () => {
  const numberOfItems = 5
  const skeletons = Array.from({ length: numberOfItems }, (_, index) => (
    <ActivityItemSkeleton key={index} />
  ))

  return <>{skeletons}</>
}

export interface ActivitiesProps {
  allPagesLoaded: boolean
  nextPageCallback: () => void
  proofCommentFormProps: ProofCommentFormProps
  activities?: ActivityContainerProps[]
  loading?: boolean
  page?: number
}

export const Activities: React.FC<ActivitiesProps> = ({
  allPagesLoaded,
  nextPageCallback,
  proofCommentFormProps,
  activities = [],
  loading = false,
  page = 1
}) => {
  return (
    <TabContent
      footerElement={<ProofCommentForm {...proofCommentFormProps} />}
      scrollToBottom={true}
    >
      {!allPagesLoaded && (
        <InView
          as="div"
          onChange={(inView: boolean) => {
            if (inView && activities.length > 0 && !loading) {
              nextPageCallback()
            }
          }}
          trackVisibility={true}
          delay={300}
        >
          <BoxRowBasic sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </BoxRowBasic>
        </InView>
      )}
      <BoxColumnBasic gap="1rem" height="100%">
        {loading ? (
          <ActivitiesSkeleton />
        ) : (
          activities.map((activity, index) => (
            <ActivityContainer key={`activity-item-${index}`} {...activity} />
          ))
        )}
      </BoxColumnBasic>
    </TabContent>
  )
}

import { ModalContext } from 'components/Modal/ModalContext'
import { SideViewer } from 'components/SideViewer/SideViewer'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'
import { TopBar } from 'pages/Ingredients/components/IngredientViewer/components/TopBar/TopBar'
import React, { useContext } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  clearSelectedSimpleIngredient,
  deleteSimpleIngredient,
  duplicateSimpleIngredient,
  getSimpleIngredient,
  SelectedIngredient,
  setSelectedSimpleIngredient
} from 'state/simple_ingredients/SimpleIngredientsSlice'
import { PageViewerContext } from '../PageViewerContext'
import { IngredientFormContainer } from './components/Form/IngredientFormContainer'
import { IngredientViewerPanel } from './components/Panel/IngredientViewerPanel'
import { FilePreviewContext } from 'components/FilePreview/FilePreviewContext'

export const IngredientsViewerContainer: React.FC = () => {
  const dispatch = useAppDispatch()

  const { setFilePreviewLeftTargetRef } = React.useContext(FilePreviewContext)
  const ref = React.useRef<HTMLDivElement>(null)

  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const selectedIngredient = useAppSelector(
    (state) => state.simpleIngredients.selectedSimpleIngredient
  )
  const deleting = useAppSelector((state) => state.simpleIngredients.deleting)

  const measurementsSaving = useAppSelector(
    (state) =>
      state.simpleIngredientMeasurementSlice.updating ||
      state.simpleIngredientMeasurementSlice.deleting ||
      state.simpleIngredientMeasurementSlice.adding
  )
  const ingredientSaving = useAppSelector(
    (state) =>
      state.simpleIngredients.creating ||
      state.simpleIngredients.updating ||
      state.simpleIngredients.removing ||
      state.simpleIngredients.adding
  )
  const saving = ingredientSaving || measurementsSaving

  const { request, viewedItemId, clearRequest, setViewedItemId } =
    React.useContext(PageViewerContext)
  const { showConfirmationModal } = React.useContext(ModalContext)

  const [viewerVisible, setViewerVisible] = React.useState(false)
  const { ingredientsAnalytics } = useContext(AnalyticsContext)
  const { showSuccess, showError } = useContext(SnackbarContext)

  React.useEffect(() => {
    if (ref && ref.current) {
      setFilePreviewLeftTargetRef(ref)
    }
  }, [ref.current])

  // When viewed item id changes, get the ingredient information and set is as selected.
  React.useEffect(() => {
    if (viewedItemId) {
      void dispatch(
        getSimpleIngredient({
          companyId: currentCompany.id,
          simpleIngredientId: viewedItemId
        })
      )
        .unwrap()
        .then((res) => {
          const ingredient = res as SelectedIngredient
          if (ingredient.id === selectedIngredient?.id) {
            ingredient.new = selectedIngredient.new
          }
          dispatch(setSelectedSimpleIngredient(ingredient))
        })
    }
  }, [viewedItemId])

  // Show the viewer.
  const showViewer = () => {
    setViewerVisible(true)
  }

  const showAllergenNotProvidedModal = (onYesClicked: () => void) => {
    showConfirmationModal({
      title: 'Allergen Tags',
      message: (
        <>
          Are you sure that all relevant allergens are tagged for this
          ingredient?
        </>
      ),
      yesText: 'Yes',
      noText: 'No',
      onYesClicked: () => onYesClicked()
    })
  }

  // Hides the viewer and runs all the necessary conditions.
  const hideViewer = () => {
    if (selectedIngredient?.new && selectedIngredient?.id && !deleting) {
      showAllergenNotProvidedModal(() => {
        setViewerVisible(false)
      })
    } else {
      setViewerVisible(false)
    }
  }

  const handleShowRequest = (id?: string) => {
    // Hide the viewer if it's the same selected ingredient
    if (selectedIngredient && selectedIngredient.id === id) {
      hideViewer()
    } else if (selectedIngredient?.new && selectedIngredient?.id) {
      // If we're dismissing the current ingredient which is a new ingredient, show the modal before accepting the request.
      showAllergenNotProvidedModal(() => {
        setViewedItemId(id)
      })
    } else {
      // Switch to the newly selected ingredient.
      dispatch(clearSelectedSimpleIngredient()).finally(() => {
        setViewedItemId(id)
      })
    }
    clearRequest()
  }

  const handleHideRequest = React.useCallback(() => {
    hideViewer()
  }, [hideViewer])

  React.useEffect(() => {
    if (request) {
      if (request.type === 'show') {
        handleShowRequest(request.id)
      } else {
        handleHideRequest()
      }
    }
  }, [request])

  React.useEffect(() => {
    return () => {
      dispatch(setSelectedSimpleIngredient(undefined))
    }
  }, [])

  /* open viewer when ingredient is selected */
  React.useEffect(() => {
    if (selectedIngredient) {
      showViewer()
    }
  }, [selectedIngredient])

  /* set selected ingredient to undefined when viewer closes */
  React.useEffect(() => {
    if (!viewerVisible && selectedIngredient) {
      dispatch(setSelectedSimpleIngredient(undefined))
      setViewedItemId(undefined)
    }
  }, [viewerVisible])

  /* auto close viewer on delete */
  React.useEffect(() => {
    if (deleting) {
      hideViewer()
    }
  }, [deleting])

  const handleDelete = () => {
    if (selectedIngredient) {
      showConfirmationModal({
        title: 'Delete Ingredient',
        message: (
          <>
            Are you sure you want to delete <b>{selectedIngredient.name}</b>?
          </>
        ),
        danger: true,
        yesText: 'Delete',
        noText: 'Cancel',
        onYesClicked: () => {
          ingredientsAnalytics.deletedIngredient(selectedIngredient.id)
          void dispatch(
            deleteSimpleIngredient({
              companyId: selectedIngredient.company.id,
              simpleIngredientId: selectedIngredient.id
            })
          )
            .unwrap()
            .then(() => {
              showSuccess(`${selectedIngredient.name} has been deleted.`)
            })
            .catch(() =>
              showError(`Failed to delete ${selectedIngredient.name}.`)
            )
        }
      })
    }
  }

  const handleDuplicate = () => {
    if (selectedIngredient) {
      ingredientsAnalytics.duplicatedIngredient(selectedIngredient.id)
      void dispatch(
        duplicateSimpleIngredient({
          companyId: currentCompany.id,
          simpleIngredientId: selectedIngredient.id,
          simpleIngredientCompanyId: selectedIngredient.company.id
        })
      )
        .unwrap()
        .catch(() => {
          showError(
            `Failed to duplicate the ingredient ${selectedIngredient.name}`
          )
        })
    }
  }

  return (
    <SideViewer
      ref={ref}
      topBar={
        <TopBar
          saving={saving}
          editable={!selectedIngredient?.company.public}
          onDuplicateClick={handleDuplicate}
          onDeleteClick={handleDelete}
        />
      }
      show={viewerVisible}
      form={<IngredientFormContainer />}
      panel={
        selectedIngredient ? (
          <IngredientViewerPanel
            simpleIngredient={selectedIngredient}
            readOnly={selectedIngredient.company.public}
          />
        ) : null
      }
    />
  )
}

import React, { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { InputField, InputFieldProps } from './InputField'

interface TextFieldFieldProps extends InputFieldProps {
  debounceTime?: number
  rows?: number
  multiline?: boolean
}

export const TextField: React.FC<TextFieldFieldProps> = ({
  debounceTime = 0,
  value = '',
  onChange,
  rows,
  multiline,
  ...rest
}) => {
  const [internalValue, setInternalValue] = useState<string>(value)
  const [pendingSubmission, setPendingSubmission] =
    React.useState<boolean>(false)

  useEffect(() => {
    if (!pendingSubmission) {
      setInternalValue(value)
    }
  }, [value])

  const handleInputChangeDebounced = useDebouncedCallback(
    (inputValue: string) => {
      if (onChange) {
        onChange(inputValue)
        setPendingSubmission(false)
      }
    },
    debounceTime
  )

  const handleInputChange = (inputValue: string) => {
    // If it's not multiline, prevent new lines. This is used to have multiple rows but single line.
    if (!multiline) {
      inputValue = inputValue.replace(/(\r\n|\n|\r)/gm, '')
    }
    setInternalValue(inputValue)
    setPendingSubmission(true)
    handleInputChangeDebounced(inputValue)
  }

  return (
    <InputField
      value={internalValue}
      onChange={handleInputChange}
      rows={rows}
      multiline={!!rows || multiline}
      {...rest}
    />
  )
}

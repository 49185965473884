import { Box, Typography } from '@mui/material'
import { NumberUnitField, Unit } from 'components/InputFields/NumberUnitField'
import { SearchField } from 'components/SearchField/SearchField'
import { BoxRowBasic, ViewerBottomHeaderFooter } from 'components/common'
import { IngredientViewerPanelContent } from 'pages/Ingredients/components/IngredientViewer/IngredientViewerStyles'
import React from 'react'
import { NutrientRowGroupProps } from './components/NutrientRowGroup'
import NutrientsTable from './components/NutrientTable'

interface NutrientsProps {
  search: {
    value: string
    onChange: (value: string) => void
  }
  nutrientTableRowGroups: NutrientRowGroupProps[]
  disabled?: boolean
  basedOn: {
    unit: Unit
    unitOptions: Unit[]
    scale?: number
    onUnitChange: (unit: Unit) => void
    onScaleChange: (scale: number) => void
  }
}

export const Nutrients: React.FC<NutrientsProps> = ({
  search,
  nutrientTableRowGroups,
  basedOn,
  disabled = false
}) => {
  return (
    <>
      <IngredientViewerPanelContent>
        <Box sx={{ padding: '20px 20px 0px 20px' }}>
          <SearchField
            placeholder="Search nutrients"
            onChange={search.onChange}
            sx={{ width: '100%' }}
          />
          {search.value && nutrientTableRowGroups.length === 0 && (
            <Typography sx={{ margin: '10px 0px', textAlign: 'center' }}>
              No nutrient matched your search
            </Typography>
          )}
        </Box>
        <NutrientsTable
          nutrientTableRowGroups={nutrientTableRowGroups}
          disabled={disabled}
          loading={!search.value && nutrientTableRowGroups.length === 0}
        />
      </IngredientViewerPanelContent>

      <ViewerBottomHeaderFooter data-footer>
        <BoxRowBasic gap={'5px'} style={{ alignItems: 'center' }}>
          <Typography color={'secondary'}>Based on</Typography>

          <NumberUnitField
            value={basedOn.scale || 100}
            validation=">0"
            unit={basedOn.unit}
            onChange={(value) => {
              basedOn.onScaleChange(value)
            }}
            onUnitChange={(unit) => {
              basedOn.onUnitChange(unit)
            }}
            unitOptions={basedOn.unitOptions}
            disabled={disabled}
            debounceTime={1000}
            maxDecimals={2}
          />
        </BoxRowBasic>
      </ViewerBottomHeaderFooter>
    </>
  )
}

import { SxProps, Typography } from '@mui/material'
import { BoxRow } from 'components/common'
import React from 'react'

interface EditableTextProps {
  initialValue?: string
  saveAction: (title: string) => void
  placeholder?: string
  variant?:
    | 'body2'
    | 'h1'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'caption'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
  prefix?: string
  sx?: SxProps
  withBorder?: boolean
  disabled?: boolean
}

export const EditableText: React.FC<EditableTextProps> = ({
  initialValue,
  saveAction,
  placeholder,
  variant = 'body1',
  prefix,
  sx,
  disabled = false,
  withBorder = true
}) => {
  const [value, setValue] = React.useState(initialValue)
  const [isEditing, setIsEditing] = React.useState(false)
  const [isHovered, setIsHovered] = React.useState(false)
  const titleRef = React.useRef<HTMLSpanElement>(null)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const handleNewTitle = () => {
    setIsEditing(false)
    if (titleRef.current) {
      const updatedTitle = (
        titleRef.current.textContent ||
        placeholder ||
        ''
      ).trim()
      setValue(updatedTitle)
      if (initialValue !== updatedTitle) {
        saveAction(updatedTitle)
      }
    }
  }

  const handleKeyInput = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      titleRef.current?.blur()
    }
  }

  const handleClick = () => {
    if (!disabled) {
      setIsEditing(true)
      if (value === placeholder) {
        setValue('')
      }
      setTimeout(() => {
        titleRef.current?.focus()
      }, 0)
    }
  }

  const handleMouseOut = () => {
    if (!disabled) {
      setIsHovered(false)
    }
  }

  const handleMouseEnter = () => {
    if (!disabled) {
      setIsHovered(true)
    }
  }

  const handleFocus = () => {
    if (!disabled) {
      handleClick()
    }
  }

  return (
    <BoxRow>
      <Typography
        variant={variant}
        sx={{
          color: value === placeholder && !isEditing ? 'gray' : 'black',
          ...sx
        }}
      >
        {prefix}
      </Typography>
      <Typography
        ref={titleRef}
        suppressContentEditableWarning={true}
        contentEditable={isEditing && !disabled}
        onBlur={handleNewTitle}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseOut={handleMouseOut}
        onKeyDown={handleKeyInput}
        onFocus={handleFocus}
        variant={variant}
        sx={{
          border: withBorder
            ? `1px solid ${
                isHovered || isEditing ? 'rgba(0, 0, 0, 0.1)' : 'transparent'
              }`
            : 'none',
          borderRadius: '8px',
          boxShadow: 'none',
          padding: '4px 0px',
          outline: 'none',
          color: value === placeholder && !isEditing ? 'gray' : 'black',
          minWidth: '20px',
          ...sx
        }}
      >
        {isEditing ? value : value || placeholder}
      </Typography>
    </BoxRow>
  )
}

import { SupplementFactsIngredient } from 'models/SupplementFacts'
import { ItemTableContent } from './ItemTableContent'

export const ActiveIngredientsTable: React.FC<{
  activeIngredients: SupplementFactsIngredient[]
}> = ({ activeIngredients = [] }) => {
  return (
    <>
      <ItemTableContent
        tableItemRowsProps={activeIngredients.map((ai) => {
          const name = ai.ingredientStatement
            ? `${ai.name}\n${ai.ingredientStatement}`
            : ai.name

          return {
            itemRowValues: {
              name: name,
              amount: ai.amountInMeasurement,
              unit: ai.measurement.unit,
              dvPercentage: '†'
            }
          }
        })}
      />
    </>
  )
}

import { BasicUser } from 'models/User'
import {
  ReviewerApiResponse,
  TaskApiResponse
} from 'services/apis/label_proofs/LabelProofApiResponse'
import { ActionStatus } from '../../../../components/Avatar'
import {
  Reviewer,
  ReviewerOption
} from './ReviewItem/components/Reviewers/Reviewers'
import { ReviewItem } from './ReviewList'

const isTaskChecked = (
  reviewers: ReviewerApiResponse[],
  currentUserId: string
): boolean => {
  // If the current user is a reviewer and approved the task or if all reviewers approved, it is checked.
  if (reviewers.length === 0) {
    return false
  }
  return (
    reviewers.some(
      (reviewer) => reviewer.reviewer.id === currentUserId && reviewer.approved
    ) || reviewers.every((reviewer) => reviewer.approved)
  )
}

const isTaskCompleted = (reviewers: ReviewerApiResponse[]): boolean => {
  return (
    reviewers.every((reviewer) => reviewer.approved) && reviewers.length > 0
  )
}

const getReviewersFromTask = (reviewers: ReviewerApiResponse[]): Reviewer[] => {
  return reviewers.map((reviewer) => ({
    id: reviewer.reviewer.id,
    name: reviewer.reviewer.firstName + ' ' + reviewer.reviewer.lastName,
    imageUrl: reviewer.reviewer.imagePath,
    removable: true,
    action: reviewer.approved
      ? {
          status: ActionStatus.APPROVED,
          date: reviewer.approvedAt || ''
        }
      : undefined
  }))
}

const getReviewerOptions = (
  allReviewerOptions: BasicUser[]
): ReviewerOption[] => {
  return allReviewerOptions.map((reviewer) => ({
    id: reviewer.id,
    name: reviewer.firstName + ' ' + reviewer.lastName,
    imageUrl: reviewer.imagePath
  }))
}

const isCheckable = (
  reviewers: ReviewerApiResponse[],
  currentUserId: string,
  createdBy: string
): boolean => {
  return (
    reviewers.some((reviewer) => reviewer.reviewer.id === currentUserId) ||
    createdBy === currentUserId
  )
}

const isEditable = (createdBy: string, currentUserId: string): boolean => {
  return true // For now.
}

export const getReviewItemsFromTasks = (
  tasks: TaskApiResponse[],
  allReviewerOptions: BasicUser[],
  currentUserId: string
): ReviewItem[] => {
  return tasks.map((task: TaskApiResponse) => ({
    id: task.id,
    name: task.name,
    checked: isTaskChecked(task.reviewers, currentUserId),
    completed: isTaskCompleted(task.reviewers),
    reviewerOptions: getReviewerOptions(allReviewerOptions),
    reviewers: getReviewersFromTask(task.reviewers),
    checkable: isCheckable(task.reviewers, currentUserId, task.createdBy.id),
    editable: isEditable(task.createdBy.id, currentUserId)
  }))
}

import { Checkbox } from '@mui/material'
import { BoxRow } from 'components/common'
import { EditableText } from 'components/EditableText/EditableText'
import React from 'react'

export interface ReviewItemAdderProps {
  onAdd: (name: string) => Promise<void>
}

export const ReviewItemCreator: React.FC<ReviewItemAdderProps> = ({
  onAdd
}) => {
  const placeholder = 'Add another task...'
  const [lastValue, setLastValue] = React.useState<string>(placeholder)

  const onSave = (name: string) => {
    setLastValue(name)
    if (name !== placeholder && name.trim() !== '') {
      onAdd(name)
        .then(() => {
          setLastValue(placeholder)
        })
        .catch(() => {
          setLastValue(name)
        })
    }
  }

  return (
    <BoxRow>
      <Checkbox disabled />
      <EditableText
        initialValue={lastValue}
        placeholder={placeholder}
        saveAction={onSave}
      />
    </BoxRow>
  )
}

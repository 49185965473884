import { Box, Input, Typography } from '@mui/material'
import { BoxRow, LabelTypography } from 'components/common'
import { InputFieldProps } from 'components/InputFields/InputField'
import React from 'react'
import { Tag, TagOption, TagProps } from './Tag'
import { useDebouncedCallback } from 'use-debounce'

export interface AutocompleteInputFieldProps extends InputFieldProps {
  tags: Omit<TagProps, 'onDelete' | 'disabled'>[]
  disabled?: boolean
  onDelete: (tag: TagOption) => void
  onCreate?: (newTag: string) => void
  maxDisplay?: number
  debounceTime?: number
  label?: string
  labelIcon?: React.ReactNode
  required?: boolean
}

export const AutocompleteInputField: React.FC<AutocompleteInputFieldProps> = ({
  tags,
  disabled,
  onDelete,
  onCreate,
  maxDisplay,
  debounceTime,
  value = '',
  onChange,
  label,
  labelIcon,
  required,
  ...rest
}) => {
  const [internalValue, setInternalValue] = React.useState<string>(value)
  const [pendingSubmission, setPendingSubmission] =
    React.useState<boolean>(false)

  React.useEffect(() => {
    if (!pendingSubmission) {
      setInternalValue(value)
    }
  }, [value])

  const handleInputChangeDebounced = useDebouncedCallback(
    (inputValue: string) => {
      if (onChange) {
        onChange(inputValue)
        setPendingSubmission(false)
      }
    },
    debounceTime
  )

  const handleInputChange = (inputValue: string) => {
    setInternalValue(inputValue)
    setPendingSubmission(true)
    handleInputChangeDebounced(inputValue)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && onCreate && internalValue.trim()) {
      onCreate(internalValue.trim())
      // Reset the input field after creating a new tag.
      handleInputChange('')
    }
  }

  return (
    <Box>
      {label && (
        <LabelTypography variant="subtitle2">
          {label} {required && '*'} {labelIcon}
        </LabelTypography>
      )}
      <BoxRow
        sx={{
          display: 'inline-flex',
          flexWrap: 'wrap',
          background: '#FDFDFD' as const,
          border: '1px solid rgba(161, 171, 183, 0.3)' as const,
          boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.05)' as const,
          borderRadius: '8px' as const,
          position: 'relative' as const,
          overflow: 'hidden' as const,
          gap: '5px',
          padding: '4px 8px'
        }}
      >
        {tags.slice(0, disabled ? tags.length : maxDisplay).map((tag) => (
          <Tag
            key={tag.id}
            {...tag}
            onDelete={() => onDelete(tag)}
            disabled={disabled}
          />
        ))}
        {!disabled && maxDisplay && tags.length > maxDisplay && (
          <Typography>+{tags.length - maxDisplay}</Typography>
        )}
        {(!disabled || tags.length === 0) && (
          <Input
            value={internalValue}
            disabled={disabled}
            onChange={(e) => handleInputChange(e.target.value)}
            onKeyDown={handleKeyDown}
            sx={{
              background: 'transparent',
              flexGrow: 1,
              border: 'none',
              padding: '0px',
              '& input': {
                width: '100%',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize:
                  rest.scale === 'large'
                    ? '20px'
                    : rest.scale === 'small'
                    ? '14px'
                    : '16px',
                lineHeight: '20px',
                color: '#16191C',
                backgroundColor: 'transparent',
                padding: '0px'
              }
            }}
            {...rest}
          />
        )}
      </BoxRow>
    </Box>
  )
}

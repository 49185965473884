import { MentionApiResponse, PinApiResponse } from "services/apis/label_proofs/LabelProofApiResponse"

export const getMentionedUsersMap = (mentions: MentionApiResponse[]): Record<string, string> => {
    return mentions.reduce((acc, mention) => {
        acc[mention.mentionedUser.id as string] =
            mention.mentionedUser.firstName +
            ' ' +
            mention.mentionedUser.lastName
        return acc
    }, {} as Record<string, string>)
}

export const getPinsMap = (pins: (PinApiResponse | undefined)[]): Record<string, string> => {
    return pins.reduce((acc, pin) => {
        if (pin) {
            acc[pin.id] = "Pin"
        }
        return acc
    }, {} as Record<string, string>)
}

export const addPinToText = (text: string, pin?: PinApiResponse) => {
    return pin ? `@@[${pin.id}] ${text}` : text
}

import {
  EntrTableContainer,
  PageEntrTableInnerContainer
} from 'components/common'
import { EntrCardProps } from 'components/EntrGrid/EntrCard'
import { EntrGrid } from 'components/EntrGrid/EntrGrid'
import { TitleActionsBar } from 'components/TitleActionsBar/TitleActionsBar'
import React from 'react'
import {
  ProofCardContent,
  ProofCardProps
} from './components/Grid/ProofCardContent'

interface LabelProofProps {
  filterAndSearch: React.ReactNode
  proofs: Omit<ProofCardProps, 'onClick'>[]
  newProofAction: React.ReactNode
  loading?: boolean
  allPagesLoaded: boolean
  onProofClick: (id: string) => void
  onLoadNextPage: () => void
}

export const LabelProofs: React.FC<LabelProofProps> = ({
  filterAndSearch,
  proofs,
  newProofAction,
  loading,
  allPagesLoaded,
  onProofClick,
  onLoadNextPage
}) => {
  const proofCards: EntrCardProps[] = proofs.map((proof) => ({
    id: proof.id,
    children: <ProofCardContent {...proof} />,
    onClick: () => onProofClick(proof.id)
  }))

  return (
    <>
      <PageEntrTableInnerContainer>
        <EntrTableContainer>
          <TitleActionsBar title={'Label Proofs'} action={newProofAction} />
          {filterAndSearch}
          <EntrGrid
            cards={proofCards}
            onCardClick={onProofClick}
            noDataMessage="It's empty here. Create a new proof!"
            loading={loading}
            allPagesLoaded={allPagesLoaded}
            onLoadNextPage={onLoadNextPage}
          />
        </EntrTableContainer>
      </PageEntrTableInnerContainer>
    </>
  )
}

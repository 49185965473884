import React from 'react'
import { DraggablePin } from './Pin'
import { Pin } from './Pin'

interface PinsProps {
  pins: DraggablePin[]
  scale: number
  updatePosition: (x: number, y: number, id: string) => void
}

export const Pins: React.FC<PinsProps> = ({ pins, scale, updatePosition }) => {
  return (
    <>
      {pins.map((pin) => (
        <Pin
          key={pin.commentId || pin.id}
          id={pin.id}
          highlighted={pin.highlighted}
          commentId={pin.commentId}
          hidden={pin.hidden}
          x={pin.x}
          y={pin.y}
          scale={scale}
          updatePosition={updatePosition}
        />
      ))}
    </>
  )
}

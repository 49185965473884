import React from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  addReviewer,
  createTask,
  deleteTask,
  getReviewerOptions,
  getTasks,
  removeReviewer,
  reviewTask,
  updateTask
} from 'state/labelproof/proofer/review/ReviewSlice'
import { ReviewList } from './ReviewList'
import { getReviewItemsFromTasks } from './ReviewListMapper'
import { ModalContext } from 'components/Modal/ModalContext'

export const ReviewListContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const companyId = useAppSelector((state) => state.companies.currentCompany.id)
  const proofId = useAppSelector((state) => state.proofer.proof?.id)
  const userId = useAppSelector((state) => state.users.currentUser?.id)
  const tasks = useAppSelector((state) => state.review.tasks)
  const allReviewerOptions = useAppSelector(
    (state) => state.review.reviewerOptions
  )
  const loading = useAppSelector(
    (state) => state.review.loadingTasks && state.review.loadingReviewerOptions
  )

  const { showConfirmationModal } = React.useContext(ModalContext)

  React.useEffect(() => {
    if (companyId) {
      if (proofId) {
        void dispatch(getTasks({ companyId, proofId }))
      }
      void dispatch(getReviewerOptions({ companyId }))
    }
  }, [companyId, proofId])

  const handleAddItem = async (name: string) => {
    if (companyId && proofId) {
      await dispatch(createTask({ companyId, proofId, name }))
      return await Promise.resolve()
    }
    return Promise.reject()
  }

  const handleChangeItemName = (id: string, name: string) => {
    if (companyId && proofId) {
      void dispatch(updateTask({ companyId, proofId, taskId: id, name }))
    }
  }

  const handleAssignReviewer = (id: string, reviewerId: string) => {
    if (companyId && proofId) {
      void dispatch(addReviewer({ companyId, proofId, taskId: id, reviewerId }))
    }
  }

  const handleUnassignReviewer = (id: string, reviewerId: string) => {
    if (companyId && proofId) {
      void dispatch(
        removeReviewer({ companyId, proofId, taskId: id, reviewerId })
      )
    }
  }

  const handleChangeCheckItem = (id: string, checked: boolean) => {
    if (companyId && proofId) {
      void dispatch(
        reviewTask({ companyId, proofId, taskId: id, approved: checked })
      )
    }
  }

  const handleDeleteItem = (id: string) => {
    if (companyId && proofId) {
      showConfirmationModal({
        title: 'Delete Task',
        message: 'Are you sure you want to permanently delete this task?',
        onYesClicked: () => {
          void dispatch(deleteTask({ companyId, proofId, taskId: id }))
        },
        yesText: 'Delete',
        noText: 'Cancel',
        danger: true
      })
    }
  }

  return (
    <ReviewList
      reviewItems={getReviewItemsFromTasks(tasks, allReviewerOptions, userId)}
      onAssignReviewer={handleAssignReviewer}
      onUnassignReviewer={handleUnassignReviewer}
      onChangeCheckItem={handleChangeCheckItem}
      onChangeItemName={handleChangeItemName}
      onAddItem={handleAddItem}
      onDeleteItem={handleDeleteItem}
      loading={loading}
    />
  )
}

import {
  BoxRow,
  ViewerContainer,
  ViewerContent,
  ViewerRow
} from 'components/common'
import React from 'react'

interface IngredientViewerProps {
  show: boolean
  topBar: React.ReactNode
  form: React.ReactNode
  panel: React.ReactNode
}

export const SideViewer = React.forwardRef<
  HTMLDivElement,
  IngredientViewerProps
>(({ show = false, topBar, form, panel }, ref) => {
  return (
    <ViewerContainer data-show={show} ref={ref}>
      <ViewerRow sx={{ '&&': { gridTemplateColumns: 'auto auto' } }}>
        <BoxRow></BoxRow>
        {topBar}
      </ViewerRow>

      <ViewerContent>
        {form}
        {panel}
      </ViewerContent>
    </ViewerContainer>
  )
})

import { Typography } from '@mui/material'
import { BoxColumnBasic, BoxRow } from 'components/common'
import { TabContent } from 'pages/Formulator/components/FormulatorPanel/TabContent'
import React from 'react'

export interface ReviewProps {
  reviewList: React.ReactNode
}

export const Review: React.FC<ReviewProps> = ({ reviewList }) => {
  return (
    <TabContent>
      <BoxColumnBasic sx={{ gap: '10px' }}>
        <BoxRow sx={{ height: '90px', borderBottom: '1px solid #00000008' }}>
          <BoxColumnBasic sx={{ gap: '4px', paddingBottom: '20px' }}>
            <Typography variant="h5">Track what needs review</Typography>
            <Typography variant="subtitle2">
              Add review tasks and assign people
            </Typography>
          </BoxColumnBasic>
        </BoxRow>
        {reviewList}
      </BoxColumnBasic>
    </TabContent>
  )
}

import { ComponentPreview } from 'components/ComponentPreview/ComponentPreview'
import React from 'react'
import { Pins } from './Pins/Pins'
import { Box } from '@mui/material'
import { DraggablePin } from './Pins/Pin'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { buildPinId } from 'state/labelproof/proofer/helpers'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import { setStagedComment } from 'state/labelproof/proofer/ProoferActivitiesSlice'

export interface PreviewProps {
  fileUrl?: string
  pins?: DraggablePin[]
}

export const PreviewContainer: React.FC<PreviewProps> = ({ fileUrl, pins }) => {
  const dispatch = useAppDispatch()
  const { showWarning } = React.useContext(SnackbarContext)
  const [currentScale, setCurrentScale] = React.useState<number>(1)
  const [transformState, setTransformState] = React.useState({
    positionX: 0,
    positionY: 0
  })

  const [imageLoaded, setImageLoaded] = React.useState<boolean>(false)
  const [imageDimensions, setImageDimensions] = React.useState({
    width: 0,
    height: 0
  })
  const stagedComment = useAppSelector(
    (state) => state.prooferActivities.stagedComment
  )
  const highlightedPinId = useAppSelector((state) => state.proofer.highlightedPinId)
  const pinMode = useAppSelector((state) => state.prooferActivities.pinMode)
  const imgRef = React.useRef<HTMLImageElement>(null)
  React.useEffect(() => {
    if (imgRef.current && imageLoaded) {
      setImageDimensions({
        width: imgRef.current.naturalWidth,
        height: imgRef.current.naturalHeight
      })
    }
  }, [imageLoaded])

  const addPin = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (!imageLoaded) {
      return
    }
    if (!pinMode) {
      showWarning('Please enable pin mode to add a pin')
      return
    }

    if (stagedComment.pin) {
      showWarning('You can only add one pin for a comment')
      return
    }

    const widthRatio = e.nativeEvent.offsetX / imageDimensions.width
    const heightRatio = e.nativeEvent.offsetY / imageDimensions.height

    dispatch(
      setStagedComment({
        pin: {
          id: buildPinId(widthRatio, heightRatio),
          widthRatio,
          heightRatio
        }
      })
    )
  }

  const updatePinPosition = (
    newX: number,
    newY: number,
    id: string,
  ) => {
    if (id !== stagedComment.pin?.id) { 
      return
    }
    dispatch(
      setStagedComment({
        pin: {
          ...stagedComment.pin,
          widthRatio: newX / imageDimensions.width,
          heightRatio: newY / imageDimensions.height
        }
      })
    )
  }

  const handleTransformChange = (ref: any) => {
    setTransformState({
      positionX: ref.state.positionX,
      positionY: ref.state.positionY
    })
    setCurrentScale(ref.state.scale)
  }

  const allDraggablePins = React.useMemo(() => {
    let draggablePins = []
    if (pins) {
      draggablePins.push(
        ...pins.map((pin) => ({
          id: pin.id,
          highlighted: pin.highlighted,
          commentId: pin.commentId,
          hidden: stagedComment.pin?.id === pin.id,
          x: pin.x * imageDimensions.width,
          y: pin.y * imageDimensions.height
        }))
      )
    }

    if (stagedComment.pin) {
      draggablePins.push({
        id: stagedComment.pin.id,
        x: stagedComment.pin.widthRatio * imageDimensions.width,
        y: stagedComment.pin.heightRatio * imageDimensions.height,
        highlighted: pinMode,
        hidden: false
      })
    } else {
      draggablePins = draggablePins.filter((pin) => pin.commentId !== stagedComment.id)
    }

    return draggablePins
  }, [pins, stagedComment.pin, imageDimensions, pinMode])

  return (
    <ComponentPreview
      loading={false}
      loadingText="Loading design"
      onTransformChange={handleTransformChange}
      component={
        <div
          style={{
            position: 'relative',
            display: 'inline-block',
            width: '100%',
            height: '100%',
            backgroundColor: 'white'
          }}
          onContextMenu={addPin}
        >
          <img
            ref={imgRef}
            src={fileUrl}
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'cover'
            }}
            onLoad={() => setImageLoaded(true)}
          />
          <Box
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              top: 0,
              left: 0
            }}
          >
            <Pins
              pins={allDraggablePins}
              scale={currentScale}
              updatePosition={updatePinPosition}
            />
          </Box>
        </div>
      }
    />
  )
}

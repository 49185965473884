import {
  DisplayAmountOption,
  DisplayDvOption,
  FormulaNutritionFact,
  FormulaNutritionFactLabelAgeGroup,
  FormulaNutritionFactLabelLangSpecificFields,
  FormulaNutritionFactLabelType,
  FormulaNutritionFactLanguage,
  FormulaNutritionFactNutrient,
  FormulaNutritionFactNutrientLabelDisplay
} from 'models/FormulaLabel'
import {
  FDA_CUTOFF_RANK_TOP_BOTTOM,
  HEALTH_CANADA_CUTOFF_RANK_TOP_BOTTOM,
  NutrientRequirement,
  NutrientType
} from 'models/Nutrient'
import React from 'react'
import { RegulationName } from 'services/apis/regulation/RegulationApiResponse'
import { NutritionLabelDual } from './NutritionLabelDual/NutritionLabelDual'
import { NutritionLabelLinear } from './NutritionLabelLinear/NutritionLabelLinear'
import { NutritionLabelLinearCanadian } from './NutritionLabelLinearCanadian/NutritionLabelLinearCanadian'
import { NutritionLabelTabular } from './NutritionLabelTabular/NutritionLabelTabular'
import { NutritionLabelVertical } from './NutritionLabelVertical/NutritionLabelVertical'
import { NutritionLabelVerticalCanadian } from './NutritionLabelVerticalCanadian/NutritionLabelVerticalCanadian'

export interface NutritionFactsPerContainerProps {
  calories: string
  topNutrients: FormulaNutritionFactNutrient[]
  bottomNutrients: FormulaNutritionFactNutrient[]
}
export interface NutritionFactsProps {
  servingsPerContainer: number
  servingSize: FormulaNutritionFactLabelLangSpecificFields
  servingWeight: string
  type: FormulaNutritionFactLabelType
  ageGroup: FormulaNutritionFactLabelAgeGroup
  calories: string
  topNutrients: FormulaNutritionFactNutrientLabelDisplay[]
  bottomNutrients: FormulaNutritionFactNutrientLabelDisplay[]
  perContainer?: NutritionFactsPerContainerProps
}

export interface NutritionLabelProps extends FormulaNutritionFact {
  regulationName: RegulationName
}

export const NutritionLabel: React.FC<NutritionLabelProps> = ({
  servingsPerContainer,
  servingSize,
  servingWeight,
  servingWeightOverride,
  nutrients,
  type,
  ageGroup,
  calories,
  optionalNutrientsType: optionalNutrients,
  perContainer,
  showProteinPercentage,
  regulationName
}) => {
  const optionalNutrientsType = optionalNutrients

  const caloriesOverrideAmount = React.useMemo(() => {
    return nutrients.find((n) => n.nutrient.type === NutrientType.CALORIES)
      ?.overrideValues?.amount
  }, [nutrients])
  const perContainerCaloriesOverrideAmount = React.useMemo(() => {
    return perContainer?.nutrients.find(
      (n) => n.nutrient.type === NutrientType.CALORIES
    )?.overrideValues?.perContainerAmount
  }, [perContainer])

  const cutOffRank = React.useMemo(() => {
    switch (regulationName) {
      case RegulationName.FDA:
        return FDA_CUTOFF_RANK_TOP_BOTTOM
      case RegulationName.CANADA:
        return HEALTH_CANADA_CUTOFF_RANK_TOP_BOTTOM
      default:
        return FDA_CUTOFF_RANK_TOP_BOTTOM
    }
  }, [regulationName])

  const mapDisplayNameOptions = (
    displayNameOptions: string[] | FormulaNutritionFactLabelLangSpecificFields
  ): FormulaNutritionFactLabelLangSpecificFields => {
    if (!Array.isArray(displayNameOptions)) {
      return displayNameOptions as FormulaNutritionFactLabelLangSpecificFields
    }

    return Object.values(FormulaNutritionFactLanguage).reduce(
      (acc, language, index) => {
        if (displayNameOptions[index]) {
          acc[language] = displayNameOptions[index]
        }
        return acc
      },
      {} as FormulaNutritionFactLabelLangSpecificFields
    )
  }

  const isNutrientNameOverrideAvailable = (
    nutrient: FormulaNutritionFactNutrient
  ): boolean => {
    return nutrient.overrideValues?.nutrientDisplayName
      ? Object.keys(nutrient.overrideValues?.nutrientDisplayName).length > 0
        ? true
        : false
      : false
  }

  const getNutrientDisplayNameOptions = (
    nutrient: FormulaNutritionFactNutrient
  ): FormulaNutritionFactLabelLangSpecificFields => {
    const originalNames = mapDisplayNameOptions(nutrient.displayNameOptions)

    if (isNutrientNameOverrideAvailable(nutrient)) {
      const overrides = nutrient.overrideValues
        ?.nutrientDisplayName as FormulaNutritionFactLabelLangSpecificFields

      // Combine overrides with original names, preferring overrides
      return Object.keys(originalNames).reduce((result, language) => {
        const lang = language as FormulaNutritionFactLanguage
        result[lang] = overrides[lang] || originalNames[lang]
        return result
      }, {} as FormulaNutritionFactLabelLangSpecificFields)
    }

    // Return original names if no overrides are available
    return originalNames
  }

  const getDisplayDvOptions = (
    nutrient: FormulaNutritionFactNutrient,
    forContainerColumn: boolean,
    showProteinPercentage: boolean = true
  ): DisplayDvOption[] => {
    if (
      nutrient.nutrient.type === NutrientType.PROTEIN &&
      !showProteinPercentage
    ) {
      return [] as DisplayDvOption[]
    }
    const dvOverride = nutrient.overrideValues?.dv
    const perContainerDvOverride = nutrient.overrideValues?.perContainerDv
    const originalDv = nutrient.displayDvOptions
    if (forContainerColumn) {
      return perContainerDvOverride
        ? [{ amount: perContainerDvOverride }]
        : originalDv
    }
    return dvOverride ? [{ amount: dvOverride }] : originalDv
  }

  const getDisplayAmountOptions = (
    nutrient: FormulaNutritionFactNutrient,
    forContainerColumn: boolean
  ): DisplayAmountOption[] => {
    const amountOverride = nutrient.overrideValues?.amount
    const perContainerAmountOverride =
      nutrient.overrideValues?.perContainerAmount
    const originalAmount = nutrient.displayAmountOptions
    if (forContainerColumn) {
      return perContainerAmountOverride
        ? [{ amount: perContainerAmountOverride }]
        : originalAmount
    }
    return amountOverride ? [{ amount: amountOverride }] : originalAmount
  }

  const getBottomNutrients = (
    formulaNutritionFactNutrients: FormulaNutritionFactNutrient[],
    forContainerColumn: boolean = false
  ): FormulaNutritionFactNutrientLabelDisplay[] => {
    return formulaNutritionFactNutrients
      .filter(
        (fnfn) =>
          fnfn.nutrient.rank > cutOffRank &&
          (fnfn.nutrient.requirement === NutrientRequirement.MANDATORY ||
            (fnfn.nutrient.requirement === NutrientRequirement.OPTIONAL &&
              optionalNutrientsType?.includes(fnfn.nutrient.type)))
      )
      .map((a) => {
        return {
          nutrient: a.nutrient,
          displayDvOptions: getDisplayDvOptions(a, forContainerColumn),
          displayAmountOptions: getDisplayAmountOptions(a, forContainerColumn),
          displayNameOptions: getNutrientDisplayNameOptions(a)
        }
      })
      .sort((a, b) => a.nutrient.rank - b.nutrient.rank)
  }

  const getServingWeight = (
    servingWeight: number,
    servingSizeOverride: string,
    canadian: boolean
  ): string => {
    if (servingSizeOverride) {
      return servingSizeOverride
    }

    if (canadian) {
      // If the serving weight is less than 10g, round to nearest 0.1g.
      if (servingWeight < 10) {
        return servingWeight.toFixed(1) + ' g'
      }
      // Otherwise, round up to nearest 1g.
      return Math.round(servingWeight).toString() + ' g'
    }
    return servingWeight.toString() + 'g'
  }

  const getTopNutrients = (
    formulaNutritionFactNutrients: FormulaNutritionFactNutrient[],
    showProteinPercentage = false,
    forContainerColumn: boolean = false
  ): FormulaNutritionFactNutrientLabelDisplay[] => {
    return formulaNutritionFactNutrients
      .filter(
        (fnfn) =>
          fnfn.nutrient.type != NutrientType.CALORIES &&
          fnfn.nutrient.rank <= cutOffRank &&
          (fnfn.nutrient.requirement === NutrientRequirement.MANDATORY ||
            (fnfn.nutrient.requirement === NutrientRequirement.OPTIONAL &&
              optionalNutrientsType?.includes(fnfn.nutrient.type)))
      )
      .map((a) => {
        // Remove the dv % for protein if not specified.
        return {
          nutrient: a.nutrient,
          displayDvOptions: getDisplayDvOptions(
            a,
            forContainerColumn,
            showProteinPercentage
          ),
          displayAmountOptions: getDisplayAmountOptions(a, forContainerColumn),
          displayNameOptions: getNutrientDisplayNameOptions(a)
        }
      })
      .sort((a, b) => a.nutrient.rank - b.nutrient.rank)
  }

  const props: NutritionFactsProps = {
    servingsPerContainer,
    servingSize,
    servingWeight: getServingWeight(
      servingWeight,
      servingWeightOverride,
      regulationName === RegulationName.CANADA
    ),
    topNutrients: getTopNutrients(nutrients, showProteinPercentage),
    bottomNutrients: getBottomNutrients(nutrients),
    calories: caloriesOverrideAmount ? caloriesOverrideAmount : calories,
    type,
    ageGroup,
    perContainer: perContainer
      ? {
          calories: perContainerCaloriesOverrideAmount || perContainer.calories,
          topNutrients: getTopNutrients(
            perContainer?.nutrients,
            showProteinPercentage,
            true
          ),
          bottomNutrients: getBottomNutrients(perContainer?.nutrients, true)
        }
      : {
          calories: calories,
          topNutrients: getTopNutrients(nutrients, showProteinPercentage, true),
          bottomNutrients: getBottomNutrients(nutrients, true)
        }
  }

  if (regulationName === RegulationName.CANADA) {
    switch (type) {
      case FormulaNutritionFactLabelType.VERTICAL:
        return <NutritionLabelVerticalCanadian {...props} />
      case FormulaNutritionFactLabelType.LINEAR:
        return <NutritionLabelLinearCanadian {...props} />
      default:
        return <NutritionLabelVerticalCanadian {...props} />
    }
  } else {
    switch (type) {
      case FormulaNutritionFactLabelType.VERTICAL:
        return <NutritionLabelVertical {...props} />
      case FormulaNutritionFactLabelType.LINEAR:
        return <NutritionLabelLinear {...props} />
      case FormulaNutritionFactLabelType.TABULAR:
        return <NutritionLabelTabular {...props} />
      case FormulaNutritionFactLabelType.DUAL:
        return <NutritionLabelDual {...props} />
      default:
        return <NutritionLabelVertical {...props} />
    }
  }
}

import { BoxRow } from 'components/common'
import React from 'react'
import { Reviewers, ReviewersProps } from './components/Reviewers/Reviewers'
import { Task, TaskProps } from './components/Task/Task'

export interface ReviewItemProps {
  taskProps: TaskProps
  reviewersProps: ReviewersProps
}

export const ReviewItem: React.FC<ReviewItemProps> = ({
  taskProps,
  reviewersProps
}) => {
  return (
    <BoxRow sx={{ justifyContent: 'space-between', gap: '10px' }}>
      <Task {...taskProps} />
      <Reviewers {...reviewersProps} />
    </BoxRow>
  )
}

import { Autocomplete, TextField, Typography } from '@mui/material'
import { BoxColumnBasic, ViewerBottomHeaderFooter } from 'components/common'
import React from 'react'
import {
  ConversionItem,
  ConversionItemProps
} from './components/ConversionItem'
import { IngredientViewerPanelContent } from 'pages/Ingredients/components/IngredientViewer/IngredientViewerStyles'

export interface MeasurementOption {
  id: string
  name: string
  unit: string
}

interface IngredientMeasurementsPanelProps {
  conversions: ConversionItemProps[]
  measurementOptions: MeasurementOption[]
  onMeasurementSelect: (measurement: MeasurementOption) => void
  readOnly?: boolean
}

export const Measurements: React.FC<IngredientMeasurementsPanelProps> = ({
  conversions,
  measurementOptions,
  onMeasurementSelect,
  readOnly = false
}) => {
  const [measurementValue, setMeasurementValue] =
    React.useState<MeasurementOption | null>(null)

  const handleMeasurementChange = (value: MeasurementOption | null) => {
    if (value) {
      onMeasurementSelect(value)
    }
    setMeasurementValue(null)
  }

  return (
    <>
      <IngredientViewerPanelContent>
        <BoxColumnBasic sx={{ padding: '20px', gap: '10px' }}>
          {conversions.map((c) => (
            <ConversionItem key={c.id} {...c} disabled={readOnly} />
          ))}

          {conversions.length > 0 && (
            <Typography variant="subtitle2">
              <i>
                All other liquid measurements will be derived from this
                measurement.
              </i>
            </Typography>
          )}

          {!readOnly && conversions.length === 0 && (
            <Autocomplete
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              value={measurementValue}
              options={measurementOptions}
              onChange={(_, v) => handleMeasurementChange(v)}
              getOptionLabel={(option: MeasurementOption) =>
                `${option.name} (${option.unit})`
              }
              renderInput={(params) => (
                <TextField {...params} placeholder="e.g., 100 mL = 100 g" />
              )}
            />
          )}
        </BoxColumnBasic>
      </IngredientViewerPanelContent>

      <ViewerBottomHeaderFooter data-footer />
    </>
  )
}

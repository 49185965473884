import React from 'react'
import { Activity } from './Activity'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { addPinToText } from '../../helpers'

export interface ActivityContainerPin {
  id: string
  widthRatio: number
  heightRatio: number
}

export interface ActivityContainerProps {
  id: string
  text: string
  createdBy?: {
    id: string
    name: string
    isOwner: boolean
    avatarSrc?: string
  }
  repliedComment?: {
    id: string
    text: string
    createdByName?: string
    deleted: boolean
    pin?: ActivityContainerPin
  }
  createdAt: string
  lastEditedAt?: string
  deleted: boolean
  pin?: ActivityContainerPin
  mentionedUsersMap: Record<string, string>
  pinsMap: Record<string, string>
  onDeleteClick: (id: string) => void
  onEditClick: (id: string) => void
  onReplyClick: (id: string) => void
  onPinClick: (id: string) => void
}

export const ActivityContainer: React.FC<ActivityContainerProps> = ({
  id,
  text,
  createdBy,
  repliedComment,
  createdAt,
  lastEditedAt,
  deleted,
  pin,
  mentionedUsersMap,
  pinsMap,
  onDeleteClick,
  onEditClick,
  onReplyClick,
  onPinClick
}) => {
  return (
    <Activity
      id={id}
      text={addPinToText(text, pin)}
      createdBy={createdBy}
      repliedComment={
        repliedComment && {
          id: repliedComment.id,
          text: addPinToText(repliedComment.text, repliedComment.pin),
          createdByName: repliedComment.createdByName,
          deleted: repliedComment.deleted,
        }
      }
      timestamp={createdAt}
      edited={!!lastEditedAt}
      deleted={deleted}
      mentionedUsersMap={mentionedUsersMap}
      pinMap={pinsMap}
      onDeleteClick={onDeleteClick}
      onEditClick={onEditClick}
      onReplyClick={onReplyClick}
      onPinClick={onPinClick}
    />
  )
}

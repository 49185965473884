import { Input } from '@mui/material'
import { ViewerBottomContent } from 'components/common'
import { entrTheme } from 'entrTheme'
import styled from 'styled-components'

export const IngredientViewerPanelContent = styled(ViewerBottomContent)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px'
})

export const IngredientViewerPanelInput = styled(Input)({
  display: 'inline-grid',
  padding: 0,
  height: '28px',
  width: 'min-content',
  minWidth: '98px',
  position: 'relative',
  '&::after': {
    content: 'attr(data-value) " "',
    gridArea: '1/1',
    width: 'auto',
    minMidth: '64px',
    visibility: 'hidden',
    whiteSpace: 'pre-wrap',
    padding: '4px',
    marginLeft: '20px'
  },
  '.MuiInput-input': {
    gridArea: '1/1',
    textAlign: 'right',
    padding: '0 4px',
    fontSize: '16px',
    lineHeight: '16px',
    width: 'auto',
    minMidth: '64px',
    height: '28px'
  },
  '.MuiTypography-root': {
    gridArea: '1/2',
    padding: '4px 4px 4px 0',
    fontSize: '16px',
    lineHeight: '16px',
    color: entrTheme.palette.tertiary.main
  }
})

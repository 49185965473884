import React from 'react'

import { Box } from '@mui/material'
import { useDocuments } from 'common/documentsHook'
import { BasicDocument } from 'models/Document'
import FilesUploadButton from '../../FilesUploadButton/FilesUploadButton'
import { LoadingAnimation } from '../../LoadingAnimation/LoadingAmination'
import { SnackbarContext } from '../../Snackbar/SnackbarContext'
import { DocumentsPanelLinearItem } from './DocumentsPanelLinearItem'
import { FilePreviewContext } from 'components/FilePreview/FilePreviewContext'

export interface DocumentsPanelProps {
  documents?: BasicDocument[]
  onUploadDocument: (
    file: File,
    progressListener: (progress: number | undefined) => void
  ) => Promise<void>
  onPreviewDocument: (document: BasicDocument) => Promise<string>
  onDownloadDocument: (document: BasicDocument) => Promise<void>
  onDeleteDocument: (document: BasicDocument) => Promise<void>
  refresh: () => void
  maxDocuments?: number
}

export const DocumentsPanelLinear: React.FC<DocumentsPanelProps> = ({
  documents,
  onUploadDocument,
  onPreviewDocument,
  onDownloadDocument,
  onDeleteDocument,
  refresh,
  maxDocuments
}) => {
  const {
    uploadDocumentsCallback,
    previewDocumentCallback,
    downloadDocumentCallback,
    deleteDocumentCallback
  } = useDocuments({
    onUploadDocument,
    onPreviewDocument,
    onDownloadDocument,
    onDeleteDocument,
    refresh
  })

  const { showWarning } = React.useContext(SnackbarContext)
  const { clearFilePreviewLeftTargetRef } = React.useContext(FilePreviewContext)

  const handleUploadDocuments = (files: File[]) => {
    if (
      maxDocuments &&
      (documents?.length || 0) + files.length > maxDocuments
    ) {
      showWarning(`Maximum documents of ${maxDocuments} is allowed`)
    } else {
      uploadDocumentsCallback(files)
    }
  }

  const handlePreviewDocument = (file: BasicDocument) => {
    previewDocumentCallback(file)
  }

  const handleDownloadDocument = (file: BasicDocument) => {
    downloadDocumentCallback(file)
  }

  const handleDeleteDocument = (file: BasicDocument | null) => {
    deleteDocumentCallback(file)
  }

  React.useEffect(() => {
    clearFilePreviewLeftTargetRef()
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <Box sx={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
        {documents === undefined ? (
          <LoadingAnimation text="Loading documents" />
        ) : (
          <>
            {/* Documents View */}
            {documents.map((file: BasicDocument) => (
              // Document Row
              <DocumentsPanelLinearItem
                key={file.name + ' - ' + file.lastModified}
                file={file}
                onDeleteDocument={handleDeleteDocument}
                onDownloadDocument={handleDownloadDocument}
                onPreviewDocument={handlePreviewDocument}
              />
            ))}
          </>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column-reverse' }}>
        <FilesUploadButton size="small" uploadFiles={handleUploadDocuments}>
          Upload Attachments
        </FilesUploadButton>
      </Box>
    </Box>
  )
}

import {
  EditorGroup,
  EditorGroupSeparator,
  EditorGroupTitle
} from '../../EditorPanelStyles'

interface GroupProps {
  title: string
  children: React.ReactNode
  lastSection?: boolean
}

export const EditorSection: React.FC<GroupProps> = ({
  title,
  children,
  lastSection = false
}) => {
  return (
    <>
      <EditorGroup style={{ paddingBottom: lastSection ? '10px' : '0' }}>
        <EditorGroupTitle>{title}</EditorGroupTitle>
        {children}
      </EditorGroup>
      {!lastSection && <EditorGroupSeparator />}
    </>
  )
}

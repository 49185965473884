import { Box, Typography } from '@mui/material'
import { MarkdownRenderer } from 'components/MarkdownRenderer/MarkdownRenderer'
import { BoxRowBasic } from 'components/common'
import React from 'react'

export interface StatementProps {
  id?: string
  statement?: string
  suggestedStatement?: string
  prefix?: string
}

export const Statement: React.FC<StatementProps> = ({
  id,
  statement,
  suggestedStatement,
  prefix
}) => {
  if (!(statement || suggestedStatement)) {
    return null
  }

  return (
    <Box width="100%" id={id}>
      <MarkdownRenderer
        markdownText={`${prefix || ''}<escape>${statement || suggestedStatement || ''}</escape>`}
      />
    </Box>
  )
}

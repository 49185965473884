import { LabelProofCommentApiResponse } from "services/apis/label_proofs/LabelProofApiResponse";
import { CreateProofCommentRequestData, UpdateProofCommentRequestData } from "state/labelproof/proofer/ProoferRequest";
import { StagedComment, StagedCommentReplyTo } from "state/labelproof/proofer/ProoferActivitiesSlice";
import { addPinToText, getMentionedUsersMap, getPinsMap } from "./helpers";
import { ActivityContainerProps } from "./components/Activity/ActivitiyContainer";


export const mapCommentReplyToStagedCommentReply = (replyTo?: LabelProofCommentApiResponse): StagedCommentReplyTo | undefined => {
    if (!replyTo) {
        return undefined
    }
    return {
        id: replyTo.id,
        text: addPinToText(replyTo.comment, replyTo.pin),
        mentionsMap: getMentionedUsersMap(replyTo.mentions),
        pinMap: getPinsMap([replyTo.pin]),
        pinId: replyTo.pin?.id
    }
}

export const mapCommentToStagedComment = (comment: LabelProofCommentApiResponse): StagedComment => {
    return {
        id: comment.id,
        text: comment.comment,
        replyTo: mapCommentReplyToStagedCommentReply(comment.replyTo),
        pin: comment.pin,
        mentionedUserIds: comment.mentions.map(
            (mention) => mention.mentionedUser.id
        )
    }
}

export const mapStagedCommentToCreateProofCommentRequestData = (stagedComment: StagedComment): CreateProofCommentRequestData => {
    return {
        comment: stagedComment.text,
        reply_to: stagedComment.replyTo?.id,
        pin: stagedComment.pin && {
            widthRatio: stagedComment.pin.widthRatio,
            heightRatio: stagedComment.pin.heightRatio
        },
        mentionedUserIds: stagedComment.mentionedUserIds
    }
}

export const mapStagedCommentToUpdateProofCommentRequestData = (stagedComment: StagedComment): UpdateProofCommentRequestData => {
    return {
        comment: stagedComment.text,
        pin: stagedComment.pin && {
            widthRatio: stagedComment.pin.widthRatio,
            heightRatio: stagedComment.pin.heightRatio
        },
        deleteOldPin: stagedComment.pin === undefined,
        mentionedUserIds: stagedComment.mentionedUserIds
    }
}

const mapCommentToActivityItem = (
    comment: LabelProofCommentApiResponse,
    currentUserId: string,
    onDeleteClick: (id: string) => void,
    onEditClick: (id: string) => void,
    onReplyClick: (id: string) => void,
    onPinClick: (id: string) => void
    ): ActivityContainerProps => {
    return {
        id: comment.id,
        text: comment.comment,
        createdBy: comment.createdBy && {
            id: comment.createdBy.id,
            name: comment.createdBy.firstName + ' ' + comment.createdBy.lastName,
            isOwner: comment.createdBy.id === currentUserId,
            avatarSrc: comment.createdBy.imagePath
        },
        repliedComment: comment.replyTo && {
            id: comment.replyTo.id,
            text: comment.replyTo.comment,
            createdByName: comment.replyTo.createdBy && comment.replyTo.createdBy.firstName + ' ' + comment.replyTo.createdBy.lastName,
            deleted: comment.replyTo.isDeleted,
            pin: comment.replyTo.pin
        },
        createdAt: comment.createdAt,
        lastEditedAt: comment.lastEditedAt,
        deleted: comment.isDeleted,
        pin: comment.pin && {
            id: comment.pin.id,
            widthRatio: comment.pin.widthRatio,
            heightRatio: comment.pin.heightRatio
        },
        mentionedUsersMap: getMentionedUsersMap([
            ...comment.mentions,
            ...(comment.replyTo?.mentions || [])
        ]),
        pinsMap: getPinsMap([comment.pin, comment.replyTo?.pin]),
        onDeleteClick,
        onEditClick,
        onReplyClick,
        onPinClick
    }
}

export const mapCommentsToActivityItems = (
    comments: LabelProofCommentApiResponse[],
    currentUserId: string,
    onDeleteClick: (id: string) => void,
    onEditClick: (id: string) => void,
    onReplyClick: (id: string) => void,
    onPinClick: (id: string) => void
): ActivityContainerProps[] => {
    return comments.map((comment) => mapCommentToActivityItem(
        comment,
        currentUserId,
        onDeleteClick,
        onEditClick,
        onReplyClick,
        onPinClick
    ))
}


import styled from 'styled-components'
import {
  FlexContainerRowSpaced,
  NutritionLabelBox
} from '../components/NutritionLabelStyles'

export const NutritionLabelVerticalBox = styled(NutritionLabelBox)`
  display: table-caption;
`

export const AmountPerServingContainer = styled(FlexContainerRowSpaced)`
  align-items: flex-end;
`

export const PercentDailyValueContainer = styled(FlexContainerRowSpaced)`
  align-items: center;
  flex-direction: row;
`

export const FootNoteContainer = styled.div`
  flex-direction: row;
  display: flex;
`

export const NutritionFactsHeaderContainer = styled.div`
  box-sizing: border-box;
  margin-bottom: 5px;
`

import {
  Fade,
  InputAdornment,
  Popper,
  PopperProps,
  SxProps,
  Typography
} from '@mui/material'
import { BoxColumnBasic } from 'components/common'
import { TextField } from 'components/InputFields/TextField'
import { ReactComponent as SearchIcon } from 'assets/Search.svg'

import React from 'react'
import { EntrTable, EntrTableProps, IId } from 'components/EntrTable/EntrTable'

export interface SearchProps {
  value: string
  onChange: (value: string) => void
}

export interface SearchableTablePopperProps<K extends IId> {
  title?: string
  search: SearchProps
  tableProps: EntrTableProps<K>
  popperProps: PopperProps
  sx?: SxProps
  tableSx?: SxProps
}

export const SearchableTablePopper = <K extends IId>({
  title,
  search,
  tableProps,
  popperProps,
  sx,
  tableSx
}: SearchableTablePopperProps<K>) => {
  const { headerSx, bodySx, ...rest } = tableProps

  return (
    <Popper
      placement="bottom-start"
      {...popperProps}
      transition
      sx={{ zIndex: 3 }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <BoxColumnBasic
            sx={{
              maxWidth: '20vw',
              minWidth: '400px',
              maxHeight: '50vh',
              borderRadius: '12px',
              background: 'white',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              ...sx
            }}
          >
            <BoxColumnBasic sx={{ gap: '18px', padding: '16px' }}>
              {title && (
                <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                  {title}
                </Typography>
              )}
              <TextField
                value={search.value}
                onChange={search.onChange}
                startAdornment={
                  <InputAdornment
                    position="start"
                    sx={{ marginLeft: '0.5rem' }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                }
                debounceTime={300}
              />
            </BoxColumnBasic>
            <BoxColumnBasic
              sx={{
                background: '#FDFDFD',
                borderWidth: '1px 0px 1px 0px',
                borderStyle: 'solid',
                borderColor: '#0000000D',
                borderRadius: '0px 0px 12px 12px',
                paddingBottom: '16px'
              }}
            >
              <EntrTable
                sx={{ maxHeight: 'calc(50vh - 100px)', ...tableSx }}
                headerSx={{
                  borderBottom: '1px solid #233C580F',
                  background: '#FDFDFD',
                  '& .MuiBox-root': {
                    marginBottom: '0px',
                    padding: '16px 0px'
                  },
                  '& th:first-of-type': {
                    paddingLeft: '24px'
                  },
                  '& th:last-child': {
                    paddingRight: '24px'
                  },
                  ...headerSx
                }}
                bodySx={{
                  '& td:first-of-type': {
                    borderTopLeftRadius: '0px',
                    borderBottomLeftRadius: '0px',
                    paddingLeft: '24px'
                  },
                  '& td:last-child': {
                    borderTopRightRadius: '0px',
                    borderBottomRightRadius: '0px',
                    paddingRight: '24px'
                  },
                  ...bodySx
                }}
                {...rest}
              />
            </BoxColumnBasic>
          </BoxColumnBasic>
        </Fade>
      )}
    </Popper>
  )
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  AddFormulaIngredientRequest,
  GetFormulaIngredientsRequest,
  GetIngredientConversionsRequest,
  RemoveFormulaIngredientsRequest,
  ScaleFormulaRequest,
  UpdateFormulaIngredientActiveRequest,
  UpdateFormulaIngredientAmountRequest,
  UpdateFormulaIngredientMeasurementRequest,
  UpdateFormulaIngredientStatementFormatRequest,
  UpdateFormulaIngredientSupplierRequest,
  UpdateFormulaIngredientWastePercentageRequest
} from './FormulatorIngredientsRequest'
import { FormulaIngredient } from 'models/FormulaIngredient'
import {
  AddingState,
  LoadingState,
  RemovingState,
  UpdatingState
} from '../../CommonState'
import { FormulaApi } from 'services/apis/formula/FormulaApi'
import { ConversionsApi } from 'services/apis/conversions/ConversionApi'
import { RootState } from '../../store'
import { fromSubFormulaStatementFormat } from 'services/apis/formula/FormulaApiMapper'

export interface FormulatorIngredientsState
  extends LoadingState,
  UpdatingState,
  AddingState,
  RemovingState {
  formulaIngredients?: FormulaIngredient[]
  totalWeight: number
}

const initialState: FormulatorIngredientsState = {
  loading: false,
  updating: false,
  adding: false,
  removing: false,
  error: false,
  formulaIngredients: undefined,
  totalWeight: 0
}

export const getFormulaIngredients = createAsyncThunk(
  'formulas/ingredients/all/get',
  async ({ companyId, formulaId }: GetFormulaIngredientsRequest) => {
    return await FormulaApi.getIngredients(companyId, formulaId)
  }
)

export const getFormulaIngredientMeasurements = createAsyncThunk(
  'formulas/ingredients/measurements/get',
  async ({ companyId, ingredientId }: GetIngredientConversionsRequest) => {
    return await ConversionsApi.getConversions(companyId, ingredientId).then(
      (res) => {
        return [
          ...res.conversions.map((c) => c.measurement),
          ...res.suggestedConversions.map((sc) => sc.measurement)
        ]
      }
    )
  }
)

export const scaleFormula = createAsyncThunk(
  'formulas/scale',
  async ({ companyId, formulaId, scale }: ScaleFormulaRequest) => {
    return await FormulaApi.scaleFormula(companyId, formulaId, { scale }).then(
      () => {
        return scale
      }
    )
  }
)

export const updateFormulaIngredientMeasurement = createAsyncThunk(
  'formulas/ingredients/measurements/update',
  async (
    {
      companyId,
      formulaId,
      formulaIngredientId,
      measurementId
    }: UpdateFormulaIngredientMeasurementRequest,
    { getState }
  ) => {
    const state = getState() as RootState
    const amountInMeasurement =
      state.formulatorIngredients.formulaIngredients?.find(
        (v) => v.id === formulaIngredientId
      )?.amountInMeasurement

    return await FormulaApi.updateIngredient(
      companyId,
      formulaId,
      formulaIngredientId,
      {
        measurementId: measurementId,
        amountInMeasurement: amountInMeasurement
      }
    )
  }
)

export const addIngredientToFormula = createAsyncThunk(
  'formulas/ingredients/add',
  async ({
    companyId,
    formulaId,
    supplierId,
    ingredientId,
    amount = 0,
    wastePercentage = 0
  }: AddFormulaIngredientRequest) => {
    return await FormulaApi.addIngredient(companyId, formulaId, {
      supplierId,
      ingredientId,
      amount,
      wastePercentage
    })
  }
)

export const updateIngredientAmount = createAsyncThunk(
  'formulas/ingredients/amount/update',
  async ({
    companyId,
    formulaId,
    formulaIngredientId,
    amountInMeasurement,
    measurementId
  }: UpdateFormulaIngredientAmountRequest) => {
    return await FormulaApi.updateIngredient(
      companyId,
      formulaId,
      formulaIngredientId,
      { amountInMeasurement, measurementId }
    )
  },
  {
    getPendingMeta: (action) => {
      return {
        requestId: action.requestId,
        sequentialThunk: true,
        arguments: [
          action.arg.companyId,
          action.arg.formulaId,
          action.arg.formulaIngredientId
        ]
      }
    }
  }
)

export const updateFormulaIngredientActive = createAsyncThunk(
  'formulas/ingredients/active/update',
  async ({
    companyId,
    formulaId,
    formulaIngredientId,
    isActive
  }: UpdateFormulaIngredientActiveRequest) => {
    return await FormulaApi.updateIngredient(
      companyId,
      formulaId,
      formulaIngredientId,
      { isActive }
    )
  },
  {
    getPendingMeta: (action) => {
      return {
        requestId: action.requestId,
        sequentialThunk: true,
        arguments: [
          action.arg.companyId,
          action.arg.formulaId,
          action.arg.formulaIngredientId
        ]
      }
    }
  }
)

export const updateIngredientWastePercentage = createAsyncThunk(
  'formulas/ingredients/wastePercentage/update',
  async ({
    companyId,
    formulaId,
    formulaIngredientId,
    wastePercentage
  }: UpdateFormulaIngredientWastePercentageRequest) => {
    return await FormulaApi.updateIngredient(
      companyId,
      formulaId,
      formulaIngredientId,
      { wastePercentage }
    )
  },
  {
    getPendingMeta: (action) => {
      return {
        requestId: action.requestId,
        sequentialThunk: true,
        arguments: [
          action.arg.companyId,
          action.arg.formulaId,
          action.arg.formulaIngredientId
        ]
      }
    }
  }
)

export const updateFormulaIngredientSupplier = createAsyncThunk(
  'formulas/ingredients/supplier/update',
  async ({
    companyId,
    formulaId,
    formulaIngredientId,
    supplierId
  }: UpdateFormulaIngredientSupplierRequest) => {
    return await FormulaApi.updateIngredient(
      companyId,
      formulaId,
      formulaIngredientId,
      { supplierId }
    )
  }
)

export const updateFormulaIngredientStatementFormat = createAsyncThunk(
  'formulas/ingredients/statementFormat/update',
  async ({
    companyId,
    formulaId,
    formulaIngredientId,
    formatInStatement
  }: UpdateFormulaIngredientStatementFormatRequest) => {
    return await FormulaApi.updateIngredient(
      companyId,
      formulaId,
      formulaIngredientId,
      { formatInStatement: fromSubFormulaStatementFormat(formatInStatement) }
    )
  }
)

export const removeFormulaIngredients = createAsyncThunk(
  'formulas/ingredients/remove',
  async ({
    companyId,
    formulaId,
    formulaIngredientIds = []
  }: RemoveFormulaIngredientsRequest) => {
    return await FormulaApi.deleteIngredients(
      companyId,
      formulaId,
      formulaIngredientIds
    )
  }
)

const calculateTotalWeight = (
  formulaIngredients: FormulaIngredient[]
): number => {
  return Number(
    formulaIngredients
      .reduce(
        (sum, formulaIngredient) =>
          sum +
          formulaIngredient.amount * (1 - formulaIngredient.wastePercentage),
        0
      )
      .toFixed(6)
  )
}

const calculateIngredientPercentages = (
  formulaIngredients: FormulaIngredient[]
): FormulaIngredient[] => {
  const totalWeight = calculateTotalWeight(formulaIngredients)

  if (totalWeight === 0) {
    formulaIngredients.forEach((ingredient) => {
      ingredient.amountInPercentage = 0
    })
    return formulaIngredients
  }

  const exactPercentages = formulaIngredients.map(
    (ingredient) =>
      ((ingredient.amount * (1 - ingredient.wastePercentage)) / totalWeight) *
      10000
  )

  const roundedPercentages = exactPercentages.map((percentage) =>
    Math.round(percentage)
  )

  let error = 10000 - roundedPercentages.reduce((a, b) => a + b, 0)

  // Create a list of the part after the second decimal and their indexes.
  const sortedRemainingParts = exactPercentages
    .map((percentage, index) => ({
      decimal: percentage - Math.floor(percentage),
      index: index
    }))
    .sort((a, b) => b.decimal - a.decimal)

  // Distribute the error.
  for (let i = 0; i < Math.abs(error); i++) {
    const index = sortedRemainingParts[i % sortedRemainingParts.length].index
    if (
      (roundedPercentages[index] > 0 || error > 0) &&
      roundedPercentages[index] < 10000
    ) {
      roundedPercentages[index] += error > 0 ? 1 : -1
      error += error > 0 ? -1 : 1
    }
  }

  formulaIngredients.forEach((ingredient, index) => {
    ingredient.amountInPercentage = roundedPercentages[index] / 100
  })

  return formulaIngredients
}

const formulatorIngredientsSlice = createSlice({
  name: 'formulatorIngredientsSlice',
  initialState,
  reducers: {
    resetState: (state) => {
      Object.assign(state, initialState)
    }
  },
  extraReducers(builder) {
    // Get ingredients of formula.
    builder.addCase(getFormulaIngredients.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(getFormulaIngredients.fulfilled, (state, action) => {
      state.formulaIngredients = calculateIngredientPercentages(action.payload)
      state.totalWeight = calculateTotalWeight(action.payload)
      state.loading = false
    })
    builder.addCase(getFormulaIngredients.rejected, (state) => {
      state.loading = false
      state.error = true
    })

    // Add ingredient to formula.
    builder.addCase(addIngredientToFormula.pending, (state) => {
      state.adding = true
      state.error = false
    })
    builder.addCase(addIngredientToFormula.fulfilled, (state, action) => {
      if (state.formulaIngredients) {
        state.formulaIngredients = [...state.formulaIngredients, action.payload]
        state.adding = false
      }
    })
    builder.addCase(addIngredientToFormula.rejected, (state) => {
      state.adding = false
      state.error = true
    })

    // Remove ingredient from formula.
    builder.addCase(removeFormulaIngredients.pending, (state) => {
      state.removing = true
      state.error = false
    })
    builder.addCase(removeFormulaIngredients.fulfilled, (state, action) => {
      if (state.formulaIngredients) {
        const removedFormulaIngredientIds = action.payload
        const remaining = state.formulaIngredients.filter(
          (formulaIngredient) =>
            !removedFormulaIngredientIds.includes(formulaIngredient.id)
        )
        state.formulaIngredients = remaining
        state.formulaIngredients = calculateIngredientPercentages(
          state.formulaIngredients
        )
        state.totalWeight = calculateTotalWeight(state.formulaIngredients)
        state.removing = false
      }
    })
    builder.addCase(removeFormulaIngredients.rejected, (state) => {
      state.removing = false
      state.error = true
    })

    // Update ingredient amount.
    builder.addCase(updateIngredientAmount.pending, (state) => {
      state.updating = true
      state.error = false
    })
    builder.addCase(updateIngredientAmount.fulfilled, (state, action) => {
      if (state.formulaIngredients) {
        const updatedIngredient: FormulaIngredient = action.payload
        const ingredientIndex = state.formulaIngredients.findIndex(
          (ingredient) => ingredient.id === updatedIngredient.id
        )

        state.formulaIngredients[ingredientIndex].amount =
          updatedIngredient.amount
        state.formulaIngredients[ingredientIndex].amountInMeasurement =
          updatedIngredient.amountInMeasurement
        state.formulaIngredients[ingredientIndex].totalCostForGrams =
          updatedIngredient.totalCostForGrams

        state.formulaIngredients = calculateIngredientPercentages(
          state.formulaIngredients
        )
        state.totalWeight = calculateTotalWeight(state.formulaIngredients)
        state.updating = false
      }
    })
    builder.addCase(updateIngredientAmount.rejected, (state) => {
      state.updating = false
      state.error = true
    })

    // Update ingredient waste percentage.
    builder.addCase(updateIngredientWastePercentage.pending, (state) => {
      state.updating = true
      state.error = false
    })
    builder.addCase(
      updateIngredientWastePercentage.fulfilled,
      (state, action) => {
        if (state.formulaIngredients) {
          const updatedIngredient: FormulaIngredient = action.payload
          const ingredientIndex = state.formulaIngredients.findIndex(
            (ingredient) => ingredient.id === updatedIngredient.id
          )

          state.formulaIngredients[ingredientIndex].wastePercentage =
            updatedIngredient.wastePercentage
          state.formulaIngredients = calculateIngredientPercentages(
            state.formulaIngredients
          )
          state.totalWeight = calculateTotalWeight(state.formulaIngredients)
          state.updating = false
        }
      }
    )
    builder.addCase(updateIngredientWastePercentage.rejected, (state) => {
      state.updating = false
      state.error = true
    })

    // Update ingredient active.
    builder.addCase(updateFormulaIngredientActive.pending, (state) => {
      state.updating = true
      state.error = false
    })
    builder.addCase(
      updateFormulaIngredientActive.fulfilled,
      (state, action) => {
        if (state.formulaIngredients) {
          const updatedIngredient: FormulaIngredient = action.payload
          const ingredientIndex = state.formulaIngredients.findIndex(
            (ingredient) => ingredient.id === updatedIngredient.id
          )
          state.formulaIngredients[ingredientIndex].isActive =
            updatedIngredient.isActive
          state.updating = false
        }
      }
    )
    builder.addCase(updateFormulaIngredientActive.rejected, (state) => {
      state.updating = false
      state.error = true
    })

    // Update ingredient measurement.
    builder.addCase(updateFormulaIngredientMeasurement.pending, (state) => {
      state.updating = true
      state.error = false
    })
    builder.addCase(
      updateFormulaIngredientMeasurement.fulfilled,
      (state, action) => {
        if (state.formulaIngredients) {
          const updatedIngredient: FormulaIngredient = action.payload
          const ingredientIndex = state.formulaIngredients.findIndex(
            (ingredient) => ingredient.id === updatedIngredient.id
          )

          state.formulaIngredients[ingredientIndex].measurement = {
            ...updatedIngredient.measurement
          }
          state.formulaIngredients[ingredientIndex].amount =
            updatedIngredient.amount
          state.formulaIngredients[ingredientIndex].amountInMeasurement =
            updatedIngredient.amountInMeasurement

          state.formulaIngredients = calculateIngredientPercentages(
            state.formulaIngredients
          )
          state.totalWeight = calculateTotalWeight(state.formulaIngredients)
          state.updating = false
        }
      }
    )
    builder.addCase(updateFormulaIngredientMeasurement.rejected, (state) => {
      state.updating = false
      state.error = true
    })

    // Update formula supplier ingredient.
    builder.addCase(updateFormulaIngredientSupplier.pending, (state) => {
      state.updating = true
      state.error = false
    })
    builder.addCase(
      updateFormulaIngredientSupplier.fulfilled,
      (state, action) => {
        if (state.formulaIngredients) {
          const updatedIngredient = action.payload
          const ingredientIndex = state.formulaIngredients.findIndex(
            (ingredient) => ingredient.id === updatedIngredient.id
          )
          state.formulaIngredients[ingredientIndex].id = updatedIngredient.id

          state.formulaIngredients[ingredientIndex].supplier =
            updatedIngredient.supplier

          state.updating = false
        }
      }
    )
    builder.addCase(updateFormulaIngredientSupplier.rejected, (state) => {
      state.updating = false
      state.error = true
    })

    // Update formula statement Format.
    builder.addCase(updateFormulaIngredientStatementFormat.pending, (state) => {
      state.updating = true
      state.error = false
    })
    builder.addCase(
      updateFormulaIngredientStatementFormat.fulfilled,
      (state, action) => {
        state.updating = false
      }
    )
    builder.addCase(updateFormulaIngredientStatementFormat.rejected, (state) => {
      state.updating = false
      state.error = true
    })
  }
})

export const { resetState } = formulatorIngredientsSlice.actions
export default formulatorIngredientsSlice.reducer

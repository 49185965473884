import { Box } from '@mui/material'
import { SideBarLayout } from 'components/SideBarLayout/SideBarLayout'
import { TopBarLayout } from 'components/TopBarLayout/TopBarLayout'
import React from 'react'
import { TopBarContainer } from './components/TopBar/TopBarContainer'
import { DesignContainer } from './components/Design/DesignContainer'
import { PanelContainer } from './components/Panel/PanelContainer'

export const LabelProofer: React.FC = () => {
  return (
    <Box>
      <TopBarLayout topBar={<TopBarContainer />}>
        <SideBarLayout sidebar={<PanelContainer />} alwaysOpen={false}>
          <DesignContainer />
        </SideBarLayout>
      </TopBarLayout>
    </Box>
  )
}

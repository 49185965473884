import React from 'react'
import { NutrientType } from 'models/Nutrient'
import {
  ItemRowSettings,
  ItemRowValues
} from 'components/NutritionLabel/components/ItemRow'
import { NutritionLabelText } from 'components/NutritionLabel/components/NutritionLabelText'
import { NutritionFactsRule } from 'components/NutritionLabel/components/NutritionFactsRule'
import { ItemRow } from './ItemRow'
import { POINT_TO_PIXEL_RATIO } from 'components/NutritionLabel/components/NutritionLabelStyles'

export interface TableContentProps {
  tableItemRowsProps: TableItemRowProps[]
}

export interface TableItemRowProps {
  nutrientType?: NutrientType
  itemRowValues: ItemRowValues
  numberOnly?: boolean
}

export const NUTRIENT_TYPE_ITEM_ROW_SETTINGS: Partial<
  Record<NutrientType, ItemRowSettings>
> = {
  [NutrientType.SATURATED_FAT]: {
    level: 1
  },
  [NutrientType.TRANS_FATTY_ACID]: {
    level: 1
  },
  [NutrientType.POLY_FAT]: {
    level: 1
  },
  [NutrientType.MONO_FAT]: {
    level: 1
  },
  [NutrientType.DIETARY_FIBER]: {
    level: 1
  },
  [NutrientType.SOLUBLE_FIBER]: {
    level: 2
  },
  [NutrientType.INSOLUBLE_FIBER]: {
    level: 2
  },
  [NutrientType.TOTAL_SUGARS]: {
    level: 1
  },
  [NutrientType.ADDED_SUGAR]: {
    level: 2
  },
  [NutrientType.SUGAR_ALCOHOL]: {
    level: 1
  }
}

const getTableItemRowSettings = (
  nutrientType?: NutrientType
): ItemRowSettings => {
  if (nutrientType) {
    return NUTRIENT_TYPE_ITEM_ROW_SETTINGS[nutrientType] || {}
  }
  return {}
}

const getTableItemRowOverride = (
  amount: string,
  unit: string,
  nutrientType?: NutrientType
): React.ReactNode => {
  const { level } = getTableItemRowSettings(nutrientType)
  switch (nutrientType) {
    case NutrientType.ADDED_SUGAR:
      return (
        <NutritionLabelText
          points={8}
          extraStyle={{
            paddingLeft: level ? `${level * 10 * POINT_TO_PIXEL_RATIO}px` : ''
          }}
        >{`Includes ${amount}${unit} Added Sugars`}</NutritionLabelText>
      )
    case NutrientType.TRANS_FATTY_ACID:
      return (
        <NutritionLabelText
          points={8}
          extraStyle={{
            paddingLeft: level ? `${level * 10 * POINT_TO_PIXEL_RATIO}px` : ''
          }}
        >
          <i>Trans </i>
          {`Fat ${amount}${unit}`}
        </NutritionLabelText>
      )
    case NutrientType.FOLATE_DFE:
      return (
        <NutritionLabelText points={8}>
          {`Folate ${amount}${unit} DFE`}
        </NutritionLabelText>
      )
  }
  return undefined
}

export const ItemTableContent: React.FC<TableContentProps> = ({
  tableItemRowsProps = []
}) => {
  return (
    <>
      {tableItemRowsProps.map((tableItemRowProps, index) => {
        const itemRowSettings = getTableItemRowSettings(
          tableItemRowProps.nutrientType
        )

        return (
          <React.Fragment key={`fragment-${index}`}>
            {index > 0 && (
              <NutritionFactsRule
                key={`separator-${tableItemRowProps.nutrientType}`}
                type="hairline"
                short={itemRowSettings.level}
                margin={2}
              />
            )}
            <ItemRow
              key={`item-row-${index}`}
              {...itemRowSettings}
              level={tableItemRowProps.numberOnly ? 0 : itemRowSettings.level}
              {...tableItemRowProps.itemRowValues}
              override={
                !tableItemRowProps.numberOnly
                  ? getTableItemRowOverride(
                      (tableItemRowProps.itemRowValues.amount as string) || '',
                      tableItemRowProps.itemRowValues.unit || '',
                      tableItemRowProps.nutrientType
                    )
                  : undefined
              }
            />
          </React.Fragment>
        )
      })}
    </>
  )
}

import { BoxColumnBasic } from 'components/common'
import { NutritionLabel } from 'components/NutritionLabel/NutritionLabel'
import React, { useEffect, useState } from 'react'

import { Box } from '@mui/material'
import { LoadingAnimation } from 'components/LoadingAnimation/LoadingAmination'
import {
  FormulaNutritionFact,
  FormulaNutritionFactLabelType
} from 'models/FormulaLabel'
import { RegulationName } from 'services/apis/regulation/RegulationApiResponse'
import {
  AllergensStatementProps,
  IngredientStatementProps,
  LabelDescriptionProps,
  Statements
} from './Statements/Statements'
import { SupplementFacts } from 'models/SupplementFacts'
import { SupplementFactsLabel } from 'components/NutritionLabel/SupplementFactsLabel'

export interface FactsLabelProps {
  facts?: FormulaNutritionFact | SupplementFacts
  ingredientStatement: IngredientStatementProps
  allergenStatement: AllergensStatementProps
  labelDescription: LabelDescriptionProps
  regulationName: RegulationName
  scale?: number
}

export const FactsLabel: React.FC<FactsLabelProps> = ({
  facts,
  ingredientStatement,
  allergenStatement,
  labelDescription,
  regulationName,
  scale = 1
}) => {
  const [width, setWidth] = useState(0)
  const nutritionLabelRef = React.useRef<HTMLDivElement>(null)

  const isSupplement = React.useMemo(() => {
    // Type guard in typescript to check the type of facts.
    const isSupplementFacts = (
      facts: FormulaNutritionFact | SupplementFacts
    ): facts is SupplementFacts => {
      return (facts as SupplementFacts).activeIngredients !== undefined
    }

    if (!facts) {
      return false
    }
    return isSupplementFacts(facts)
  }, [facts])

  // Function to recompute width considering the scale
  const computeWidth = () => {
    if (nutritionLabelRef.current) {
      const newWidth = nutritionLabelRef.current.getBoundingClientRect().width
      setWidth(newWidth / scale) // Adjust width by scale
    }
  }

  // Use ResizeObserver to monitor changes in the element size
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === nutritionLabelRef.current) {
          computeWidth()
        }
      }
    })

    if (nutritionLabelRef.current) {
      observer.observe(nutritionLabelRef.current)
    }

    return () => {
      if (nutritionLabelRef.current) {
        observer.unobserve(nutritionLabelRef.current)
      }
    }
  }, [scale])

  // Effect to recompute width when nutritionFactsLabel changes
  useEffect(() => {
    computeWidth()
  }, [facts, scale])

  return (
    <BoxColumnBasic
      display="flex"
      m="8px"
      gap="8px"
      overflow="scroll"
      alignItems={
        facts?.type === FormulaNutritionFactLabelType.TABULAR
          ? 'flex-start'
          : 'center'
      }
      flexGrow={1}
      position="relative"
    >
      {!facts && !ingredientStatement && (
        <LoadingAnimation text="Loading label" />
      )}

      {!!facts && !!ingredientStatement && (
        <BoxColumnBasic
          id="nutrition-fact-label"
          sx={{
            alignItems: 'left',
            gap: '20px',
            padding: '10px'
          }}
        >
          <Box
            ref={nutritionLabelRef}
            width={'fit-content'}
            id="nutrition-facts"
          >
            {isSupplement ? (
              <SupplementFactsLabel
                {...(facts as SupplementFacts)}
                servingWeight={String(facts.servingWeight)}
                selectedNutrients={facts.optionalNutrientsType}
              />
            ) : (
              <NutritionLabel {...facts} regulationName={regulationName} />
            )}
          </Box>
          <BoxColumnBasic
            gap="10px"
            sx={{ maxWidth: `${width}px`, width: '100%' }}
          >
            <Statements
              allergensStatement={allergenStatement}
              ingredientsStatement={ingredientStatement}
              labelDescription={labelDescription}
            />
          </BoxColumnBasic>
        </BoxColumnBasic>
      )}
    </BoxColumnBasic>
  )
}

import { Box } from '@mui/material'
import { BoxColumnBasic } from 'components/common'
import { Tab } from 'components/Tabs/Tab/Tab'
import { TabPanel } from 'components/Tabs/TabPanel/TabPanel'
import { Tabs } from 'components/Tabs/Tabs'
import React from 'react'

export interface PanelProps {
  reviewTabContent: React.ReactNode
  activityTabContent: React.ReactNode
  labelTabContent: React.ReactNode
}

export const Panel: React.FC<PanelProps> = ({
  reviewTabContent,
  activityTabContent: commentsTabContent,
  labelTabContent
}) => {
  const [activeTab, setActiveTab] = React.useState(0)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  return (
    <>
      <Box height="100%">
        <BoxColumnBasic height="100%" width="100%" minWidth="36.8vw">
          <Tabs
            sx={{
              padding: '20px',
              alignItems: 'center',
              overflow: 'hidden',
              boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08)',
              flex: '0 0 auto',
              zIndex: '1',
              borderBottom: '2px solid rgba(0, 0, 0, 0.06)'
            }}
            value={activeTab}
            onChange={handleTabChange}
          >
            <Tab label="Review" />
            <Tab label="Activity" />
            <Tab label="Label" />
          </Tabs>
          <Box flex="1 1 auto" zIndex={1} overflow="hidden">
            <TabPanel value={activeTab} index={0}>
              {reviewTabContent}
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              {commentsTabContent}
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              {labelTabContent}
            </TabPanel>
          </Box>
        </BoxColumnBasic>
      </Box>
    </>
  )
}

import { SimpleTagsPaginated } from 'models/Tags'
import { api } from '../config/AxiosConfig'
import { toSimpleTagsPaginated } from './TagsApiMapper'
import { SimpleTagApiPaginatedResponse } from './TagsApiResponse'
import { GetTagsApiParams } from './TagsApiRequest'

export const TagsApi = {
  getTags: (
    companyId: string,
    params: GetTagsApiParams
  ): Promise<SimpleTagsPaginated> => {
    return api
      .get<SimpleTagApiPaginatedResponse>(`/companies/${companyId}/tags`, {
        params,
        paramsSerializer: {
          indexes: null
        }
      })
      .then((res) => toSimpleTagsPaginated(res.data))
  }
}

import { Typography } from '@mui/material'
import styled from 'styled-components'

export const FormulaTypography = styled(Typography).attrs({
  variant: 'subtitle1'
})``

export const TitleTypography = styled(Typography).attrs({
  variant: 'h4'
})``

export const FooterTypography = styled(Typography).attrs({
  variant: 'subtitle2'
})``

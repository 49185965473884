import { EntrTableFiltersV2 } from 'components/EntrTable/EntrTableFiltersV2'
import { QueriesContext } from 'components/EntrTable/Filter/QueriesContext'
import React from 'react'
import { useAppSelector } from 'state/hooks'
import {
  FilterSearchResponse,
  useFilter
} from '../../../../components/EntrTable/Filter/FiltersHook'
import { LabelProofsApi } from 'services/apis/label_proofs/LabelProofsApi'
import { getOptionsFromFilterApiResponse } from 'components/EntrTable/Filter/helpers'

export const SearchFiltersContainer: React.FC = () => {
  const { setSearch, setFilters, filters, search } =
    React.useContext(QueriesContext)
  const { createdBy } = filters
  const companyId = useAppSelector((state) => state.companies.currentCompany.id)

  // Search bar.
  const handleSearchUpdate = React.useCallback(
    (value: string) => {
      setSearch(value)
    },
    [setSearch]
  )

  const handleCreatedBySearchChange = React.useCallback(
    (search?: string, page = 1): Promise<FilterSearchResponse> => {
      return LabelProofsApi.getLabelProofCreatedByFilter(companyId, {
        searchTerm: search,
        page: page,
        selectedUserIds: createdBy
      }).then((createdByFilter) => {
        return getOptionsFromFilterApiResponse(
          createdByFilter,
          (responseItem) => {
            return {
              label: responseItem.firstName + ' ' + responseItem.lastName,
              value: false,
              id: responseItem.id
            }
          },
          createdBy ?? [],
          Boolean(search)
        )
      })
    },
    [createdBy, companyId]
  )

  const createdByFilter = useFilter({
    name: 'Created By',
    onSearchChange: handleCreatedBySearchChange,
    localSearch: false,
    onChange: (values) => setFilters('createdBy', values)
  })

  return (
    <EntrTableFiltersV2
      search={{
        placeholder: 'Search all label proofs',
        onUpdate: handleSearchUpdate,
        initialSearch: search
      }}
      filters={[createdByFilter]}
    />
  )
}

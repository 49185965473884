import { useAppDispatch, useAppSelector } from 'state/hooks'
import { TotalsBar } from './TotalsBar'
import { Typography } from '@mui/material'
import {
  getFormulaIngredients,
  scaleFormula
} from 'state/formulator/ingredients/FormulatorIngredientsSlice'
import { ModalContext } from 'components/Modal/ModalContext'
import React from 'react'
import {
  getFormulaTotalCost,
  updateFormulaServingWeight
} from 'state/formulator/FormulatorSlice'
import { getExecutedCompanyTargets } from 'state/formulator/formulaTargets/FormulaTargetsSlice'
import { getFormulaHistory } from 'state/formulator/history/FormulaHistorySlice'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'
import { FormulatorContext } from '../../FormulatorProvider'

export const TotalsBarContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const formulaIngredients =
    useAppSelector((state) => state.formulatorIngredients.formulaIngredients) ||
    []
  const totalWeight = useAppSelector(
    (state) => state.formulatorIngredients.totalWeight
  )
  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )

  const formulaId = useAppSelector((state) => state.formulator.formula.id)
  const servingWeight = useAppSelector(
    (state) => state.formulator.formula.servingWeight
  )
  const formulaCost = useAppSelector((state) => state.formulator.formula.cost)
  const { formulatorAnalytics } = React.useContext(AnalyticsContext)

  const { showConfirmationModal } = React.useContext(ModalContext)
  const { viewOptions } = React.useContext(FormulatorContext)

  React.useEffect(() => {
    if (formulaId && formulaIngredients.length > 0) {
      void dispatch(
        getFormulaTotalCost({
          companyId: currentCompany.id,
          formulaId: formulaId
        })
      )
    }
  }, [formulaId, currentCompany, servingWeight, formulaIngredients])

  const handleScaleFormula = React.useCallback(
    (newTotal: number) => {
      formulatorAnalytics.ingredients.scaledIngredients(formulaId)
      showConfirmationModal({
        title: 'Scaling Formula',
        message: (
          <Typography>
            Are you sure you want to scale formula to <b>{newTotal}g</b>? This
            will modify all its ingredient amounts proportionally.
          </Typography>
        ),
        yesText: 'Scale',
        noText: 'Cancel',
        onYesClicked: () => {
          void dispatch(
            scaleFormula({
              companyId: currentCompany.id,
              formulaId: formulaId,
              scale: newTotal
            })
          ).then(() => {
            void dispatch(
              getFormulaIngredients({
                companyId: currentCompany.id,
                formulaId: formulaId
              })
            )
          })
        }
      })
    },
    [currentCompany.id, formulaId]
  )

  const costingVisibile: boolean = React.useMemo(() => {
    // Search within viewOptions array for the view option with id as cost.
    return viewOptions.find((option) => option.id === 'cost')?.value || false
  }, [viewOptions])

  const handleServingWeightChange = React.useCallback(
    (newServingWeight: number) => {
      formulatorAnalytics.label.modifiedServingWeight(formulaId)
      void dispatch(
        updateFormulaServingWeight({
          companyId: currentCompany.id,
          formulaId: formulaId,
          servingWeight: newServingWeight
        })
      ).then((action) => {
        if (updateFormulaServingWeight.fulfilled.match(action)) {
          void dispatch(
            getExecutedCompanyTargets({
              companyId: currentCompany.id,
              formulaId: formulaId
            })
          )
          void dispatch(
            getFormulaHistory({
              companyId: currentCompany.id,
              formulaId: formulaId
            })
          )
        }
      })
    },
    [currentCompany.id, formulaId]
  )

  return (
    <TotalsBar
      totalIngredients={formulaIngredients.length}
      servingWeight={{
        value: servingWeight || 0,
        onChange: handleServingWeightChange
      }}
      totalWeight={{
        value: totalWeight,
        onChange: handleScaleFormula
      }}
      cost={{
        totalCostPerServing: formulaCost.totalCostPerServing,
        totalCostPerWeight: formulaCost.totalCostPerWeight,
        visibile: costingVisibile
      }}
    />
  )
}

import { NameAvatarCell } from 'components/Cells/NameAvatarCell'
import { ColumnConfiguration } from 'components/EntrTable/EntrTable'
import { SearchableTablePopper } from 'components/SearchableTablePopper/SearchableTablePopper'
import React from 'react'

export interface AssignerTableRow {
  id: string
  name: string
  imageUrl?: string
}

export interface AssignerPopperProps {
  data: AssignerTableRow[]
  onRowClick: (id: string) => void
  open: boolean
  anchorEl: HTMLElement | null
}

export const AssignerPopper: React.FC<AssignerPopperProps> = ({
  data,
  onRowClick,
  open,
  anchorEl
}) => {
  const [searchValue, setSearchValue] = React.useState<string>('')
  const [filteredData, setFilteredData] = React.useState<AssignerTableRow[]>([])

  React.useEffect(() => {
    setFilteredData(
      data.filter((option) =>
        option.name.toLowerCase().includes(searchValue.toLowerCase())
      )
    )
  }, [searchValue, data])

  const columnConfigs: ColumnConfiguration<AssignerTableRow>[] = [
    {
      title: 'Name',
      fieldStyles: { width: '70%' },
      field: (option) => option.name,
      customRender: (option) => (
        <NameAvatarCell name={option.name} avatarSrc={option.imageUrl || ''} />
      )
    }
  ]

  return (
    <SearchableTablePopper
      title="Select a user to assign"
      search={{
        value: searchValue,
        onChange: setSearchValue
      }}
      tableProps={{
        data: filteredData,
        columnConfigs: columnConfigs,
        onRowClick: (row) => {
          onRowClick(row.id)
        },
        noDataMessage: 'No users found'
      }}
      popperProps={{
        open: open,
        anchorEl: anchorEl
      }}
      sx={{
        width: '300px',
        minWidth: '300px',
        maxHeight: '45vh'
      }}
      tableSx={{
        maxHeight: 'calc(40vh - 100px)'
      }}
    />
  )
}

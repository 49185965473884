import { ClearRounded } from '@mui/icons-material'
import { Input, MenuItem, TableCell, TableRow } from '@mui/material'
import { Button } from 'components/Button/Button'
import { BoxRowBasic, Option } from 'components/common'
import { Select } from 'components/Select/Select'
import { UnstyledInput } from 'pages/Formulator/FormulatorStyles'
import React from 'react'
import { YieldQuantityEditor } from './components/YieldQuantityEditor'
import { InfoBubble } from 'components/InfoBubble/InfoBubble'
import { MarkdownRenderer } from 'components/MarkdownRenderer/MarkdownRenderer'
import { infoMessages } from 'common/constants'

export interface YieldAdjustmentProps {
  adjustment: {
    id: string
    name: string
  }
  adjustmentTypeOptions: Option<boolean>[]
  adjustmentPercentage: number
  adjustmentAmount: number
  onChange: (
    amount?: number,
    percentage?: number,
    adjustmentTypeId?: string
  ) => void
  onRemove: () => void
}

export const YieldAdjustment: React.FC<YieldAdjustmentProps> = ({
  adjustment,
  adjustmentTypeOptions,
  adjustmentPercentage,
  adjustmentAmount,
  onChange,
  onRemove
}) => {
  return (
    <TableRow key={adjustment.id}>
      <TableCell>
        <Input
          fullWidth
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontFamily: 'Inter',
            fontSize: 16,
            '& .MuiInputBase-input': {
              padding: '0px'
            },
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#000000'
            }
          }}
          startAdornment={
            <BoxRowBasic
              sx={{
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '5px',
                color: '#000000',
              }}
            >
              {adjustment.name}
              <InfoBubble
                text={
                  <MarkdownRenderer
                    markdownText={infoMessages.formula.yieldAdjustments.MOISTURE}
                  />
                }
              />
            </BoxRowBasic>
          }
          disabled
        />
      </TableCell>
      <TableCell>
        <Select
          value={adjustmentTypeOptions.find((a) => a.value)?.id || ''}
          onChange={(adjustmentTypeId: string) =>
            onChange(undefined, undefined, adjustmentTypeId)
          }
          options={adjustmentTypeOptions.map((option) => ({
            value: option.id,
            label: option.label
          }))}
        ></Select>
      </TableCell>

      <TableCell>
        <YieldQuantityEditor
          amount={{
            value: adjustmentAmount,
            onUpdate: (amount: number) => onChange(amount, undefined, undefined)
          }}
          percentage={{
            value: adjustmentPercentage,
            onUpdate: (percentage: number) =>
              onChange(undefined, percentage, undefined)
          }}
        />
      </TableCell>

      <TableCell>
        <Button
          variant="circle"
          size="small"
          color="tertiary"
          onClick={onRemove}
        >
          <ClearRounded sx={{ width: '15px', height: '15px' }} />
        </Button>
      </TableCell>
    </TableRow>
  )
}

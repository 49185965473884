import { PATHS } from 'common/constants'
import BackgroundGlowLayout from 'components/BackgroundGlowLayout/BackgroundGlowLayout'
import NavBarLayout from 'components/NavBarLayout/NavBarLayout'
import PrivateRoutes from 'core/PrivateRoutes'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Activation } from './Activation/Activation'
import { CompanyCheck } from './CompanyCheck/CompanyCheck'
import { FormulasContainer } from './Formulas/FormulasContainer'
import { Formulator } from './Formulator/Formulator'
import { IngredientsContainer } from './Ingredients/IngredientsContainer'
import Login from './Login/Login'
import Company from './Settings/Company'
import Members from './Settings/Members'
import Profile from './Settings/Profile'
import Settings from './Settings/Settings'
import Signup from './Signup/Signup'
import { Suppliers } from './Suppliers/Suppliers'
import { Targets } from './Targets/Targets'
import { LabelProofsContainer } from './LabelProofs/LabelProofsContainer'
import { LabelProoferContainer } from './LabelProofs/LabelProofer/LabelProoferContainer'

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route element={<BackgroundGlowLayout />}>
          <Route element={<NavBarLayout />}>
            <Route
              path={PATHS.HOME}
              element={<Navigate to={PATHS.FORMULAS} replace />}
            />
            <Route path={PATHS.FORMULAS} element={<FormulasContainer />} />
            <Route
              path={PATHS.INGREDIENTS}
              element={<IngredientsContainer />}
            />
            <Route path={PATHS.SUPPLIERS} element={<Suppliers />} />
            <Route path={PATHS.TARGETS} element={<Targets />} />
            <Route
              path={PATHS.LABEL_PROOFS}
              element={<LabelProofsContainer />}
            />
            <Route path={PATHS.SETTINGS} element={<Settings />}>
              <Route path={PATHS.PROFILE} element={<Profile />} />
              <Route path={PATHS.COMPANY} element={<Company />} />
              <Route path={PATHS.MEMBERS} element={<Members />} />
            </Route>
          </Route>
        </Route>
        <Route path={PATHS.ACTIVATION} element={<Activation />} />
        <Route path={PATHS.NO_COMPANY} element={<CompanyCheck />} />
        <Route path={PATHS.EDIT_FORMULA} element={<Formulator />} />
        <Route path={PATHS.EDIT_PROOFS} element={<LabelProoferContainer />} />
      </Route>
      <Route element={<BackgroundGlowLayout />}>
        <Route path={PATHS.LOGIN} element={<Login />} />
        <Route path={PATHS.SIGNUP} element={<Signup />} />
      </Route>
    </Routes>
  )
}

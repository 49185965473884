import { Button } from 'components/Button/Button'
import {
  SavingStatus,
  SavingStatusIndicator
} from 'components/SavingStatusIndicator/SavingStatusIndicator'
import { BoxRow } from 'components/common'
import React from 'react'

export interface TopBarProps {
  onDuplicateClick: () => void
  onDeleteClick: () => void
  editable?: boolean
  saving: boolean
}

export const TopBar: React.FC<TopBarProps> = ({
  onDuplicateClick,
  onDeleteClick,
  saving,
  editable = true
}) => {
  return (
    <BoxRow gap="8px" justifyContent="flex-end">
      {editable && (
        <SavingStatusIndicator
          status={saving ? SavingStatus.SAVING : SavingStatus.SAVED}
        />
      )}

      <Button size="small" color="secondary" onClick={onDuplicateClick}>
        Duplicate
      </Button>

      {editable && (
        <Button size="small" color="danger" onClick={onDeleteClick}>
          Delete
        </Button>
      )}
    </BoxRow>
  )
}

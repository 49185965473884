import React from 'react'
import { SupplementFactsProps } from '../SupplementFactsLabel'
import { NutritionFactsRule } from '../components/NutritionFactsRule'
import {
  Container,
  FlexContainerColumn,
  FlexContainerRow
} from '../components/NutritionLabelStyles'
import { NutritionLabelText } from '../components/NutritionLabelText'
import {
  FootNoteContainer,
  NutritionFactsHeaderContainer,
  NutritionLabelVerticalBox
} from './SupplementLabelVerticalStyles'
import { ActiveIngredientsTable } from './components/ActiveIngredientsTable'
import { NutrientsTable } from './components/NutrientsTable'

export const SupplementFactsLabelVertical: React.FC<SupplementFactsProps> = ({
  servingSize,
  servingWeight,
  servingsPerContainer,
  nutrients,
  activeIngredients
}) => {
  return (
    <Container>
      <NutritionLabelVerticalBox>
        <NutritionFactsHeaderContainer>
          <NutritionLabelText bold points={25}>
            Supplement Facts
          </NutritionLabelText>
        </NutritionFactsHeaderContainer>

        <FlexContainerColumn>
          {Object.keys(servingSize).map((language) => (
            <NutritionLabelText key={language} points={8}>
              Serving Size {servingSize[language as keyof typeof servingSize]} (
              {servingWeight})
            </NutritionLabelText>
          ))}
          <NutritionLabelText points={8}>
            Servings Per Container {servingsPerContainer}
          </NutritionLabelText>
        </FlexContainerColumn>

        <NutritionFactsRule type="thick" margin={2} />

        <>
          <NutrientsTable nutrients={nutrients} />
          {nutrients.length > 0 && (
            <NutritionFactsRule type="thick" margin={2} />
          )}
        </>

        {activeIngredients.length > 0 && (
          <>
            <ActiveIngredientsTable activeIngredients={activeIngredients} />
            <NutritionFactsRule type="thick" margin={2} />
          </>
        )}

        <FootNoteContainer>
          <FlexContainerColumn>
            {nutrients.length > 0 && (
              <FlexContainerRow>
                <NutritionLabelText points={6}>*&nbsp; </NutritionLabelText>
                <NutritionLabelText points={6} wrapText={true}>
                  Percent Daily Values are based on a 2,000 calorie diet.
                </NutritionLabelText>
              </FlexContainerRow>
            )}
            {activeIngredients.length > 0 && (
              <FlexContainerRow>
                <NutritionLabelText points={6}>†&nbsp; </NutritionLabelText>
                <NutritionLabelText points={6} wrapText={true}>
                  Daily Value not established.
                </NutritionLabelText>
              </FlexContainerRow>
            )}
          </FlexContainerColumn>
        </FootNoteContainer>
      </NutritionLabelVerticalBox>
    </Container>
  )
}

import React from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { Label } from './Label'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'
import {
  getFactsAllergens,
  getFactsIngredientStatement,
  getFormula,
  getNutritionFacts,
  getSupplementFacts
} from 'state/labelproof/proofer/ProoferLabelSlice'
import { FormulaNutritionFactLanguage } from 'models/FormulaLabel'
import {
  AllergenDisplayNameLanguage,
  AllergensPerLanguage
} from 'models/Allergen'
import { getAvailableRegulations } from 'state/labels/nutritions/NutritionFactLabelsSlice'
import { RegulationName } from 'services/apis/regulation/RegulationApiResponse'

export const LabelContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const { labelProoferAnalytics } = React.useContext(AnalyticsContext)

  const companyId = useAppSelector((state) => state.companies.currentCompany.id)
  const proof = useAppSelector((state) => state.proofer.proof)

  const facts = useAppSelector((state) => state.prooferLabel.facts)
  const ingredientStatement = useAppSelector(
    (state) => state.prooferLabel.ingredientStatement
  )
  const allergenStatement = useAppSelector(
    (state) => state.prooferLabel.allergenStatement
  )
  const availableRegulations = useAppSelector(
    (state) => state.nutritionFactLabels.availableRegulations
  )
  const formula = useAppSelector((state) => state.prooferLabel.formula)
  const loadingFormula = useAppSelector(
    (state) => state.prooferLabel.loadingFormula
  )

  const handleOpenFormula = React.useCallback((formulaId: string) => {
    window.open(`/formulas/${formulaId}`, '_blank')
  }, [])

  React.useEffect(() => {
    if (!availableRegulations) {
      void dispatch(getAvailableRegulations())
    }
  }, [])

  React.useEffect(() => {
    if (proof && proof.formula.id != formula?.id) {
      void dispatch(
        getFormula({ companyId: companyId, formulaId: proof.formula.id })
      )
    }
  }, [companyId, proof?.formula.id])

  React.useEffect(() => {
    if (formula && proof) {
      labelProoferAnalytics.viewedLabelTab(proof.id)
      if (!formula.isSupplement) {
        void dispatch(
          getNutritionFacts({
            companyId: companyId,
            formulaId: formula.id
          })
        )
      } else {
        void dispatch(
          getSupplementFacts({
            companyId: companyId,
            formulaId: formula.id
          })
        )
      }
      void dispatch(
        getFactsIngredientStatement({
          companyId: companyId,
          formulaId: formula.id
        })
      )
      void dispatch(
        getFactsAllergens({
          companyId: companyId,
          formulaId: formula.id
        })
      )
    }
  }, [companyId, formula])

  const ingredientStatementStatement = React.useMemo(() => {
    return (
      facts?.ingredientStatement || {
        [FormulaNutritionFactLanguage.ENGLISH]: ''
      }
    )
  }, [facts?.ingredientStatement])

  const allergenStatementOverride = React.useMemo(() => {
    return (
      facts?.allergenStatement || {
        [FormulaNutritionFactLanguage.ENGLISH]: ''
      }
    )
  }, [facts?.allergenStatement])

  const suggestedAllergenStatement = React.useMemo(() => {
    return (
      facts?.suggestedAllergenStatement || {
        [FormulaNutritionFactLanguage.ENGLISH]: ''
      }
    )
  }, [facts?.suggestedAllergenStatement])

  const allergenStatementPrefix = React.useMemo(() => {
    return (
      facts?.allergenStatementPrefix || {
        [FormulaNutritionFactLanguage.ENGLISH]: 'Contains: ',
        [FormulaNutritionFactLanguage.FRENCH]: 'Contient: ',
        [FormulaNutritionFactLanguage.SPANISH]: 'Contiene: '
      }
    )
  }, [facts?.allergenStatementPrefix])

  const suggestedIngredientsStatement = React.useMemo(() => {
    return (
      ingredientStatement?.suggestedIngredientStatement || {
        [FormulaNutritionFactLanguage.ENGLISH]: ''
      }
    )
  }, [ingredientStatement?.suggestedIngredientStatement])

  const description = React.useMemo(() => {
    return (
      facts?.description || {
        [FormulaNutritionFactLanguage.ENGLISH]: ''
      }
    )
  }, [facts?.description])

  const allergens: AllergensPerLanguage = React.useMemo(() => {
    const getLanguageList = (
      language: AllergenDisplayNameLanguage
    ): string[] => {
      return allergenStatement
        ? allergenStatement.allergens
            .flatMap((a) => {
              if (a.displayNames && a.displayNames[language]) {
                return a.displayNames[language]
              }
              return []
            })
            .filter((name): name is string => name !== undefined)
        : []
    }

    return {
      [AllergenDisplayNameLanguage.ENGLISH]: getLanguageList(
        AllergenDisplayNameLanguage.ENGLISH
      ),
      [AllergenDisplayNameLanguage.FRENCH]: getLanguageList(
        AllergenDisplayNameLanguage.FRENCH
      ),
      [AllergenDisplayNameLanguage.SPANISH]: getLanguageList(
        AllergenDisplayNameLanguage.SPANISH
      )
    }
  }, [allergenStatement])

  const regulationName = React.useMemo(() => {
    return (
      (availableRegulations?.find((r) => r.id === facts?.regulationId)
        ?.name as RegulationName) || RegulationName.FDA
    )
  }, [facts?.regulationId])

  return (
    <Label
      formula={{
        id: formula?.id || '',
        name: formula?.name || '',
        onClick: handleOpenFormula,
        loading: loadingFormula
      }}
      factsLabel={{
        ingredientStatement: {
          statement: ingredientStatementStatement,
          suggestedStatement: suggestedIngredientsStatement,
          prefix: ingredientStatement?.ingredientStatementPrefix || {
            [FormulaNutritionFactLanguage.ENGLISH]: 'Ingredients: ',
            [FormulaNutritionFactLanguage.FRENCH]: 'Ingrédients: ',
            [FormulaNutritionFactLanguage.SPANISH]: 'Ingredientes: '
          }
        },
        allergenStatement: {
          allergens: allergens,
          statementsOverride: allergenStatementOverride,
          suggestedAllergenStatement: suggestedAllergenStatement,
          allergenStatementPrefix: allergenStatementPrefix
        },
        labelDescription: {
          description: description
        },
        facts: facts,
        regulationName: regulationName
      }}
    />
  )
}

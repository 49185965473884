import React from 'react'
import { Typography } from '@mui/material'
import { AvatarInitials } from '../AvatarInitials/AvatarInitials'
import { BoxRow } from '../common'

interface NameAvatarCellProps {
  name: string
  avatarSrc?: string
}

export const NameAvatarCell: React.FC<NameAvatarCellProps> = ({
  name,
  avatarSrc
}) => {
  return (
    <BoxRow alignItems="center" title={name}>
      <AvatarInitials variant="square" name={name} avatarSrc={avatarSrc} />
      <Typography
        sx={{
          marginLeft: '8px',
          fontWeight: 'bold',
          fontSize: '15px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: '100%'
        }}
      >
        {name}
      </Typography>
    </BoxRow>
  )
}

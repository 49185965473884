import { QueriesProvider } from 'components/EntrTable/Filter/QueriesContext'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'
import React from 'react'
import { IngredientsSearchFiltersContainer } from './components/IngredientsSearchFilters/IngredientsSearchFiltersContainer'
import { IngredientsTableContainer } from './components/IngredientsTable/IngredientsTableContainer'
import { IngredientsViewerContainer } from './components/IngredientViewer/IngredientViewerContainer'
import {
  PageViewerContext,
  PageViewerProvider
} from './components/PageViewerContext'
import { Ingredients } from './Ingredients'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  createSimpleIngredient,
  setSelectedSimpleIngredient
} from 'state/simple_ingredients/SimpleIngredientsSlice'

export const IngredientsContainer: React.FC = () => {
  return (
    <PageViewerProvider queryKey="ingredientId">
      <_IngredientsContainer />
    </PageViewerProvider>
  )
}

const _IngredientsContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const companyId = useAppSelector((state) => state.companies.currentCompany.id)
  const { sendHideRequest } = React.useContext(PageViewerContext)
  const { ingredientsAnalytics } = React.useContext(AnalyticsContext)

  React.useEffect(() => {
    ingredientsAnalytics.viewedPage()
  }, [])

  const handleClickAway = React.useCallback(() => {
    sendHideRequest()
  }, [sendHideRequest])

  const handleNewIngredientClick = React.useCallback(() => {
    ingredientsAnalytics.createdNewIngredient()
    void dispatch(
      createSimpleIngredient({
        companyId,
        name: 'Untitled Ingredient'
      })
    )
      .unwrap()
      .then((i) => {
        void dispatch(setSelectedSimpleIngredient({ ...i, new: true }))
      })
  }, [companyId])

  return (
    <QueriesProvider>
      <Ingredients
        ingredientViewer={<IngredientsViewerContainer />}
        onClickAway={handleClickAway}
        onNewIngredientClick={handleNewIngredientClick}
        filterAndSearch={<IngredientsSearchFiltersContainer />}
        table={<IngredientsTableContainer />}
      />
    </QueriesProvider>
  )
}

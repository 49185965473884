import { Typography } from '@mui/material'
import { Button } from 'components/Button/Button'
import { DateWithTooltip } from 'components/DateWithTooltip/DateWithTooltip'
import { BoxColumnBasic, BoxRow, ViewerRow } from 'components/common'
import React from 'react'
import {
  Cost,
  IngredientCostRow,
  MeasurementUnit,
  UpdateCostArgs
} from './IngredientCostRow'
export interface IngredientCostProps {
  costs: Cost[]
  onCreate: () => void
  onUpdate: (id: string, args: UpdateCostArgs) => void
  onDelete: (id: string) => void
  measurementUnitOptions: MeasurementUnit[]
  disabled: boolean
}
export const IngredientCost: React.FC<IngredientCostProps> = ({
  costs,
  disabled,
  onCreate,
  onUpdate,
  onDelete,
  measurementUnitOptions
}) => {
  return (
    <BoxColumnBasic
      style={{
        gap: '12px',
        padding: '12px',
        backgroundColor: '#F6F5F4',
        borderRadius: '8px'
      }}
    >
      <ViewerRow>
        <Typography
          style={{
            fontWeight: '500',
            fontSize: '16px',
            color: '#1A2C42BF'
          }}
        >
          Cost
        </Typography>
        <BoxRow gap="8px" justifyContent="flex-end">
          {costs.length > 0 ? (
            <>
              <Typography
                style={{
                  fontWeight: '400',
                  fontSize: '14px',
                  color: '#233C586E',
                  paddingBottom: '1px'
                }}
              >
                last updated{' '}
              </Typography>
              <DateWithTooltip
                date={
                  costs.reduce(
                    (max, cost) =>
                      new Date(cost.updatedAt) > new Date(max)
                        ? cost.updatedAt
                        : max,
                    costs[0]?.updatedAt || ''
                  ) || ''
                }
                style={{
                  fontWeight: '400',
                  fontSize: '14px',
                  color: '#233C586E',
                  paddingBottom: '1px'
                }}
              />
            </>
          ) : (
            <Button
              size="small"
              color="secondary"
              onClick={onCreate}
              disabled={disabled}
            >
              Add new cost
            </Button>
          )}
        </BoxRow>
      </ViewerRow>
      {costs.length > 0 && (
        <BoxColumnBasic>
          {costs.map((cost) => (
            <IngredientCostRow
              key={cost.id}
              cost={cost}
              measurementUnitOptions={measurementUnitOptions}
              onUpdate={onUpdate}
              onDelete={onDelete}
              disabled={disabled}
            />
          ))}
        </BoxColumnBasic>
      )}
    </BoxColumnBasic>
  )
}

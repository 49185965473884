import { useLoadNextPage } from 'common/hooks'
import { Option } from 'components/common'
import React from 'react'

export interface SearchResponse {
  options: Option<boolean>[]
  page: number
  pages: number
}

export interface SearchOptions {
  name?: string
  localSearch?: boolean
  searchPlaceholder?: string
  onSearchChange: (search?: string, page?: number) => Promise<SearchResponse>
  contextKey?: string // This key is used for context switching. We might be using the same hook in different contexts.
}

export interface SearchProps {
  name?: string
  options: Option<boolean>[]
  setOptions: React.Dispatch<React.SetStateAction<Option<boolean>[]>>
  setFilteredOptions: React.Dispatch<
    React.SetStateAction<Option<boolean>[] | null>
  >
  resetSearch: () => void
  allPagesLoaded?: boolean
  onLoadNextPage?: () => void
  search: {
    value: string
    onChange: (search: string) => void
    placeholder?: string | React.ReactNode
  }
  filteredOptions?: Option<boolean>[] | null
}

/**
 * Handles search and filter logic with pagination in lists.
 * @param searchOptions The options for the search.
 * @returns The search props.
 */
export const useSearch = (searchOptions: SearchOptions) => {
  const [options, setOptions] = React.useState<Option<boolean>[]>([])
  const [filteredOptions, setFilteredOptions] = React.useState<
    Option<boolean>[] | null
  >(null)
  const [search, setSearch] = React.useState('')
  const [page, setPage] = React.useState(1)
  const [pages, setPages] = React.useState(1)
  const prevContextKeyRef = React.useRef<string | undefined>(
    searchOptions.contextKey
  )

  const setCurrentPageCallback = React.useCallback(
    (newPage: number) => {
      void searchOptions
        .onSearchChange(search, newPage)
        .then(({ options: newOptions, page, pages }) => {
          if (search) {
            // If we're searching, set the filtered options.
            setFilteredOptions((prevFilteredOptions) => [
              ...(prevFilteredOptions || []),
              ...newOptions
            ])
          } else {
            // Otherwise, just append the new options to the list.
            setOptions((prevOptions) => [...prevOptions, ...newOptions])
          }
          setPage(page)
          setPages(pages)
        })
    },
    [searchOptions.onSearchChange, search, options, filteredOptions]
  )

  const { nextPageCallback, allPagesLoaded } = useLoadNextPage({
    page,
    pages,
    setCurrentPageCallback
  })

  React.useEffect(() => {
    const prevContextKey = prevContextKeyRef.current
    prevContextKeyRef.current = searchOptions.contextKey
    // If it's not a local search or we are in the initial state.
    if (
      !searchOptions.localSearch ||
      (options.length === 0 && search === '') ||
      searchOptions.contextKey !== prevContextKey
    ) {
      void searchOptions
        .onSearchChange(search, 1)
        .then(({ options, page, pages }) => {
          // If the search is not empty, then this should go into the filteredOptions.
          if (search) {
            setFilteredOptions(options)
          } else {
            setOptions(options)
            setFilteredOptions(null)
          }
          setPage(page)
          setPages(pages)
        })
    } else {
      setFilteredOptions(
        options.filter((option) =>
          (option.label as string).toLowerCase().includes(search.toLowerCase())
        )
      )
    }
  }, [search, searchOptions.contextKey])

  const handleSearchChange = React.useCallback(
    (search: string) => {
      setSearch(search)
    },
    [setSearch]
  )

  const resetSearch = React.useCallback(() => {
    setSearch('')
  }, [setSearch])

  return {
    name: searchOptions.name,
    options,
    setOptions,
    setFilteredOptions,
    allPagesLoaded,
    resetSearch,
    onLoadNextPage: nextPageCallback,
    search: {
      value: search,
      onChange: handleSearchChange,
      placeholder: searchOptions.searchPlaceholder
    },
    filteredOptions
  }
}

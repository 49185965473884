import React from 'react'
import { Button } from '../Button/Button'
import { NoPreview } from '../NoPreview/NoPreview'
import {
  FilePreviewButtonGroup,
  FilePreviewContainer,
  FilePreviewContentContainer,
  FilePreviewFileName,
  FilePreviewTopBarContainer
} from './FilePreviewStyles'
import ImagePreview from './ImagePreview/ImagePreview'
import PDFPreview from './PDFPreview/PDFPreview'
import { LoadingAnimation } from 'components/LoadingAnimation/LoadingAmination'

export interface FilePreviewProps {
  fileName?: string
  downloadFileCallback: () => void
  hidePreviewCallback?: () => void
  deleteFileCallback?: () => void
  downloadPreviewCallback?: () => Promise<string>
  fileSource?: string
  fileType?: string
}

export const FilePreview: React.FC<FilePreviewProps> = ({
  fileName,
  downloadFileCallback,
  downloadPreviewCallback,
  hidePreviewCallback,
  deleteFileCallback,
  fileSource,
  fileType
}) => {
  const [localFileSource, setLocalFileSource] = React.useState<string>()

  const isImage = (type?: string): boolean => {
    const validImageTypes = ['jpeg', 'png', 'jpg']
    return Boolean(type && validImageTypes.includes(type))
  }

  const isPDF = (type?: string): boolean => {
    return Boolean(type && type === 'pdf')
  }

  const isSupportedFileType = (type?: string): boolean => {
    return isImage(type) || isPDF(type)
  }

  /**
   * File preview callback and display.
   */
  React.useEffect(() => {
    if (isSupportedFileType(fileType)) {
      if (fileSource) {
        setLocalFileSource(fileSource)
      } else if (downloadPreviewCallback) {
        void downloadPreviewCallback().then((res) => {
          setLocalFileSource(res)
        })
      } else {
        setLocalFileSource(undefined)
      }
    }
  }, [downloadPreviewCallback, fileSource])

  return (
    <FilePreviewContainer>
      <FilePreviewTopBarContainer sx={{ justifyContent: 'space-between' }}>
        <FilePreviewFileName>{fileName}</FilePreviewFileName>
        <FilePreviewButtonGroup>
          <Button color="secondary" size="small" onClick={downloadFileCallback}>
            Download
          </Button>
          {hidePreviewCallback && <Button color="secondary" size="small" onClick={hidePreviewCallback}>
            Close
          </Button>}
          {deleteFileCallback && <Button color="danger" size="small" onClick={deleteFileCallback}>
            Delete
          </Button>}
        </FilePreviewButtonGroup>
      </FilePreviewTopBarContainer>
      <FilePreviewContentContainer>
        {!isSupportedFileType(fileType) && (
          <NoPreview
            title="File type not supported"
            text="Need this file type? Let us know!"
          />
        )}
        {!localFileSource && isSupportedFileType(fileType) && (
          <LoadingAnimation
            sx={{ alignSelf: 'center' }}
            text="Loading preview"
          />
        )}
        {isSupportedFileType(fileType) && localFileSource && (
          <>
            {isPDF(fileType) && <PDFPreview fileSource={localFileSource} />}
            {isImage(fileType) && <ImagePreview fileSource={localFileSource} />}
          </>
        )}
      </FilePreviewContentContainer>
    </FilePreviewContainer>
  )
}

export default FilePreview

import { Accordion } from 'components/Accordion/Accordion'
import { BoxColumnBasic } from 'components/common'
import { TextField } from 'components/InputFields/TextField'
import React from 'react'

export interface BotanicalsProps {
  botanicalName: {
    value?: string
    onChange: (value: string) => void
    disabled?: boolean
  }
  source: {
    value?: string
    onChange: (value: string) => void
    disabled?: boolean
  }
  assay: {
    value?: string
    onChange: (value: string) => void
    disabled?: boolean
  }
  expanded?: boolean
  debounceTime?: number
}

export const Botanicals: React.FC<BotanicalsProps> = ({
  botanicalName,
  source,
  assay,
  expanded = false,
  debounceTime
}) => {
  const [internalExpanded, setInternalExpanded] = React.useState<boolean>(false)

  React.useEffect(() => {
    setInternalExpanded(expanded)
  }, [expanded])

  return (
    <Accordion
      title="Botanical Info"
      expanded={internalExpanded}
      onChange={(_, e) => {
        setInternalExpanded(e)
      }}
    >
      <BoxColumnBasic gap={'10px'}>
        <TextField
          label="Botanical Name"
          name="botanicalName"
          value={botanicalName.value}
          onChange={botanicalName.onChange}
          debounceTime={debounceTime}
          disabled={botanicalName.disabled}
        />

        <TextField
          label="Source"
          name="source"
          value={source.value}
          onChange={source.onChange}
          debounceTime={debounceTime}
          rows={3}
        />
        <TextField
          label="Assay"
          name="assay"
          value={assay.value}
          onChange={assay.onChange}
          debounceTime={debounceTime}
        />
      </BoxColumnBasic>
    </Accordion>
  )
}

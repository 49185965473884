import { Option } from 'components/common'
import {
  SearchOptions,
  useSearch
} from 'components/EntrTable/Filter/SearchHook'
import { AutocompleteChangeReason } from './Autocomplete'

export interface AutocompleteProps {
  options: Option<boolean>[]
  onChange: (option: Option<boolean>, reason: AutocompleteChangeReason) => void
  allPagesLoaded?: boolean
  onLoadNextPage?: () => void
  search: {
    value: string
    onChange: (search: string) => void
    placeholder?: string
  }
  filteredOptions?: Option<boolean>[] | null
}

export interface FilterSearchResponse {
  options: Option<boolean>[]
  page: number
  pages: number
}

interface AutocompleteOptions extends SearchOptions {
  onChange: (
    option: Option<boolean>,
    reason: AutocompleteChangeReason
  ) => Promise<Option<boolean> | void>
}

export const useAutocomplete = (
  autocompleteOptions: AutocompleteOptions
): AutocompleteProps => {
  const {
    options,
    filteredOptions,
    setOptions,
    setFilteredOptions,
    allPagesLoaded,
    onLoadNextPage,
    search,
    resetSearch
  } = useSearch(autocompleteOptions)

  const onChange = (
    option: Option<boolean>,
    reason: AutocompleteChangeReason
  ) => {
    console.log('Change called', option, reason)
    void autocompleteOptions.onChange(option, reason).then((newOption) => {
      console.log('newOption', newOption)
      if (newOption) {
        // Update the options list to include the new selected option.
        // This case handles if the option is in the filter but not in the original.
        setOptions((prevOptions) => {
          let optionFound = false
          const newOptions = prevOptions.map((o) => {
            if (o.id === newOption.id) {
              optionFound = true
              return { ...o, value: newOption.value }
            }
            return o
          })

          if (!optionFound) {
            newOptions.push(newOption)
          }

          return newOptions
        })

        // Reflect the changes in the filteredOptions if needed.
        setFilteredOptions((prevFilteredOptions) => {
          const newFilteredOptions =
            prevFilteredOptions?.map((o) => {
              if (o.id === newOption.id) {
                return { ...o, value: newOption.value }
              }
              return o
            }) || null

          return newFilteredOptions
        })

        resetSearch()
      }
    })
  }

  return {
    options,
    onChange,
    allPagesLoaded,
    onLoadNextPage,
    search: {
      value: search.value,
      onChange: search.onChange,
      placeholder: 'Search...'
    },
    filteredOptions
  }
}

import { useDocuments } from 'common/documentsHook'
import React from 'react'
import { Design } from './Design'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  getProofDesignFileUrl,
  uploadProofDesignFile
} from 'state/labelproof/proofer/ProoferSlice'
import { getProofCommentsHavingPin } from 'state/labelproof/proofer/ProoferActivitiesSlice'

interface DesignContainerProps {}

export const DesignContainer: React.FC<DesignContainerProps> = ({}) => {
  const dispatch = useAppDispatch()
  const companyId = useAppSelector((state) => state.companies.currentCompany.id)
  const proof = useAppSelector((state) => state.proofer.proof)
  const commentsHavingPin =
    useAppSelector((state) => state.prooferActivities.commentsHavingPin) || []

  const designFileUrl = useAppSelector((state) => state.proofer.designFileUrl)
  const loadingDesignFileUrl = useAppSelector(
    (state) => state.proofer.loadingDesignFileUrl
  )

  const highlightedPinId = useAppSelector((state) => state.proofer.highlightedPinId)
  const stagedCommentPin = useAppSelector(
    (state) => state.prooferActivities.stagedComment.pin
  )

  const handleUploadFile = (
    file: File,
    progressListener: (progress: number | undefined) => void
  ): Promise<void> => {
    if (!proof) {
      return Promise.reject('No proof found')
    }
    return dispatch(
      uploadProofDesignFile({
        companyId: companyId,
        proofId: proof.id,
        file: file,
        uploadProgressListener: (progressEvent) => {
          progressListener(progressEvent.progress)
        }
      })
    ).then(() => {})
  }

  const getDesignFileUrl = () => {
    if (!proof) {
      return
    }
    dispatch(
      getProofDesignFileUrl({
        companyId,
        proofId: proof.id
      })
    )
  }

  React.useEffect(() => {
    if (!proof) {
      return
    }
    dispatch(
      getProofCommentsHavingPin({
        companyId,
        proofId: proof.id
      })
    )
    getDesignFileUrl()
  }, [proof])

  const { uploadDocumentsCallback: uploadFiles } = useDocuments({
    onUploadDocument: handleUploadFile,
    refresh: getDesignFileUrl,
    validFileTypes: ['image/png'],
    invalidFileTypeMessage: 'Only PNG is currently supported'
  })

  return (
    <Design
      uploader={{
        uploadFile: (file) => uploadFiles([file])
      }}
      preview={{
        fileUrl: designFileUrl,
        pins: commentsHavingPin.map((comment) => ({
          id: comment.pin.id,
          highlighted: comment.pin.id === highlightedPinId,
          hidden: stagedCommentPin?.id === comment.pin.id,
          commentId: comment.id,
          x: comment.pin.widthRatio,
          y: comment.pin.heightRatio
        }))
      }}
      loadingDesignFileUrl={loadingDesignFileUrl}
    />
  )
}

import { Box } from '@mui/material'
import React from 'react'

interface OverridesProps {
  label: React.ReactNode
  nutrients: React.ReactNode
}

export const Overrides: React.FC<OverridesProps> = ({ label, nutrients }) => {
  return (
    <Box sx={{ height: '100%' }}>
      {label}
      {nutrients}
    </Box>
  )
}

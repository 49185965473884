import { BoxColumnBasic } from 'components/common'
import React from 'react'
import { Uploader, UploaderProps } from './components/Uploader'
import { PreviewContainer, PreviewProps } from './components/PreviewContainer'
import { LoadingAnimation } from 'components/LoadingAnimation/LoadingAmination'

interface DesignProps {
  uploader: UploaderProps
  preview: PreviewProps
  loadingDesignFileUrl: boolean
}

export const Design: React.FC<DesignProps> = ({
  uploader,
  preview,
  loadingDesignFileUrl
}) => {
  return (
    <BoxColumnBasic
      sx={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {loadingDesignFileUrl || preview.fileUrl === undefined ? (
        <LoadingAnimation text={'Loading'} />
      ) : preview.fileUrl ? (
        <PreviewContainer {...preview} />
      ) : (
        <Uploader {...uploader} />
      )}
    </BoxColumnBasic>
  )
}

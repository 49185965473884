import { SimpleIngredientAllergen } from 'models/Allergen'
import { IngredientType } from 'models/Ingredient'
import {
  BasicSimpleIngredient,
  DetailedSimpleIngredient,
  DetailedSimpleIngredientsPaginated,
  SimpleIngredientCost
} from 'models/SimpleIngredient'
import { SupplierSimpleIngredient } from 'models/Supplier'
import { SimpleIngredientTag } from 'models/Tags'
import { toBasicAllergen, toBasicSupplier } from '../supplier/SupplierApiMapper'
import { toBasicUser } from '../user/UserApiMapper'
import {
  BasicSimpleIngredientApiResponse,
  DetailedSimpleIngredientApiPaginatedResponse,
  DetailedSimpleIngredientApiResponse,
  SimpleIngredientAllergenApiResponse,
  SimpleIngredientCostApiResponse,
  SimpleIngredientTagApiResponse,
  SimpleSimpleIngredientApiResponse,
  SupplierSimpleIngredientApiPaginatedResponse,
  SupplierSimpleIngredientApiResponse
} from './SimpleIngredientApiResponse'
import { toMeasurement } from '../measurements/MeasurementApiMapper'

export const toSupplierSimpleIngredients = (
  response: SupplierSimpleIngredientApiPaginatedResponse
): SupplierSimpleIngredient[] => {
  return response.items.map((supplierSimpleIngredientApiResponse) => {
    return toSupplierSimpleIngredient(supplierSimpleIngredientApiResponse)
  })
}

export const toSupplierSimpleIngredient = (
  response: SupplierSimpleIngredientApiResponse
): SupplierSimpleIngredient => {
  return {
    id: response.id,
    ingredient: toBasicSimpleIngredient(response.ingredient),
    supplier: toBasicSupplier(response.supplier)
  }
}

export const toBasicSimpleIngredient = (
  response: BasicSimpleIngredientApiResponse
): BasicSimpleIngredient => {
  return {
    ...response,
    type: IngredientType.SIMPLE
  }
}

export const toSimpleIngredientCost = (
  response: SimpleIngredientCostApiResponse
): SimpleIngredientCost => {
  return {
    ...response,
    measurement: toMeasurement(response.measurement)
  }
}

export const toDetailedSimpleIngredientFromSimpleSimpleIngredient = (
  response: SimpleSimpleIngredientApiResponse
): DetailedSimpleIngredient => {
  return {
    ...response,
    createdBy: response.createdBy ? toBasicUser(response.createdBy) : undefined,
    createdAt: response.createdAt,
    updatedAt: response.updatedAt,
    type: IngredientType.SIMPLE,
    measurementPreference: response.measurementPreference
      ? toMeasurement(response.measurementPreference)
      : undefined,
    tags: [],
    costs: [],
    formulas: [],
    suppliers: []
  }
}

export const toDetailedSimpleIngredient = (
  response: DetailedSimpleIngredientApiResponse
): DetailedSimpleIngredient => {
  return {
    ...response,
    costs: response.costs.map((c) => toSimpleIngredientCost(c)),
    createdBy: response.createdBy ? toBasicUser(response.createdBy) : undefined,
    type: IngredientType.SIMPLE
  }
}

export const toDetailedSimpleIngredientPaginated = (
  response: DetailedSimpleIngredientApiPaginatedResponse
): DetailedSimpleIngredientsPaginated => {
  return {
    ...response,
    items: response.items.map((d) => toDetailedSimpleIngredient(d))
  }
}

export const toSimpleIngredientTag = (
  response: SimpleIngredientTagApiResponse
): SimpleIngredientTag => {
  return {
    id: response.id,
    ingredient: toBasicSimpleIngredient(response.ingredient),
    tag: {
      id: response.tag.id,
      name: response.tag.name
    }
  }
}

export const toSimpleIngredientAllergen = (
  response: SimpleIngredientAllergenApiResponse
): SimpleIngredientAllergen => {
  return {
    ingredient: toBasicSimpleIngredient(response.ingredient),
    allergen: toBasicAllergen(response.allergen)
  }
}
